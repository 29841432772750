import { Slice } from "../types/prismic";
import { Optional } from "../types/generics";

export function findSlice<T extends Slice>(
    body: Optional<readonly (T | object)[]>,
    type: T["__typename"]
): Optional<T> {
    function isT(slice: T | object): slice is T {
        return "__typename" in slice && (slice as T).__typename === type;
    }

    return body?.find(isT);
}

export function findSlices<T extends Slice>(
    body: Optional<readonly (T | object)[]>,
    type: T["__typename"]
): Optional<T[]> {
    function isT(slice: T | object): slice is T {
        return "__typename" in slice && (slice as T).__typename === type;
    }

    return body?.filter<T>(isT);
}
