import { CustomerAttributeName, CustomerAttributeValue } from "../../types/customerAttributes";
import { getCustomerAttributeValueByName } from "../customer/getCustomerAttributeValueByName";
import { PropertyOwnershipStatus, PropertySelection, PropertyType } from "../../types/property";
import { Maybe, Optional } from "../../types/generics";

export const getPropertySelectionFromCustomerAttributes = (
    attributes?: CustomerAttributeValue[],
    selection?: Maybe<PropertySelection>
): Optional<Maybe<PropertySelection>> => {
    if (!attributes || selection) {
        return selection;
    }

    const propertyTypeAttribute = getCustomerAttributeValueByName(CustomerAttributeName.PROPERTY_TYPE, attributes);
    const propertyStatusAttribute = getCustomerAttributeValueByName(CustomerAttributeName.PROPERTY_STATUS, attributes);

    return {
        type: (propertyTypeAttribute?.attribute_value ?? null) as Maybe<PropertyType>,
        status: (propertyStatusAttribute?.attribute_value ?? null) as Maybe<PropertyOwnershipStatus>,
    };
};
