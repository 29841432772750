import { useStaticQuery, graphql } from "gatsby";
import { PriceList } from "../../types/price";

const usePriceLists = (): PriceList[] => {
    const { priceLists } = useStaticQuery(
        graphql`
            query {
                priceLists {
                    data {
                        id
                        name
                        active
                    }
                }
            }
        `
    );

    return priceLists.data;
};

export default usePriceLists;
