import React from "react";
import { InstallationType } from "../../types/installation";
import { getInstallationType } from "../../utils/installation/getInstallationType";
import { productName } from "../../utils/productName";
import { Pages } from "../../types/navigation";
import Link from "../Buttons/Link";
import useLocation from "../../hooks/useLocation";
import { LineItem } from "../../types/cart";
import useChargers from "../../hooks/BigCommerce/useChargers";
import { getPowerRatingOptionValue } from "../../utils/products/getPowerRatingOptionValue";
import { useInstallationSelection } from "../../hooks/useInstallationSelection";
import { IndividualCustomerContent } from "../Layout/IndividualCustomerContent";
import { getCustomerGroupId } from "../../utils/customer/customerGroups";
import { useAffiliateTrackingStorage } from "../../hooks/useAffiliateTrackingStorage";
import { useFetchCustomer } from "../../queries/useFetchCustomer";
import { useCustomerGroupById } from "../../hooks/BigCommerce/useCustomerGroupById";
import { kebabCase } from "../../utils/str/case";
import ItemQuantityAndRemoval from "./ItemQuantityAndRemoval";
import CartRow from "./CartRow";
import CartPrice from "./CartPrice";

export enum CartRowVariant {
    STANDARD,
    MINIMAL,
}

interface Props {
    lineItem: LineItem;
    pageContent?: Queries.PrismicCartPageFragment;
    rowVariant?: CartRowVariant;
    allowRemoval?: boolean;
}

export default function ChargerCartRow({
    lineItem,
    pageContent,
    rowVariant = CartRowVariant.STANDARD,
    allowRemoval = false,
}: Props) {
    const { selection } = useInstallationSelection();
    const installationType = getInstallationType(selection);
    const allChargers = useChargers();
    const charger = allChargers.find((charger) => charger.id === lineItem.product_id);
    const chargerVariant = charger?.variants.find((variant) => variant.id === lineItem.variant_id);
    const powerRatingOptionValue = getPowerRatingOptionValue(charger, chargerVariant);
    const location = useLocation();

    const { getPartner, getPublisher } = useAffiliateTrackingStorage();
    const { data: customer } = useFetchCustomer();
    const customerGroupId = getCustomerGroupId(customer, getPartner(), getPublisher());
    const customerGroup = useCustomerGroupById(customerGroupId);

    const showEditButton = !location.pathname.includes(Pages.CHARGER_SELECTION);
    const showQuantityPicker = allowRemoval || lineItem.quantity > 1;

    if (!charger) {
        return null;
    }

    return (
        <CartRow
            testId="charger-cart-item"
            image={charger.images.length > 0 ? charger.images[0] : undefined}
            content={
                <div
                    className={`flex w-full ${
                        rowVariant === CartRowVariant.STANDARD ? "flex-row justify-between" : "flex-col"
                    }`}
                >
                    <div className="p-2">
                        <div className="flex flex-row justify-between mb-2">
                            <h2 className="text-xl font-bold">{productName(charger)}</h2>
                        </div>

                        {powerRatingOptionValue && (
                            <div
                                data-charger-cart-item-sku={chargerVariant?.sku}
                                data-testid={`charger-cart-item-${kebabCase(
                                    productName(charger)
                                )}-${powerRatingOptionValue.label.toLowerCase()}`}
                            >
                                <span>{powerRatingOptionValue.label}</span>
                                {showEditButton && (
                                    <Link to={Pages.CHARGER_SELECTION} className="ml-2">
                                        {pageContent?.primary?.edit ?? ""}
                                    </Link>
                                )}
                            </div>
                        )}

                        {showQuantityPicker && (
                            <ItemQuantityAndRemoval
                                lineItem={lineItem}
                                minQuantity={0}
                                maxQuantity={1}
                                allowRemoval={allowRemoval}
                            />
                        )}

                        {rowVariant === CartRowVariant.STANDARD && (
                            <div className="flex flex-col gap-2">
                                <div className="flex items-center pt-4">
                                    <img
                                        className="w-8 h-8 mr-2 hidden sm:block"
                                        src={pageContent?.primary.icon_standard_installation?.url ?? ""}
                                        alt={pageContent?.primary.icon_standard_installation?.alt ?? ""}
                                    />
                                    <div>
                                        <p className="text-gray-300 text-xs">
                                            {installationType === InstallationType.NON_STANDARD
                                                ? pageContent?.primary.non_standard_installation_line_1
                                                : pageContent?.primary.standard_installation_line_1}
                                        </p>
                                        <p className="text-gray-300 text-xs">
                                            {installationType === InstallationType.NON_STANDARD
                                                ? pageContent?.primary.non_standard_installation_line_2
                                                : pageContent?.primary.standard_installation_line_2}
                                        </p>
                                    </div>
                                </div>
                                <IndividualCustomerContent
                                    className="text-gray-300 text-xs"
                                    uid="edf-terms-and-conditions"
                                    customerGroupName={customerGroup?.name}
                                />
                            </div>
                        )}
                    </div>
                    <div className="p-2">
                        <h3 className="text-2xl font-bold">
                            <CartPrice lineItem={lineItem} />
                        </h3>
                    </div>
                </div>
            }
        />
    );
}
