import { gtm } from "@pod-point/tracking.js";
import vwoSdk from "vwo-node-sdk";
import { Customer } from "../types/customer";
import { Origin, OriginType } from "../types/origin";
import { OptionDisplayName, Product, ProductPresentmentPrice } from "../types/products";
import { PropertyOwnershipStatus, PropertySelection, PropertyType } from "../types/property";
import Vehicle, { VehicleSelection } from "../types/vehicle";
import { Cart, LineItem } from "../types/cart";
import { LineItemTrackingEventBody, ORDER_TO_TRACKING_TYPE, TrackingPurchaseEventBody } from "../types/tracking";
import { Maybe, Optional } from "../types/generics";
import { getProductType } from "../utils/products/getProductType";
import { getCharger } from "../utils/products/getCharger";
import { getChannelCurrency } from "../store/ephemeral/channel";
import { getPowerRatingOptionValue } from "../utils/products/getPowerRatingOptionValue";
import { getChargerVariantFromCart, getProductLineItem } from "../utils/cart/cart";
import { kebabCase } from "../utils/str/case";
import { VWO_EVENT_PREFIX, VWO_UUID_COOKIE } from "../utils/vwoConstants";
import { generateCustomerHash } from "../utils/customer/generateCustomerHash";

export const ADD_SHIPPING_INFO_EVENT = "add_shipping_info";
export const CONNECTION_TYPE_SELECTED_EVENT = "select_connection";
export const CUSTOMER_DETAILS_SUBMITTED_EVENT = "Customer Details Entered";
export const SELF_CERTIFICATION_COMPLETE_EVENT = "Install Self-Cert Completed";
export const LINKEDIN_TRACK = "track";
export const REVIEW_ORDER_EVENT = "review_order";
export const BEGIN_CHECKOUT_EVENT = "begin_checkout";
export const PURCHASE_EVENT = "purchase";
export const SELECT_PROPERTY_EVENT = "select_property";
export const GENERATE_LEAD = "generate_lead";
export const USER_LOGIN_EVENT = "login";
export const MAGIC_LINK_LOGIN_SUCCESS_EVENT = "magic_link_login_success";
export const MAGIC_LINK_LOGIN_FAILURE_EVENT = "magic_link_login_failure";
export const MAGIC_LINK_EMAIL_SENT = "magic_link_email_sent";
export const EMAIL_AND_PASSWORD_LOGIN_SUCCESS_EVENT = "email_and_password_login_success";
export const EMAIL_AND_PASSWORD_LOGIN_FAILURE_EVENT = "email_and_password_login_failure";
export const MFA_LOGIN_SUCCESS_EVENT = "mfa_login_success";
export const MFA_LOGIN_FAILURE_EVENT = "mfa_login_failure";
export const USER_LOGOUT_EVENT = "logout";
export const SELECT_VEHICLE_EVENT = "select_vehicle";
export const CALL_TO_ACTION_EVENT = "cta_click";
export const TRACK_NAVBAR_EVENT = "nav_click";
export const ADD_TO_CART_EVENT = "add_to_cart";
export const REMOVE_FROM_CART_EVENT = "remove_from_cart";
export const TRACK_MODAL_OPENED_EVENT = "modal_opened";
export const TRACK_PHONE_NUMBER_CTA_EVENT = "phone_number_cta_click";

let vwoSettings: any | undefined;
let vwoClient: vwoSdk.vwoAsyncInstance | undefined;

const getLeadType = (origin: Maybe<Origin>): string => {
    const LEAD_TYPES = {
        [OriginType.AFFILIATE]: kebabCase(origin?.sourceEnvironment ?? OriginType.AFFILIATE), // awin-partner, awin-web...
        [OriginType.ORGANIC]: "homecharge",
        [OriginType.REFERRAL]: "partner",
    };

    return origin?.type ? LEAD_TYPES[origin.type] : LEAD_TYPES[OriginType.ORGANIC];
};

export const getVwoUuid = () => {
    if (typeof document === "undefined") {
        return undefined;
    }

    const cookie = document.cookie
        .split(";")
        .map((cookie) => cookie.trim().split("=", 2))
        .find(([cookieName]) => cookieName == VWO_UUID_COOKIE);

    return cookie ? cookie[1] : undefined;
};

const initVwo = async () => {
    const vwoAccountId = process.env.GATSBY_VWO_ACCOUNT_ID;
    const vwoAccountApiKey = process.env.GATSBY_VWO_ACCOUNT_API_KEY;

    if (vwoAccountId && vwoAccountApiKey) {
        try {
            vwoSettings = await vwoSdk.getSettingsFile(vwoAccountId, vwoAccountApiKey);
            vwoSdk.launch({ settingsFile: vwoSettings });
        } catch (error) {
            // Fail silently as ad-blockers will trigger errors
        }
    }
};

export const initTracking = async () => {
    if (process.env.GATSBY_GTM_ID) {
        gtm.init(process.env.GATSBY_GTM_ID as string);
    }

    await initVwo();
};

export const trackPage = (path: string, properties: object = {}) => {
    gtm.trackPage(path, properties);
};

export const trackEvent = (event: string, properties: object = {}, options: object = {}) => {
    gtm.track(event, {
        ...properties,
        ...options,
    });

    const vwoUuid = getVwoUuid();
    if (vwoSettings && vwoClient && vwoUuid) {
        const eventName = VWO_EVENT_PREFIX + event;
        if (
            // Check this event exists as a goal for a campaign or VWO will log an error
            vwoSettings.campaigns.find((campaign: any) =>
                campaign.goals.find((goal: any) => goal.identifier === eventName)
            )
        ) {
            void vwoClient.track(null, vwoUuid, eventName, {
                // Not listed in API documentation but passing event properties here is possible
                // see https://github.com/wingify/vwo-node-sdk/blob/master/lib/api/track.js#L57
                eventProperties: properties,
            });
        }
    }
};

export const trackLoginEvent = (userUid: string) => {
    trackEvent(USER_LOGIN_EVENT, {
        user_id: userUid,
    });
};

export const trackEmailAndPasswordLoginSuccess = (userUid: string, origin: Maybe<Origin>) => {
    trackEvent(EMAIL_AND_PASSWORD_LOGIN_SUCCESS_EVENT, {
        user_id: userUid,
        lead_type: getLeadType(origin),
    });
};

export const trackEmailAndPasswordLoginFailure = (userUid: string, origin: Maybe<Origin>) => {
    trackEvent(EMAIL_AND_PASSWORD_LOGIN_FAILURE_EVENT, {
        user_id: userUid,
        lead_type: getLeadType(origin),
    });
};

export const trackMFALoginSuccess = (userUid: string, origin: Maybe<Origin>) => {
    trackEvent(MFA_LOGIN_SUCCESS_EVENT, {
        user_id: userUid,
        lead_type: getLeadType(origin),
    });
};

export const trackMFALoginFailure = (userUid: string, origin: Maybe<Origin>) => {
    trackEvent(MFA_LOGIN_FAILURE_EVENT, {
        user_id: userUid,
        lead_type: getLeadType(origin),
    });
};

export const trackMagicLinkLoginSuccess = (userUid: string, origin: Maybe<Origin>) => {
    trackEvent(MAGIC_LINK_LOGIN_SUCCESS_EVENT, {
        user_id: userUid,
        lead_type: getLeadType(origin),
    });
};

export const trackMagicLinkLoginFailure = (userUid: string, origin: Maybe<Origin>, errorCode: string) => {
    trackEvent(MAGIC_LINK_LOGIN_FAILURE_EVENT, {
        user_id: userUid,
        lead_type: getLeadType(origin),
        error_code: errorCode,
    });
};

export const trackLogoutEvent = (userUid: string) => {
    trackEvent(USER_LOGOUT_EVENT, {
        user_id: userUid,
    });
};

export const trackLinkedinConversion = (conversionId: number) => {
    window.lintrk && window.lintrk(LINKEDIN_TRACK, { conversion_id: conversionId });
};

export const trackSelectProperty = (type: Maybe<PropertyType>, status: Maybe<PropertyOwnershipStatus>) => {
    trackEvent(SELECT_PROPERTY_EVENT, {
        property_type: type,
        occupation_type: status,
    });
};

export const trackSelectVehicle = (vehicle: Maybe<Vehicle>, condition: Maybe<string>, connector: Maybe<string>) => {
    trackEvent(SELECT_VEHICLE_EVENT, {
        vehicle_brand: vehicle ? vehicle.brand.name : null,
        vehicle_model: vehicle ? vehicle.name : null,
        vehicle_condition: condition,
        connection_type: connector,
    });
};

export const trackConnectionTypeSelection = (product: Product, vehicleConnector: Maybe<string>) => {
    trackEvent(CONNECTION_TYPE_SELECTED_EVENT, {
        product_variant: product.name,
        connection_type: vehicleConnector,
    });
};

export const trackAddToCart = (eventBody: LineItemTrackingEventBody) => {
    trackEvent(ADD_TO_CART_EVENT, {
        ...eventBody,
    });
};

export const trackRemoveFromCart = (eventBody: LineItemTrackingEventBody) => {
    trackEvent(REMOVE_FROM_CART_EVENT, {
        ...eventBody,
    });
};

export const trackGenerateLead = (customer: Customer, cart: Optional<Maybe<Cart>>, origin: Maybe<Origin>) => {
    if (cart) {
        trackEvent(GENERATE_LEAD, {
            lead_type: getLeadType(origin),
            currency: cart.currency.code,
            value: cart.cart_amount,
            user_id: generateCustomerHash(customer.email),
        });
    }
};

export const trackAddressDetailsSubmitted = (
    cartData: Maybe<Cart>,
    propertySelection: Maybe<PropertySelection>,
    vehicleSelection: Maybe<VehicleSelection>
) => {
    const physicalLineItems: Optional<any[]> = cartData?.line_items.physical_items.map((ele: LineItem) => {
        return {
            item_id: ele.sku,
            item_name: ele.name,
            price: ele.list_price,
            quantity: ele.quantity,
            affiliation: "Online Store",
            item_brand: "Pod Point",
            property_type: `${propertySelection?.status} ${propertySelection?.type}`,
            vehicle_brand: vehicleSelection?.brand,
            vehicle_model: vehicleSelection?.model,
            vehicle_condition: vehicleSelection?.condition,
            connection_type: vehicleSelection?.connector,
            power_rating: ele.options?.find((ele) => ele.name === OptionDisplayName.POWER_RATING)?.value ?? "",
            item_type: getProductType(ele.product_id),
            item_variant: "Physical",
        };
    });

    const digitalLineItems: Optional<any[]> = cartData?.line_items.digital_items.map((ele: LineItem) => {
        return {
            item_id: ele.sku,
            item_name: ele.name,
            price: ele.list_price,
            quantity: ele.quantity,
            affiliation: "Online Store",
            item_brand: "Pod Point",
            property_type: `${propertySelection?.status} ${propertySelection?.type}`,
            vehicle_brand: vehicleSelection?.brand,
            vehicle_model: vehicleSelection?.model,
            vehicle_condition: vehicleSelection?.condition,
            connection_type: vehicleSelection?.connector,
            item_type: getProductType(ele.product_id),
            item_variant: "Digital",
        };
    });

    trackEvent(ADD_SHIPPING_INFO_EVENT, {
        currency: cartData?.currency.code,
        value: cartData?.cart_amount,
        items: [...(physicalLineItems || []), ...(digitalLineItems || [])],
    });
};

export const trackCustomerDetailsSubmitted = (customer: Customer, origin: Origin) => {
    trackEvent(CUSTOMER_DETAILS_SUBMITTED_EVENT, {
        id: customer.id,
        origin,
    });
    trackLinkedinConversion(Number(process.env.GATSBY_LINKEDIN_HOMECHARGE_MQL_CONVERSION_ID as string));
};

export const trackSelfCertificationCompleted = (
    cableLength: Optional<Maybe<string>>,
    truckingLength: Optional<Maybe<string>>,
    digRequired: Optional<Maybe<string>>
) => {
    trackEvent(SELF_CERTIFICATION_COMPLETE_EVENT, {
        cableLength,
        trucking: truckingLength,
        undergroundCableRequired: digRequired,
    });
};

export const trackPurchaseEvent = (eventBody: TrackingPurchaseEventBody) => {
    trackEvent(PURCHASE_EVENT, {
        ...eventBody,
    });
};

export const trackCTAClick = (ctaText: string, ctaType: string, ctaLink?: string) => {
    trackEvent(CALL_TO_ACTION_EVENT, {
        cta_text: ctaText,
        cta_type: ctaType,
        cta_link: ctaLink,
    });
};

export const trackReviewOrder = (
    cartData: Optional<Cart>,
    vehicle: Maybe<VehicleSelection>,
    property: PropertySelection
) => {
    const physicalLineItems: Optional<any[]> = cartData?.line_items.physical_items.map((ele: LineItem) => {
        return {
            item_id: ele.sku,
            item_name: ele.name,
            price: ele.list_price,
            quantity: ele.quantity,
            affiliation: "Online Store",
            item_brand: "Pod Point",
            property_type: `${property.status} ${property.type}`,
            vehicle_brand: vehicle?.brand,
            vehicle_model: vehicle?.model,
            vehicle_condition: vehicle?.condition,
            connection_type: vehicle?.connector,
            power_rating: ele.options?.find((ele) => ele.name === OptionDisplayName.POWER_RATING)?.value ?? "",
            item_type: getProductType(ele.product_id),
            item_variant: "Physical",
        };
    });

    const digitalLineItems: Optional<any[]> = cartData?.line_items.digital_items.map((ele: LineItem) => {
        return {
            item_id: ele.sku,
            item_name: ele.name,
            price: ele.list_price,
            quantity: ele.quantity,
            affiliation: "Online Store",
            item_brand: "Pod Point",
            property_type: `${property.status} ${property.type}`,
            vehicle_brand: vehicle?.brand,
            vehicle_model: vehicle?.model,
            vehicle_condition: vehicle?.condition,
            connection_type: vehicle?.connector,
            item_type: getProductType(ele.product_id),
            item_variant: "Digital",
        };
    });

    trackEvent(REVIEW_ORDER_EVENT, {
        currency: cartData?.currency.code,
        value: cartData?.cart_amount,
        items: [...(physicalLineItems || []), ...(digitalLineItems || [])],
    });
};

export const trackBeginCheckout = (
    cartData: Optional<Maybe<Cart>>,
    vehicle: Maybe<VehicleSelection>,
    property: Maybe<PropertySelection>
) => {
    const physicalLineItems: Optional<any[]> = cartData?.line_items.physical_items.map((ele: LineItem) => {
        return {
            item_id: ele.sku,
            item_name: ele.name,
            price: ele.list_price,
            quantity: ele.quantity,
            affiliation: "Online Store",
            item_brand: "Pod Point",
            property_type: `${property?.status} ${property?.type}`,
            vehicle_brand: vehicle?.brand,
            vehicle_model: vehicle?.model,
            vehicle_condition: vehicle?.condition,
            connection_type: vehicle?.connector,
            power_rating: ele.options?.find((ele) => ele.name === OptionDisplayName.POWER_RATING)?.value ?? "",
            item_type: getProductType(ele.product_id),
            item_variant: "Physical",
        };
    });

    const digitalLineItems: Optional<any[]> = cartData?.line_items.digital_items.map((ele: LineItem) => {
        return {
            item_id: ele.sku,
            item_name: ele.name,
            price: ele.list_price,
            quantity: ele.quantity,
            affiliation: "Online Store",
            item_brand: "Pod Point",
            property_type: `${property?.status} ${property?.type}`,
            vehicle_brand: vehicle?.brand,
            vehicle_model: vehicle?.model,
            vehicle_condition: vehicle?.condition,
            connection_type: vehicle?.connector,
            item_type: getProductType(ele.product_id),
            item_variant: "Digital",
        };
    });

    trackEvent(BEGIN_CHECKOUT_EVENT, {
        currency: cartData?.currency.code,
        value: cartData?.cart_amount,
        items: [...(physicalLineItems || []), ...(digitalLineItems || [])],
    });
};

export const trackNavbarClick = (category: string, text: string) => {
    trackEvent(TRACK_NAVBAR_EVENT, {
        nav_category: category,
        nav_text: text,
    });
};

export const getCartTracking = (
    product: Optional<Product>,
    productPricing: Optional<ProductPresentmentPrice>,
    cartData: Optional<Maybe<Cart>>,
    chargers: Product[],
    propertyType: Maybe<PropertySelection>,
    vehicle: Maybe<VehicleSelection>
) => {
    const lineItem = product ? getProductLineItem(product, cartData) : undefined;
    const charger = getCharger(cartData, chargers);
    const chargerVariant = getChargerVariantFromCart(cartData, chargers);
    const productPrice = lineItem?.list_price ?? productPricing?.calculated_price;
    const currency = getChannelCurrency();
    const itemType = getProductType(product?.id);
    const connectionType = vehicle?.connector || null;

    return {
        currency: currency,
        value: productPrice,
        items: [
            {
                item_id: lineItem?.sku ?? product?.sku,
                item_name: product?.name,
                price: productPrice,
                quantity: 1,
                affiliation: "Online Store",
                item_brand: "Pod Point",
                property_type: `${propertyType?.status} ${propertyType?.type}`,
                vehicle_brand: vehicle?.brand ?? null,
                vehicle_model: vehicle?.model ?? null,
                vehicle_condition: vehicle?.condition ?? null,
                connection_type: connectionType,
                power_rating: charger ? getPowerRatingOptionValue(charger, chargerVariant)?.label : undefined,
                item_type: itemType,
                item_variant: product?.type && ORDER_TO_TRACKING_TYPE[product?.type],
            },
        ],
    };
};

export const trackModalOpened = (modalName: string) => {
    trackEvent(TRACK_MODAL_OPENED_EVENT, { modal_name: modalName });
};

export const trackMagicLinkEmailSent = (
    userUid: string,
    origin: Maybe<Origin>,
    options: { is_manually_triggered: boolean }
) => {
    trackEvent(MAGIC_LINK_EMAIL_SENT, {
        user_id: userUid,
        lead_type: getLeadType(origin),
        ...options,
    });
};

export const trackPhoneNumberClickEvent = (userUid: string) => {
    trackEvent(TRACK_PHONE_NUMBER_CTA_EVENT, {
        user_id: userUid,
    });
};
