import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Cart, CartQueryKey, LineItem } from "../../../types/cart";
import * as bigCommerce from "../../../services/bigCommerce/proxy";
import { useFetchCart } from "../useFetchCart";
import { Optional } from "../../../types/generics";

export const useRemoveLineItem = () => {
    const queryClient = useQueryClient();
    const { data: cart } = useFetchCart();

    return useMutation<any, any, { lineItem: LineItem }>({
        mutationKey: ["removeLineItem"],
        mutationFn: async ({ lineItem }) => {
            if (!cart) {
                throw new Error("Cart not found to remove line item");
            }

            const response = await bigCommerce.removeCartLineItem(cart.id, lineItem.id);

            return response.data.data;
        },
        onSuccess: (data: Optional<Cart>) => {
            queryClient.setQueryData([CartQueryKey.FETCH, cart?.id], data);
        },
    });
};
