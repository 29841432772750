import React from "react";
import { Link as GatsbyLink } from "gatsby";
import classNames from "classnames";
import Icon, { Icons } from "../Icons/Icon";

interface Props {
    children?: React.ReactNode;
    className?: string;
    isSecondary?: boolean;
    isButton?: boolean;
    isToOpenInNewTab?: boolean;
    onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
    showExternalLinkIcon?: boolean;
    icon?: Icons;
    iconTitle?: string;
    to?: string;
    testId?: string;
    dashed?: boolean;
    disabled?: boolean;
    tabIndex?: number;
}

const Link = ({
    children,
    className,
    isSecondary,
    isButton,
    isToOpenInNewTab,
    showExternalLinkIcon,
    icon,
    iconTitle,
    onClick,
    to,
    testId,
    dashed,
    disabled,
    tabIndex,
}: Props) => {
    const classes = classNames(
        "inline-block text-center underline-offset-4 group",
        {
            "cursor-not-allowed opacity-50": disabled,
            "font-bold px-4 py-2 rounded-lg border border-green-400": isButton,
            "bg-green-400 text-white": isButton && !isSecondary,
            "text-green-400 bg-transparent hover:bg-green-500 hover:text-white": isButton && isSecondary,
            "hover:text-green-500": !isButton,
            "text-blue-300 font-bold": !isButton && isSecondary,
            "text-green-400 font-semibold": !isButton && !isSecondary,
        },
        className
    );

    const innerClasses = classNames({
        "underline decoration-dashed": dashed,
        "group-hover:decoration-solid": !isButton && !isSecondary,
    });

    if (showExternalLinkIcon && !icon && to?.includes("://")) {
        icon = Icons.EXPAND_LIGHT;
        iconTitle = "Open in new tab";
    }

    const innerContent = (
        <>
            <span className={innerClasses}>{children}</span>
            {icon && (
                <>
                    &nbsp;
                    <Icon icon={icon} title={iconTitle} />
                </>
            )}
        </>
    );

    if (to) {
        if (to.includes("://")) {
            return (
                <a
                    className={classes}
                    href={to}
                    onClick={onClick}
                    data-testid={testId ?? "link-plain"}
                    {...(isToOpenInNewTab ? { target: "_blank", rel: "noreferrer" } : {})}
                    tabIndex={tabIndex}
                >
                    {innerContent}
                </a>
            );
        } else {
            return (
                <GatsbyLink className={classes} to={to} onClick={onClick} data-testid={testId ?? "link-gatsby"}>
                    {innerContent}
                </GatsbyLink>
            );
        }
    } else {
        return (
            <button
                onClick={onClick}
                disabled={disabled}
                className={classes}
                data-testid={testId ?? "link-button"}
                tabIndex={tabIndex}
                type="button"
            >
                {innerContent}
            </button>
        );
    }
};

export default Link;
