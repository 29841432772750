import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as bigCommerce from "../../../services/bigCommerce/proxy";
import { Cart, CartQueryKey } from "../../../types/cart";
import { useAssociateCartWithCustomer } from "../useAssociateCartWithCustomer";
import { Customer } from "../../../types/customer";
import { useCartSelectionStorage } from "../../../hooks/useCartSelectionStorage";
import { getCustomerGroupId } from "../../../utils/customer/customerGroups";
import { useAffiliateTrackingStorage } from "../../../hooks/useAffiliateTrackingStorage";
import { isLineItemInPricelist } from "../../../utils/products/isLineItemInPricelist";

/**
 * We are NOT going to add items to the pre-existing cart if the item in the current
 * cart has got the same product ID, the same quantity and the same optional variant.
 * Otherwise, we add the item from the current cart into the pre-existing cart as we
 * don't know which one the customer would like to pick or leave.
 */

export const useFetchPreExistingCartAndAddCurrentCartLineItems = () => {
    const queryClient = useQueryClient();
    const { mutate: associateCartWithCustomer } = useAssociateCartWithCustomer();
    const { setCartId } = useCartSelectionStorage();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();

    return useMutation<any, any, { currentCartId: string; preExistingCartId: string; customer: Customer }>({
        mutationKey: ["fetchPreExistingCartAndAddCurrentCartLineItems"],
        mutationFn: async ({ currentCartId, preExistingCartId, customer }) => {
            const customerGroupId = getCustomerGroupId(customer, getPartner(), getPublisher());
            const currentCartResponse = await bigCommerce.fetchCart(currentCartId);
            const currentCart = currentCartResponse.data.data;
            const currentCartLineItems = [...currentCart.line_items.physical_items];

            try {
                const fetchCartResponse = await bigCommerce.fetchCart(preExistingCartId);

                const preExistingCart = fetchCartResponse.data.data;
                const preExistingCartLineItems = [...preExistingCart.line_items.physical_items];

                const cartLineItemsToAdd = currentCartLineItems.filter((currentCartLineItem) => {
                    // Added items cannot already be in existing cart or not be part of customers current price list
                    const isInPriceList = isLineItemInPricelist(customerGroupId, currentCartLineItem);
                    const isNotInPreExistingCart = !preExistingCartLineItems.find(
                        (preExistingCartLineItem) =>
                            preExistingCartLineItem.product_id === currentCartLineItem.product_id &&
                            preExistingCartLineItem.quantity === currentCartLineItem.quantity &&
                            preExistingCartLineItem?.variant_id === currentCartLineItem?.variant_id
                    );

                    return isInPriceList && isNotInPreExistingCart;
                });

                const cartResponse =
                    cartLineItemsToAdd.length > 0
                        ? await bigCommerce.addCartLineItems(preExistingCart.id, {
                              line_items: cartLineItemsToAdd.map((lineItem) => ({
                                  product_id: lineItem.product_id,
                                  variant_id: lineItem.variant_id,
                                  quantity: lineItem.quantity,
                              })),
                          })
                        : fetchCartResponse;

                const cart = cartResponse.data.data as Cart;

                setCartId(cart.id);

                associateCartWithCustomer({ cart, customer });

                return cart;
            } catch (error: any) {
                if (error.status === 404 || error.response?.status === 404) {
                    setCartId(currentCart.id);

                    associateCartWithCustomer({ cart: currentCart, customer });

                    return currentCart;
                }

                throw error;
            }
        },
        onSuccess: (data: Cart) => {
            queryClient.setQueryData([CartQueryKey.FETCH, data.id], data);
        },
    });
};
