import React, { useCallback, useMemo } from "react";
import ModalSection from "../Modal/Section";
import Button from "../Buttons/Button";
import Modal from "../Modal/Modal";
import useConfirmCountryModalContent from "../../hooks/Prismic/useConfirmCountryModalContent";
import useUserLocale from "../../hooks/useUserLocale";
import ModalHeader from "../Modal/Header";
import { ConfirmCountryStorage } from "../../types/confirmCountry";
import useLocation from "../../hooks/useLocation";
import useNavigationPages from "../../hooks/useNavigationPages";
import { useStatefulLocalStorage } from "../../hooks/useStatefulLocalStorage";

const ConfirmCountryModal = () => {
    const location = useLocation();
    const { navigateToUrl } = useNavigationPages();
    const { current: userLocale, suggested: localeToRedirectTo, isLoading, error } = useUserLocale();
    const [isDismissed, setIsDismissed] = useStatefulLocalStorage(ConfirmCountryStorage.MODAL_DISMISSED_KEY, false);
    const content = useConfirmCountryModalContent(localeToRedirectTo?.countryCode);

    const shouldShow = useMemo(() => {
        return !isLoading && !error && !!userLocale && !!localeToRedirectTo && !isDismissed;
    }, [isLoading, error, localeToRedirectTo, userLocale, isDismissed]);

    const onCancel = useCallback(() => {
        setIsDismissed(true);
    }, [setIsDismissed]);

    const onConfirm = useCallback(() => {
        if (!userLocale || !localeToRedirectTo) {
            return;
        }

        const url = location.href.replace(userLocale.tld, localeToRedirectTo.tld);

        navigateToUrl(url);
    }, [localeToRedirectTo, location.href, navigateToUrl, userLocale]);

    return (
        <Modal isOpen={shouldShow} onClose={onCancel} className="w-300">
            <ModalHeader>{content?.title}</ModalHeader>
            <ModalSection>
                <p className="mb-8">{content?.description}</p>
            </ModalSection>
            <ModalSection>
                <div data-testid="remove-confirm-dialog" className="flex flex-col md:flex-row justify-between gap-4">
                    <Button onClick={onCancel} outline data-testid="cancel-button">
                        {content?.cancel_button}
                    </Button>
                    <Button onClick={onConfirm} data-testid="action-button">
                        {content?.action_button}
                    </Button>
                </div>
            </ModalSection>
        </Modal>
    );
};

export default ConfirmCountryModal;
