import { Product } from "../../types/products";
import { getAccessoryCategory } from "../../store/ephemeral/products";
import { getAvailableProducts } from "./getAvailableProducts";

export const getAccessories = (customerGroupId: number): Product[] => {
    const products = getAvailableProducts(customerGroupId);
    const accessoryCategory = getAccessoryCategory();

    return products.filter((product) => product.categories.includes(accessoryCategory.category_id));
};
