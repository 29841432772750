import { InstallationSelection, InstallationStorage } from "../types/installation";
import { Maybe, Optional } from "../types/generics";
import { useStatefulLocalStorage } from "./useStatefulLocalStorage";

export const useInstallationSelectionStorage = () => {
    const [selection, storeInstallationSelection] = useStatefulLocalStorage<Optional<Maybe<InstallationSelection>>>(
        InstallationStorage.SELECTION,
        null
    );

    return {
        selection,
        storeInstallationSelection,
    };
};
