import { AdditionalServicesStorage } from "../types/additionalServices";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const useAdditionalServicesSelectionStorage = () => {
    const [getAdditionalServicesSelection, setAdditionalServicesSelection] = useTransactionalLocalStorage<number[]>(
        AdditionalServicesStorage.SELECTED_SERVICES
    );

    return {
        getAdditionalServicesSelection: () => getAdditionalServicesSelection() ?? [],
        setAdditionalServicesSelection,
    };
};
