/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Cookies from "js-cookie";
import { CurrentCustomer } from "../types/customer";
import { getSessionId } from "./sentry";
import { generateCustomerHash } from "./customer/generateCustomerHash";

export const getClarityId = () => {
    const id = typeof window !== "undefined" && Cookies.get("_clck");

    return id ? id.split("|")[0] : undefined;
};

export const initClaritySession = (sessionId: string) => {
    if (typeof window !== "undefined" && typeof window.clarity === "function") {
        window.clarity("set", "storefront", "reached");
        window.clarity("set", "sentry_session_id", sessionId);
    }
};

export const setClarityIdentity = (customer: CurrentCustomer) => {
    if (typeof window !== "undefined" && typeof window.clarity === "function") {
        const storeHash = process.env.GATSBY_BIGCOMMERCE_STORE_HASH;

        window.clarity("identify", customer.email, getSessionId());
        window.clarity("set", "customer_id", `${customer.id}`);
        window.clarity("set", "store_hash", storeHash);
        window.clarity("set", "customer_hash", generateCustomerHash(customer.email));

        if (customer.customer_group_id) {
            window.clarity("set", "customer_group_id", `${customer.customer_group_id}`);
        }
    }
};
