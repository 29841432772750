import React from "react";
import { usePriceFormatting } from "../../services/PriceFormat";
import { LineItem } from "../../types/cart";
import { useProductPrice } from "../../hooks/useProductPrice";
import { useFetchCustomer } from "../../queries/useFetchCustomer";
import { useAffiliateTrackingStorage } from "../../hooks/useAffiliateTrackingStorage";
import { getCustomerGroupId } from "../../utils/customer/customerGroups";

interface Props {
    lineItem: LineItem;
    decimals?: boolean;
    clipTrailingZeros?: boolean;
}

const CartPrice = ({ lineItem, decimals = true, clipTrailingZeros = false }: Props) => {
    const { formatPrice } = usePriceFormatting();
    const { data: customer } = useFetchCustomer();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();
    const customerGroupId = getCustomerGroupId(customer, getPartner(), getPublisher());
    const productListPrice = useProductPrice(customerGroupId, lineItem.product_id, lineItem.variant_id);

    const isDiscounted =
        (productListPrice && productListPrice?.is_discounted) ||
        lineItem.extended_list_price !== lineItem.extended_sale_price;

    if (isDiscounted) {
        const retailPrice =
            productListPrice && productListPrice.retail_price > lineItem.extended_list_price
                ? productListPrice.retail_price
                : lineItem.extended_list_price;

        return (
            <span className="flex flex-col">
                <span className="line-through font-extrabold" data-testid={`${lineItem.sku}-cart-list-price`}>
                    {formatPrice(retailPrice, {
                        format: "{price}",
                        decimals,
                        clipTrailingZeros,
                    })}
                </span>
                <span className="text-green-500 font-extrabold" data-testid={`${lineItem.sku}-cart-sale-price`}>
                    {formatPrice(lineItem.extended_sale_price, {
                        format: "{price}",
                        decimals,
                        clipTrailingZeros,
                    })}
                </span>
            </span>
        );
    }

    return (
        <span data-testid={`${lineItem.sku}-cart-sale-price`}>
            {formatPrice(lineItem.extended_list_price, {
                format: "{price}",
                decimals,
                clipTrailingZeros,
            })}
        </span>
    );
};

export default CartPrice;
