import { formatISO } from "date-fns";
import { ConsentEnvironment, MarketingConsent } from "../../types/consent";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";
import { CustomerAttributeName } from "../../types/customerAttributes";
import { Maybe } from "../../types/generics";

export const getMarketingConsentCustomerAttributes = (consent?: Maybe<MarketingConsent>) => {
    if (!consent) {
        return [];
    }

    const { isConsentGiven, date, type, copy } = consent;

    const isConsentGivenAttributeId = getCustomerAttributeIdByName(
        CustomerAttributeName.CONSENT_MARKETING_IS_CONSENT_GIVEN
    );
    const dateAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_MARKETING_DATE);
    const copyAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_MARKETING_COPY);
    const typeAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_MARKETING_TYPE);
    const environmentAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_MARKETING_ENVIRONMENT);
    const originAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_ORIGIN);

    return [
        {
            attribute_id: isConsentGivenAttributeId,
            value: isConsentGiven ?? "",
        },
        {
            attribute_id: typeAttributeId,
            value: type ?? "",
        },
        {
            attribute_id: dateAttributeId,
            value: date ? formatISO(date) : "",
        },
        {
            attribute_id: copyAttributeId,
            value: copy ?? "",
        },
        {
            attribute_id: environmentAttributeId,
            value: ConsentEnvironment.STOREFRONT,
        },
        {
            attribute_id: originAttributeId,
            value: ConsentEnvironment.STOREFRONT,
        },
    ];
};
