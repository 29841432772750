import { CustomerGroup } from "../../types/customerGroups";
import { Maybe, Optional } from "../../types/generics";
import { getItem, storeItem } from "./index";

const CHANNEL_CUSTOMER_GROUPS_AFFILIATE_DICTIONARY = "channel.customer_groups.affiliate_dictionary";

/**
 * Affiliate customer groups
 */

export const setAffiliateCustomerGroups = (customerGroups: CustomerGroup[]) => {
    const dictionary = customerGroups
        .filter((customerGroup) => customerGroup.name.match(new RegExp("^affiliate - ", "i")))
        .reduce(
            (prev, next) => ({
                ...prev,
                [`${next.name}`]: next.id,
            }),
            {}
        );

    storeItem(CHANNEL_CUSTOMER_GROUPS_AFFILIATE_DICTIONARY, dictionary);
};

export const getAffiliateCustomerGroupId = (partner: Maybe<string>, publisher: Maybe<string>): Optional<number> => {
    const dictionary = getItem<Record<string, any>>(CHANNEL_CUSTOMER_GROUPS_AFFILIATE_DICTIONARY);
    const rgx = new RegExp(`^affiliate - ${partner} - ${publisher}`, "i");
    const key = Object.keys(dictionary ?? {}).find((key) => key.match(rgx));

    return dictionary?.[key ?? ""];
};
