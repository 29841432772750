import { useMutation } from "@tanstack/react-query";
import { PropertySelection } from "../types/property";
import { Maybe } from "../types/generics";
import { getCustomerAttributeIdByName } from "../store/ephemeral/customerAttributes";
import { CustomerAttributeName } from "../types/customerAttributes";
import { useSaveCustomerAttributes } from "./useSaveCustomerAttributes";

export interface SaveCustomerPropertySelection {
    selection: PropertySelection;
    customerId?: Maybe<number>;
}
export const useSavePropertySelection = () => {
    const { mutateAsync: saveCustomerAttributes } = useSaveCustomerAttributes();

    return useMutation({
        mutationFn: async ({ selection, customerId }: SaveCustomerPropertySelection) => {
            if (!customerId) {
                return null;
            }

            return await saveCustomerAttributes([
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.PROPERTY_STATUS),
                    customer_id: customerId,
                    value: selection.status ?? "",
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.PROPERTY_TYPE),
                    customer_id: customerId,
                    value: selection.type ?? "",
                },
            ]);
        },
    });
};
