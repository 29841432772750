import { useStaticQuery, graphql } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { getLocale } from "../../utils/locale";
import { findSlice } from "../../utils/prismic";
import { ContentType } from "../../types/prismic";

const useAccessorySelectionPageContent = () => {
    const { languageCode } = getLocale();
    const publishedData = useStaticQuery<Queries.AccessorySelectionPageQuery>(
        graphql`
            query AccessorySelectionPage {
                accessorySelectionPage: allPrismicPage(filter: { data: { page_type: { eq: "accessory-selection" } } }) {
                    nodes {
                        _previewable
                        id
                        data {
                            include_footer
                            meta_description
                            meta_title
                            page_type
                            body {
                                ...PrismicAccessory
                                ...PrismicKlarnaFooter
                            }
                        }
                        lang
                    }
                }
            }
        `
    );

    const { data } = useMergePrismicPreviewData(publishedData);

    const localePageData = data.accessorySelectionPage.nodes.find(({ lang }) => lang === languageCode)?.data;

    const accessoryContent = findSlice<Queries.PrismicAccessoryFragment>(localePageData?.body, ContentType.ACCESSORY);

    const klarnaContent = findSlice<Queries.PrismicKlarnaFooterFragment>(
        localePageData?.body,
        ContentType.KLARNA_FOOTER
    );

    return { accessoryContent, klarnaContent, localePageData };
};

export default useAccessorySelectionPageContent;
