import React from "react";
import classNames from "classnames";

type Props = React.HTMLAttributes<HTMLDivElement> & Required<Pick<React.HTMLAttributes<HTMLDivElement>, "children">>;

function ModalSection({ className, ...rest }: Props) {
    return <div className={classNames("p-2", className)} {...rest} />;
}

export default ModalSection;
