import { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useChannel } from "../hooks/BigCommerce/useChannel";
import { Channel } from "../types/channel";
import { CreateCustomerAttributes, Customer } from "../types/customer";
import { createCustomer } from "../services/bigCommerce/proxy";
import { OriginType } from "../types/origin";
import { useOriginStorage } from "../hooks/useOriginStorage";
import { useCustomerStorage } from "../hooks/useCustomerStorage";
import { getCustomerGroupId } from "../utils/customer/customerGroups";
import { useAffiliateTrackingStorage } from "../hooks/useAffiliateTrackingStorage";
import { CUSTOMER_QUERY_KEY } from "./useFetchCustomer";

export const useCreateCustomer = () => {
    const queryClient = useQueryClient();
    const { setCurrentCustomer } = useCustomerStorage();
    const { getOrigin } = useOriginStorage();
    const channel = useChannel() as Channel;
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();

    return useMutation<Customer, AxiosError, CreateCustomerAttributes>({
        mutationFn: async (request) => {
            const response = await createCustomer([
                {
                    ...request,
                    accepts_product_review_abandoned_cart_emails:
                        !getOrigin() || getOrigin().type === OriginType.ORGANIC,
                    origin_channel_id: channel.id,
                    channel_ids: [channel.id],
                    customer_group_id: getCustomerGroupId(undefined, getPartner(), getPublisher()),
                },
            ]);

            if (response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
                throw new AxiosError(
                    "Unprocessable entity",
                    `${StatusCodes.UNPROCESSABLE_ENTITY}`,
                    undefined,
                    undefined,
                    response
                );
            }

            const customer = response.data.data[0];
            setCurrentCustomer({
                address_count: 0,
                attribute_count: 0,
                customer_group_id: customer.customer_group_id,
                email: customer.email,
                id: customer.id,
            });

            return customer;
        },
        onSuccess: (customer) => {
            queryClient.setQueryData([CUSTOMER_QUERY_KEY, customer.id], customer);
        },
    });
};
