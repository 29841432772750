import React from "react";
import { Popover } from "@headlessui/react";
import useSiteConfig from "../../../hooks/useSiteConfig";
import Icon, { Icons } from "../../Icons/Icon";
import { trackLogoutEvent, trackNavbarClick } from "../../../services/tracking";
import useNavigationPages from "../../../hooks/useNavigationPages";
import { Pages } from "../../../types/navigation";
import { logout } from "../../../services/auth";
import Card from "../../Card/Card";
import { useFetchCustomer } from "../../../queries/useFetchCustomer";

function Menu() {
    const { data: customer, isAuthReady, isEnabled, isPending } = useFetchCustomer();
    const { navigateTo } = useNavigationPages();
    const siteConfigData = useSiteConfig();

    const onLogout = async () => {
        trackNavbarClick("Header", siteConfigData.sign_out_text);
        if (customer) {
            trackLogoutEvent(`${customer.id}`);
        }

        await logout();
        await navigateTo(Pages.HOME_PAGE);
    };

    if (!isAuthReady || (isEnabled && isPending)) {
        return <span className="font-extrabold text-base mr-8 p-4">{siteConfigData.loading_text}</span>;
    }

    if (!customer) {
        return (
            <button
                className="font-extrabold text-base mr-8 hover:text-green-600 p-4"
                onClick={async () => {
                    trackNavbarClick("Header", siteConfigData.sign_in_text);
                    await navigateTo(Pages.LOGIN);
                }}
            >
                {siteConfigData.sign_in_text}
            </button>
        );
    }

    return (
        <Popover>
            <Popover.Button
                type="button"
                className="inline-flex items-center rounded-md border border-none bg-gray-50 font-bold text-base mr-8 p-4 hover:text-green-600 focus:text-green-600 active:text-green-600 focus:outline-none"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
            >
                {customer?.first_name}
                <Icon icon={Icons.CARET_DOWN} className="pl-2 font-bold" />
            </Popover.Button>
            <Popover.Panel
                className="origin-top-right absolute right-0 rounded-md shadow-lg bg-white w-[85vw] xs:max-w-[400px] z-10"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
            >
                <div className="flex flex-col items-center text-center w-full" role="none">
                    <div className="flex flex-col text-left w-full p-4">
                        <p className="w-full break-all font-bold">
                            {customer.first_name} {customer.last_name}
                        </p>
                        <p className="w-full break-all font-light text-gray-500">{customer.email}</p>
                    </div>
                    <Card className="block font-extrabold text-base hover:text-green-600 !rounded-t-none cursor-pointer w-full h-full !bg-gray-100">
                        <button onClick={onLogout} className="flex flex-row w-full gap-2 items-center justify-center">
                            <Icon icon={Icons.LOGOUT_LIGHT} />
                            {siteConfigData.sign_out_text}
                        </button>
                    </Card>
                </div>
            </Popover.Panel>
        </Popover>
    );
}

export default Menu;
