import { useStaticQuery, graphql } from "gatsby";
import { ProductCategory } from "../../types/products";

const useCategories = () => {
    const { categories } = useStaticQuery<Categories>(
        graphql`
            query {
                categories {
                    data {
                        category_id
                        parent_id
                        name
                        description
                        sort_order
                        image_url
                    }
                }
            }
        `
    );

    return categories.data;
};

export default useCategories;

export type Category = ProductCategory;

export interface CategoriesData {
    data: Category[];
}

export interface Categories {
    categories: CategoriesData;
}
