import { useStaticQuery, graphql } from "gatsby";
import { getLocale } from "../utils/locale";

const useHeaderConfig = () => {
    const { languageCode } = getLocale();
    const data = useStaticQuery<Queries.HeaderDataFragmentFragment>(
        graphql`
            query {
                ...HeaderDataFragment
            }
        `
    );

    return data.headerConfig.nodes.find(({ lang }: { lang: string }) => languageCode === lang)?.data;
};

export default useHeaderConfig;
