import { VehicleSelection, VehicleStorage } from "../types/vehicle";
import { Maybe } from "../types/generics";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const useVehicleSelectionStorage = () => {
    const [getVehicleSelection, setVehicleSelection] = useTransactionalLocalStorage<Maybe<VehicleSelection>>(
        VehicleStorage.SELECTION
    );

    return {
        getVehicleSelection,
        setVehicleSelection,
    };
};
