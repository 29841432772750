import React, { useMemo, useState } from "react";
import Link from "../Buttons/Link";
import { LineItem } from "../../types/cart";
import useCartReviewPageContent from "../../hooks/Prismic/useCartReviewPageContent";
import { kebabCase } from "../../utils/str/case";
import { useRemoveLineItem } from "../../queries/cart/lineItem/useRemoveLineItem";
import { useUpdateLineItemQuantity } from "../../queries/cart/lineItem/useUpdateLineItemQuantity";
import { getCartTracking, trackRemoveFromCart } from "../../services/tracking";
import useChargers from "../../hooks/BigCommerce/useChargers";
import { useFetchCart } from "../../queries/cart/useFetchCart";
import useProducts from "../../hooks/BigCommerce/useProducts";
import { usePropertySelectionStorage } from "../../hooks/usePropertySelectionStorage";
import { useVehicleSelectionStorage } from "../../hooks/useVehicleSelectionStorage";
import QuantityPicker, { StyleVariant } from "./QuantityPicker";
import RemoveItemFromCartModal from "./RemoveItemFromCartModal";

interface Props {
    lineItem: LineItem;
    minQuantity: number;
    maxQuantity: number;
    styleVariant?: StyleVariant;
    allowRemoval?: boolean;
}

export default function ItemQuantityAndRemoval({
    lineItem,
    minQuantity,
    maxQuantity,
    styleVariant = "default",
    allowRemoval = true,
}: Props) {
    const { reviewContent } = useCartReviewPageContent();
    const chargers = useChargers();
    const products = useProducts();

    const { data: cart } = useFetchCart();
    const { mutate: updateLineItemQuantity, isPending: isProductUpdating } = useUpdateLineItemQuantity();
    const { mutateAsync: removeLineItem, isPending: isProductRemoving } = useRemoveLineItem();
    const { getPropertySelection } = usePropertySelectionStorage();
    const { getVehicleSelection } = useVehicleSelectionStorage();

    const [removeItemConfirmation, setRemoveItemConfirmation] = useState(false);

    const product = useMemo(
        () => products.find(({ id }) => id === lineItem.product_id),
        [lineItem.product_id, products]
    );

    const propertySelection = getPropertySelection();
    const cartEvent = getCartTracking(product, undefined, cart, chargers, propertySelection, getVehicleSelection());
    const isLoading = isProductRemoving || isProductUpdating;
    const isCharger = chargers.map((charger) => charger.id).includes(lineItem.product_id);

    const onRemoveFromCart = async () => {
        await removeLineItem({ lineItem });
        if (!isCharger) {
            trackRemoveFromCart(cartEvent);
        }
    };

    const onQuantityUpdate = async (value: number) => {
        if (value === 0) {
            setRemoveItemConfirmation(true);

            if (!isCharger) {
                trackRemoveFromCart(cartEvent);
            }
        }

        if (value > 0) {
            updateLineItemQuantity({ lineItem, quantity: value });
        }
    };

    return (
        <div className="flex">
            <QuantityPicker
                min={minQuantity}
                max={maxQuantity}
                isLoading={isLoading}
                value={lineItem.quantity}
                onChange={onQuantityUpdate}
                styleVariant={styleVariant}
                testId={`${lineItem.product_id}`}
            />
            {allowRemoval && (
                <>
                    {!isLoading && minQuantity === 0 ? (
                        <Link
                            testId={`accessory-cart-item-remove-${kebabCase(lineItem.name)}`}
                            className="text-sm ml-2"
                            onClick={() => setRemoveItemConfirmation(true)}
                        >
                            {reviewContent?.primary.remove}
                        </Link>
                    ) : null}

                    <RemoveItemFromCartModal
                        onCancel={() => setRemoveItemConfirmation(false)}
                        onRemoveItemConfirm={onRemoveFromCart}
                        removeItemButtonIsDisabled={isLoading}
                        isOpen={removeItemConfirmation}
                        onClose={() => setRemoveItemConfirmation(false)}
                    />
                </>
            )}
        </div>
    );
}
