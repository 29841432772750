import { CurrentCustomer, CustomerStorage } from "../types/customer";
import { setUser as setSentryIdentity } from "../utils/sentry";
import { setClarityIdentity as setClarityIdentity } from "../utils/clarity";
import { Maybe } from "../types/generics";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const useCustomerStorage = () => {
    const [getCurrentCustomer, setCurrentCustomer] = useTransactionalLocalStorage<Maybe<CurrentCustomer>>(
        CustomerStorage.CURRENT
    );

    return {
        getCurrentCustomer,
        setCurrentCustomer: (customer: Maybe<CurrentCustomer>) => {
            setCurrentCustomer(customer);

            if (customer) {
                setSentryIdentity(customer);
                setClarityIdentity(customer);
            }
        },
    };
};
