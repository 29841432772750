import { useCallback } from "react";
import { navigate } from "gatsby";
import { NavigateOptions, useLocation } from "@reach/router";
import { useLastPageVisitedStorage } from "./useLastPageVisitedStorage";
import { usePageResolver } from "./usePageResolver";

const useNavigationPages = () => {
    const location = useLocation();
    const { latestPossiblePageInFlow } = usePageResolver();
    const { getLastPageVisited, setLastPageVisited } = useLastPageVisitedStorage();

    const navigateTo = useCallback(
        (to: string, options?: NavigateOptions<object>): Promise<void> | undefined => {
            if (typeof window !== undefined) {
                if (location.pathname.toLowerCase() === to.toLowerCase()) {
                    window.location.reload();
                } else {
                    return options && Object.keys(options).length > 0 ? navigate(to, options) : navigate(to);
                }
            }
        },
        [location.pathname]
    );

    const navigateToLastPageVisited = () => navigateTo(getLastPageVisited() ?? latestPossiblePageInFlow);
    const navigateToLatestPageInFlow = () => navigateTo(latestPossiblePageInFlow);

    /**
     * For external URLs only, starting with http:// or https://.
     */
    const navigateToUrl = (url: string) => {
        if (typeof window !== undefined) {
            window.location.assign(url);
        }
    };

    return {
        navigateToLastPageVisited,
        navigateToLatestPageInFlow,
        navigateTo,
        navigateToUrl,
        lastPage: getLastPageVisited(),
        setLastPage: setLastPageVisited,
    };
};

export default useNavigationPages;
