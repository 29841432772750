export enum FirebaseAuthErrorCodes {
    EXPIRED_OOB_CODE = "auth/expired-action-code",
    INTERNAL_ERROR = "auth/internal-error",
    INVALID_CODE = "auth/invalid-verification-code",
    INVALID_CREDENTIAL = "auth/invalid-credential",
    INVALID_EMAIL = "auth/invalid-email",
    INVALID_MFA_SESSION = "auth/invalid-multi-factor-session",
    INVALID_PASSWORD = "auth/wrong-password",
    INVALID_SESSION_INFO = "auth/invalid-verification-id",
    MFA_REQUIRED = "auth/multi-factor-auth-required",
    MFA_SMS_UNAVAILABLE = "auth/multi-factor-sms-unavailable",
    MISSING_SESSION_INFO = "auth/missing-verification-id",
    TOO_MANY_ATTEMPTS_TRY_LATER = "auth/too-many-requests",
    USER_DELETED = "auth/user-not-found",
}
