import { graphql, useStaticQuery } from "gatsby";
import { Product } from "../../types/products";

const useChargers = () => {
    const { chargers } = useStaticQuery<Chargers>(
        graphql`
            query {
                chargers {
                    data {
                        id
                        sku
                        categories
                        name
                        price
                        sale_price
                        calculated_price
                        type
                        description
                        sort_order
                        images {
                            id
                            product_id
                            description
                            is_thumbnail
                            sort_order
                            url_standard
                            url_thumbnail
                            url_tiny
                            url_zoom
                            image_cart {
                                gatsbyImageData {
                                    width
                                    height
                                    images {
                                        fallback {
                                            sizes
                                            src
                                            srcSet
                                        }
                                    }
                                    layout
                                }
                            }
                            image_thumbnail {
                                gatsbyImageData {
                                    width
                                    height
                                    images {
                                        fallback {
                                            sizes
                                            src
                                            srcSet
                                        }
                                    }
                                    layout
                                }
                            }
                        }
                        variants {
                            id
                            sku
                            option_values {
                                id
                                label
                                option_display_name
                                option_id
                            }
                            price
                            sale_price
                            calculated_price
                            image_url
                            purchasing_disabled
                        }
                        custom_fields {
                            name
                            value
                        }
                        options {
                            option_values {
                                is_default
                                id
                                sort_order
                                label
                            }
                            display_name
                            id
                            name
                        }
                    }
                }
            }
        `
    );

    return chargers.data;
};

export default useChargers;

export interface Chargers {
    chargers: {
        data: Product[];
    };
}
