/**
 * Price format hook
 * A few utility functions to format prices based on the selected currency.
 */
import useChannelCurrency from "../hooks/BigCommerce/useChannelCurrency";
import { getLocale } from "../utils/locale";
import { CurrencyCode } from "../types/currency";
import { parseParams } from "../utils/str/parseParams";

export interface FormatOptions {
    decimals?: boolean;
    clipTrailingZeros?: boolean;
    format?: string;
}

export const usePriceFormatting = () => {
    const { languageCode } = getLocale();
    const currency = useChannelCurrency();

    const formatPrice = (price: number, options?: FormatOptions) => {
        const getFractionDigitLength = () => {
            const priceHasNoPence = price % 1 === 0;

            if (options?.clipTrailingZeros && priceHasNoPence) {
                return 0;
            }

            return options?.decimals === false ? 0 : 2;
        };

        const formatted = Intl.NumberFormat(languageCode, {
            style: "currency",
            currency: currency ?? CurrencyCode.GBP,
            minimumFractionDigits: getFractionDigitLength(),
            maximumFractionDigits: getFractionDigitLength(),
        }).format(price);

        if (options?.format) {
            return parseParams(options.format, { price: formatted });
        }

        return formatted;
    };

    return {
        formatPrice,
    };
};
