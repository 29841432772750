import { AccessToken } from "../types/auth";
import { clearStorage } from "../utils/storage";
import User from "../types/user";
import { Maybe } from "../types/generics";
import * as ecommerceService from "./ecommerceService";
import {
    browserLocalPersistence,
    logIn as logInFirebase,
    LoginFormData,
    logOut as logOutFirebase,
    MultiFactorFormData,
    multiFactorSmsVerify,
} from "./firebaseService";

export const login = async ({ email, password, remember }: LoginFormData) => {
    const { user } = await logInFirebase(email, password, remember ? browserLocalPersistence : undefined);

    return user;
};

export const logout = async () => {
    await logOutFirebase();
    clearStorage();
};

export const exchangeToken = async (token: AccessToken): Promise<User> => {
    const response = await ecommerceService.getUser(token);

    return typeof response.data === "string" ? (JSON.parse(response.data) as User) : response.data;
};

export const multiFactor = async ({ code }: MultiFactorFormData, verificationId: Maybe<string>) => {
    if (!verificationId) {
        throw new Error("Multi factor verification ID missing.");
    }

    const { user } = await multiFactorSmsVerify(verificationId, code);

    return user;
};
