import { useCallback } from "react";
import { usePropertySelection } from "../usePropertySelection";
import { useMarketingConsentStorage } from "../useMarketingConsentStorage";
import { useOriginStorage } from "../useOriginStorage";
import { useInstallationSelectionStorage } from "../useInstallationSelectionStorage";
import { useVehicleSelection } from "../useVehicleSelection";
import { useCartSelectionStorage } from "../useCartSelectionStorage";
import { useFetchCart } from "../../queries/cart/useFetchCart";
import { useUpdateProductSelections } from "../../queries/cart/lineItem/useUpdateProductSelections";
import { useFetchPreExistingCartAndAddCurrentCartLineItems } from "../../queries/cart/lineItem/useFetchPreExistingCartAndAddCurrentCartLineItems";
import { useAssociateCartWithCustomer } from "../../queries/cart/useAssociateCartWithCustomer";
import { getCustomerAttributeValueByName } from "../../utils/customer/getCustomerAttributeValueByName";
import { CustomerAttributeName } from "../../types/customerAttributes";
import { getOriginFromCustomerAttributes } from "../../utils/origin/getOriginFromCustomerAttributes";
import { getMarketingConsentFromCustomerAttributes } from "../../utils/consent/getMarketingConsentFromCustomerAttributes";
import { getInstallationSelectionFromCustomerAttributes } from "../../utils/installation/getInstallationSelectionFromCustomerAttributes";
import { getPropertySelectionFromCustomerAttributes } from "../../utils/property/getPropertySelectionFromCustomerAttributes";
import { getVehicleSelectionFromCustomerAttributes } from "../../utils/vehicle/getVehicleSelectionFromCustomerAttributes";
import { Maybe, Optional } from "../../types/generics";
import { PropertySelection } from "../../types/property";
import { VehicleSelection } from "../../types/vehicle";
import { Customer } from "../../types/customer";

const shouldSavePropertySelection = (
    property: Optional<Maybe<PropertySelection>>,
    attrProperty: Optional<Maybe<PropertySelection>>
): property is PropertySelection =>
    !!property && !!attrProperty && (property.status !== attrProperty.status || property.type !== attrProperty.type);

const shouldSaveVehicleSelection = (
    vehicle: Optional<Maybe<VehicleSelection>>,
    attrVehicle: Optional<Maybe<VehicleSelection>>
): vehicle is VehicleSelection =>
    !!vehicle &&
    !!attrVehicle &&
    (vehicle.uid !== attrVehicle.uid ||
        vehicle.type !== attrVehicle.type ||
        vehicle.model !== attrVehicle.model ||
        vehicle.brand !== attrVehicle.brand ||
        vehicle.condition !== attrVehicle.condition ||
        vehicle.connector !== attrVehicle.connector);

export const useUpdateSelections = () => {
    const { selection: propertySelection, storePropertySelection, savePropertySelection } = usePropertySelection();
    const { getMarketingConsent, storeMarketingConsent } = useMarketingConsentStorage();
    const { getOrigin, setOrigin } = useOriginStorage();
    const { selection: installationSelection, storeInstallationSelection } = useInstallationSelectionStorage();
    const { getVehicleSelection, setVehicleSelection, saveVehicleSelection } = useVehicleSelection();
    const { setCartId } = useCartSelectionStorage();
    const { data: cart } = useFetchCart();
    const { updateProductSelections } = useUpdateProductSelections();
    const { mutateAsync: fetchPreExistingCartAndAddCurrentCartLineItems } =
        useFetchPreExistingCartAndAddCurrentCartLineItems();
    const { mutateAsync: associateCartWithCustomer } = useAssociateCartWithCustomer();

    return useCallback(
        async (customer?: Maybe<Customer>) => {
            if (!customer) {
                return;
            }

            const preExistingCartId = getCustomerAttributeValueByName(
                CustomerAttributeName.CART_ID,
                customer.attributes
            )?.attribute_value;

            if (!cart?.id && preExistingCartId) {
                setCartId(preExistingCartId);
            }

            if (cart?.id && !preExistingCartId) {
                await associateCartWithCustomer({ cart, customer });
            }

            if (cart?.id && preExistingCartId && cart.id !== preExistingCartId) {
                await fetchPreExistingCartAndAddCurrentCartLineItems({
                    currentCartId: cart.id,
                    preExistingCartId,
                    customer,
                });
            }

            setOrigin(getOriginFromCustomerAttributes(customer.attributes, getOrigin()));
            storeMarketingConsent(
                getMarketingConsentFromCustomerAttributes(customer.attributes, getMarketingConsent())
            );
            storeInstallationSelection(
                getInstallationSelectionFromCustomerAttributes(customer.attributes, installationSelection)
            );

            const attributesPropertySelection = getPropertySelectionFromCustomerAttributes(customer.attributes);
            const attributesVehicleSelection = getVehicleSelectionFromCustomerAttributes(customer.attributes);
            const vehicleSelection = getVehicleSelection();

            if (shouldSaveVehicleSelection(vehicleSelection, attributesVehicleSelection)) {
                await saveVehicleSelection(vehicleSelection);
            } else if (attributesVehicleSelection) {
                setVehicleSelection(attributesVehicleSelection);
            }

            if (shouldSavePropertySelection(propertySelection, attributesPropertySelection)) {
                await savePropertySelection(propertySelection);
            } else if (attributesPropertySelection) {
                storePropertySelection(attributesPropertySelection);
            }

            await updateProductSelections();
        },
        [
            associateCartWithCustomer,
            cart,
            fetchPreExistingCartAndAddCurrentCartLineItems,
            installationSelection,
            getMarketingConsent,
            propertySelection,
            savePropertySelection,
            saveVehicleSelection,
            setCartId,
            storeInstallationSelection,
            storeMarketingConsent,
            getOrigin,
            setOrigin,
            storePropertySelection,
            setVehicleSelection,
            updateProductSelections,
            getVehicleSelection,
        ]
    );
};
