import React, { useEffect, useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import useNavigationPages from "../hooks/useNavigationPages";
import { clearStorage } from "../utils/storage";
import { Pages } from "../types/navigation";
import useSiteConfig from "../hooks/useSiteConfig";
import Link from "./Buttons/Link";

const Spinner = () => {
    const { navigateTo } = useNavigationPages();
    const { loading_error_self_serve, start_your_order_again } = useSiteConfig();
    const [showHelper, setShowHelper] = useState(false);

    const showHelpDelayTime = 5000; // 5 seconds

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowHelper(true);
        }, showHelpDelayTime);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            className="w-full h-full flex flex-col gap-12 items-center justify-center absolute z-40 bg-gray-50"
            data-testid="loading-spinner"
        >
            <div role="status">
                <svg
                    id="Layer_2"
                    data-name="Layer 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 22.29 30.35"
                    height="100"
                >
                    <path
                        id="bolt"
                        d="M9.28,29.35c-.83,0-1.51-.68-1.51-1.51v-7.95H2.51c-.29,0-.57-.08-.81-.24-.7-.46-.91-1.39-.46-2.09L11.46,1.7c.29-.45,.8-.75,1.33-.7,.41,.02,.78,.19,1.05,.49s.41,.69,.39,1.09l-.08,7.74h5.61c.29,0,.58,.08,.82,.24,.34,.22,.57,.56,.66,.96,.08,.4,0,.8-.22,1.14L10.54,28.67c-.29,.43-.76,.68-1.26,.68Z"
                        fill="none"
                        stroke="#8FC043"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </svg>
            </div>
            {showHelper && (
                <div data-testid="self-service-helper" className="text-center">
                    <PrismicRichText
                        field={loading_error_self_serve.richText}
                        components={{
                            heading6: ({ children }) => (
                                <p className="paragraph">
                                    {children}{" "}
                                    <Link
                                        testId="restart-order-link"
                                        className="cursor-pointer"
                                        dashed
                                        onClick={async () => {
                                            clearStorage();
                                            await navigateTo(Pages.HOME_PAGE);
                                        }}
                                    >
                                        {start_your_order_again}
                                    </Link>
                                </p>
                            ),
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default Spinner;
