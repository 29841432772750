import { useSaveLastPageVisited } from "../queries/useSaveLastPageVisited";
import { useLastPageVisitedStorage } from "./useLastPageVisitedStorage";

export const useLastPageVisited = () => {
    const { getLastPageVisited, setLastPageVisited: storeLastPageVisited } = useLastPageVisitedStorage();
    const { mutateAsync: saveLastPageVisitedAsync, mutate: saveLastPageVisited } = useSaveLastPageVisited();

    return {
        getLastPageVisited,
        storeLastPageVisited,
        saveLastPageVisited,
        saveLastPageVisitedAsync,
    };
};
