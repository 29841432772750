import { useStaticQuery, graphql } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { getLocale } from "../utils/locale";

const useFooterConfig = () => {
    const { languageCode } = getLocale();
    const publishedData = useStaticQuery<Queries.FooterDataFragmentFragment>(
        graphql`
            query {
                ...FooterDataFragment
            }
        `
    );

    const { data } = useMergePrismicPreviewData(publishedData);

    return data.footerConfig.nodes.find(({ lang }) => languageCode === lang)?.data;
};

export default useFooterConfig;
