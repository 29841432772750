export enum CustomerAttributeName {
    CART_ID = "cart_id",
    CONSENT_EMAIL_COPY = "consent_email_copy",
    CONSENT_EMAIL_DATE = "consent_email_date",
    CONSENT_EMAIL_ENVIRONMENT = "consent_email_environment",
    CONSENT_EMAIL_IS_CONSENT_GIVEN = "consent_email_is_consent_given",
    CONSENT_EMAIL_TYPE = "consent_email_type",
    CONSENT_MARKETING_COPY = "consent_marketing_copy",
    CONSENT_MARKETING_DATE = "consent_marketing_date",
    CONSENT_MARKETING_ENVIRONMENT = "consent_marketing_environment",
    CONSENT_MARKETING_IS_CONSENT_GIVEN = "consent_marketing_is_consent_given",
    CONSENT_MARKETING_TYPE = "consent_marketing_type",
    CONSENT_ORIGIN = "consent_origin",
    CONSENT_PHONE_COPY = "consent_phone_copy",
    CONSENT_PHONE_DATE = "consent_phone_date",
    CONSENT_PHONE_ENVIRONMENT = "consent_phone_environment",
    CONSENT_PHONE_IS_CONSENT_GIVEN = "consent_phone_is_consent_given",
    CONSENT_PHONE_TYPE = "consent_phone_type",
    INSTALLATION_CABLE_LENGTH = "installation_cable_length",
    INSTALLATION_DIG_REQUIRED = "installation_dig_required",
    INSTALLATION_TRUCKING_LENGTH = "installation_trucking_length",
    INSTALLATION_TYPE = "installation_type",
    LAST_PAGE_VISITED = "last_page_visited",
    ORIGIN_SYSTEM_ID = "origin_system_id",
    PARTNER = "partner",
    PROPERTY_STATUS = "property_status",
    PROPERTY_TYPE = "property_type",
    SEND_WELCOME = "send_welcome",
    SOURCE_CHANNEL_CATEGORY = "source_channel_category",
    SOURCE_ENVIRONMENT = "source_environment",
    VEHICLE_CONDITION = "vehicle_condition",
    VEHICLE_CONNECTOR = "vehicle_connector",
    VEHICLE_MAKE = "vehicle_make",
    VEHICLE_MODEL = "vehicle_model",
    VEHICLE_TYPE = "vehicle_type",
    VEHICLE_UID = "vehicle_uid",
}

export interface CustomerAttribute {
    id: number;
    name: CustomerAttributeName;
    type: string;
}

export interface CustomerAttributesResponse {
    data: CustomerAttribute[];
}

export interface CustomerAttributeValue {
    id: number;
    customer_id: number;
    attribute_id: number;
    attribute_value: string;
}

export interface MappedCustomerAttributeValue {
    attribute_id?: number;
    value: string;
}
