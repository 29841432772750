import { CustomerAttributeName, CustomerAttributeValue } from "../../types/customerAttributes";
import {
    InstallationCableLength,
    InstallationDigRequired,
    InstallationQuestions,
    InstallationSelection,
    InstallationTruckingLength,
} from "../../types/installation";
import { getCustomerAttributeValueByName } from "../customer/getCustomerAttributeValueByName";
import { Maybe, Optional } from "../../types/generics";

export const getInstallationSelectionFromCustomerAttributes = (
    attributes?: CustomerAttributeValue[],
    selection?: Maybe<InstallationSelection>
): Optional<Maybe<InstallationSelection>> => {
    if (!attributes) {
        return selection;
    }

    return {
        [InstallationQuestions.TRUCKING_LENGTH]: getCustomerAttributeValueByName(
            CustomerAttributeName.INSTALLATION_TRUCKING_LENGTH,
            attributes
        )?.attribute_value as Optional<Maybe<InstallationTruckingLength>>,
        [InstallationQuestions.CABLE_LENGTH]: getCustomerAttributeValueByName(
            CustomerAttributeName.INSTALLATION_CABLE_LENGTH,
            attributes
        )?.attribute_value as Optional<Maybe<InstallationCableLength>>,
        [InstallationQuestions.DIG_REQUIRED]: getCustomerAttributeValueByName(
            CustomerAttributeName.INSTALLATION_DIG_REQUIRED,
            attributes
        )?.attribute_value as Optional<Maybe<InstallationDigRequired>>,
    };
};
