import React, { useMemo } from "react";
import classNames from "classnames";
import { usePageResolver } from "../../../hooks/usePageResolver";
import useProgressBarConfig from "../../../hooks/Prismic/useProgressBarConfig";
import Link from "../../Buttons/Link";
import useLocation from "../../../hooks/useLocation";

export default function ProgressBar() {
    const { getProgressInFlow } = usePageResolver();
    const progressBarConfig = useProgressBarConfig();
    const location = useLocation();

    const { current, total, percent, steps } = useMemo(
        () => getProgressInFlow(location, progressBarConfig.labelled_steps),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    );

    return (
        <div className="col-span-2 justify-self-center w-full max-w-2xl">
            <div
                className="w-full h-2 relative"
                role="progressbar"
                aria-valuemin={0}
                aria-valuemax={total}
                aria-valuenow={current}
            >
                <div className="h-full w-full min-w-2 rounded-full bg-gray-200 absolute" />

                <div
                    className="h-full min-w-2 rounded-full bg-green-400 absolute"
                    data-testid="progressbar-indicator"
                    style={{ width: `${percent}%` }}
                />
            </div>

            <div className="w-full flex justify-between" role="presentation" data-testid="progress-step">
                {steps.map((step) => {
                    return (
                        <div
                            className={classNames("xs:text-sm text-xs", {
                                "text-green-500 font-semibold": step.isCurrent,
                                "text-gray-300": !step.isCurrent && !step.isPast,
                            })}
                            key={step.path}
                            aria-current={step.isCurrent ? "step" : undefined}
                        >
                            {step.isPast ? (
                                <Link isSecondary className="!font-semibold" to={step.path}>
                                    {step.label}
                                </Link>
                            ) : (
                                <>{step.label}</>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
