import React from "react";
import ReactDOMServer from "react-dom/server";
import Icon, { Icons } from "../components/Icons/Icon";

export const appendLinkIcons = (html: string): string => {
    const iconHtml = ReactDOMServer.renderToString(
        <>
            {" "}
            <Icon className="" icon={Icons.EXPAND_LIGHT} />
        </>
    );

    return html.replace(/<\/a>/g, `${iconHtml}</a>`);
};
