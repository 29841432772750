import { useStaticQuery, graphql } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { getLocale } from "../../utils/locale";
import { findSlice } from "../../utils/prismic";
import { ContentType } from "../../types/prismic";

const useCartReviewPageContent = () => {
    const { languageCode } = getLocale();
    const publishedData = useStaticQuery<Queries.CartReviewPageQuery>(
        graphql`
            query CartReviewPage {
                cartReviewPage: allPrismicPage(filter: { data: { page_type: { eq: "cart-review" } } }) {
                    nodes {
                        _previewable
                        id
                        data {
                            include_footer
                            meta_title
                            meta_description
                            page_type
                            body {
                                ...PrismicCartPage
                                ...PrismicKlarnaFooter
                            }
                        }
                        lang
                    }
                }
            }
        `
    );

    const { data } = useMergePrismicPreviewData(publishedData);

    const localePageData = data.cartReviewPage.nodes.find(({ lang }) => lang === languageCode)?.data;

    const reviewContent = findSlice<Queries.PrismicCartPageFragment>(
        localePageData?.body,
        ContentType.CART_REVIEW_PAGE
    );

    return { reviewContent, localePageData };
};

export default useCartReviewPageContent;
