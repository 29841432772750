import { graphql, useStaticQuery } from "gatsby";

const useRemoveItemFromCartModalContent = () => {
    const { prismicRemoveItemFromCartModal } = useStaticQuery<Queries.RemoveItemFromCardModalQuery>(
        graphql`
            query RemoveItemFromCardModal {
                prismicRemoveItemFromCartModal {
                    id
                    data {
                        action_button
                        cancel_button
                        description
                        title
                    }
                    lang
                }
            }
        `
    );

    return prismicRemoveItemFromCartModal?.data;
};

export default useRemoveItemFromCartModalContent;

export type RemoveItemFromCartModalContent = ReturnType<typeof useRemoveItemFromCartModalContent>;

// export interface RemoveItemFromCartModalContent {
//     action_button: string;
//     cancel_button: string;
//     description: string;
//     title: string;
// }
//
// export interface PrismicRemoveItemFromCartModal {
//     data: RemoveItemFromCartModalContent;
//     id: string;
//     lang: string;
// }
//
// export interface PrismicRemoveItemFromCartModalData {
//     prismicRemoveItemFromCartModal: PrismicRemoveItemFromCartModal;
// }
