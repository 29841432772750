import { Customer } from "../../types/customer";
import { Maybe, Optional } from "../../types/generics";
import { getDefaultCustomerGroupId, getGuestCustomerGroupId } from "../../store/ephemeral/channel";
import { getAffiliateCustomerGroupId } from "../../store/ephemeral/customerGroups";

export const getCustomerGroupId = (
    customer: Optional<Maybe<Customer>>,
    partner: Maybe<string>,
    publisher: Maybe<string>
) => {
    if (customer && customer.customer_group_id && customer.customer_group_id !== getDefaultCustomerGroupId()) {
        return customer.customer_group_id;
    }

    const affiliateGroupId = getAffiliateCustomerGroupId(partner, publisher);

    if (affiliateGroupId) {
        return affiliateGroupId;
    }

    if (!customer) {
        return getGuestCustomerGroupId();
    }

    if (!customer.customer_group_id) {
        return getDefaultCustomerGroupId();
    }

    return customer.customer_group_id;
};
