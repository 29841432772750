import { CustomerAttributeName, CustomerAttributeValue } from "../../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";
import { Maybe } from "../../types/generics";

export const getCustomerAttributeValueByName = (
    name: CustomerAttributeName,
    attributes?: CustomerAttributeValue[]
): Maybe<CustomerAttributeValue> => {
    const attributeId = getCustomerAttributeIdByName(name);

    if (attributeId > 0 && attributes) {
        return attributes.find((value) => value.attribute_id === attributeId) ?? null;
    }

    return null;
};
