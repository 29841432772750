import { CustomerAttributeName } from "../../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";
import { PropertySelection } from "../../types/property";

export const getPropertyCustomerAttributes = (selection: PropertySelection) => {
    const { type, status } = selection;

    const typeAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.PROPERTY_TYPE);
    const statusAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.PROPERTY_STATUS);

    return [
        {
            attribute_id: typeAttributeId,
            value: type ?? "",
        },
        {
            attribute_id: statusAttributeId,
            value: status ?? "",
        },
    ];
};
