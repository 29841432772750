import { useEffect, useState } from "react";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const useStatefulLocalStorage = <T>(key: string, defaultValue: T): [T, (value: T) => void] => {
    const [getLocalStorageValue, setLocalStorageValue] = useTransactionalLocalStorage<T>(key);
    const [state, setState] = useState<T>(() => getLocalStorageValue() ?? defaultValue);

    const setValue = (value: T) => {
        setState(value);
        setLocalStorageValue(value);
    };

    useEffect(() => {
        // Update state if the localStorage value changes
        const localStorageValue = getLocalStorageValue();
        if (localStorageValue !== null && JSON.stringify(localStorageValue) !== JSON.stringify(state)) {
            setState(localStorageValue);
        }
    }, [getLocalStorageValue, state]);

    return [state, setValue];
};
