import { useSaveMarketingConsent } from "../queries/useSaveMarketingConsent";
import { useFetchCustomer } from "../queries/useFetchCustomer";
import { MarketingConsent } from "../types/consent";
import { useMarketingConsentStorage } from "./useMarketingConsentStorage";

export const useMarketingConsent = () => {
    const { data: customer } = useFetchCustomer();
    const { getMarketingConsent, storeMarketingConsent } = useMarketingConsentStorage();
    const { mutateAsync, ...mutateMarketingConsent } = useSaveMarketingConsent();

    const saveMarketingConsent = async (consent: MarketingConsent) => {
        storeMarketingConsent(consent);
        await mutateAsync({ consent, customerId: customer?.id });
    };

    return {
        customer,
        getMarketingConsent,
        storeMarketingConsent,
        saveMarketingConsent,
        mutateMarketingConsent,
    };
};
