import { useCallback } from "react";
import { Maybe } from "../../types/generics";
import { useAssociateCartWithCustomer } from "../../queries/cart/useAssociateCartWithCustomer";
import { useSaveCustomerAttributes } from "../../queries/useSaveCustomerAttributes";
import { useVehicleSelectionStorage } from "../useVehicleSelectionStorage";
import { usePropertySelectionStorage } from "../usePropertySelectionStorage";
import { useMarketingConsentStorage } from "../useMarketingConsentStorage";
import { useOriginStorage } from "../useOriginStorage";
import { useFetchCart } from "../../queries/cart/useFetchCart";
import { Customer } from "../../types/customer";
import { ConsentInput } from "../../types/consent";
import { getPropertyCustomerAttributes } from "../../utils/property/getPropertyCustomerAttributes";
import { getVehicleCustomerAttributes } from "../../utils/vehicle/getVehicleCustomerAttributes";
import { getOriginCustomerAttributes } from "../../utils/origin/getOriginCustomerAttributes";
import {
    getEmailConsentCustomerAttributes,
    getPhoneConsentCustomerAttributes,
} from "../../utils/customerAttributes/consent";
import { getMarketingConsentCustomerAttributes } from "../../utils/consent/getMarketingConsentCustomerAttributes";
import { getSendWelcomeCustomerAttributes } from "../../utils/customer/getSendWelcomeCustomerAttributes";
import { getOriginFromCustomerAttributes } from "../../utils/origin/getOriginFromCustomerAttributes";
import { trackCustomerDetailsSubmitted, trackGenerateLead } from "../../services/tracking";

export const useAfterCustomerCreate = (consentCopy?: Maybe<string>) => {
    const { data: cart, isPending: cartPending } = useFetchCart();
    const { mutateAsync: associateCartWithCustomer, isPending: associateCartWithCustomerPending } =
        useAssociateCartWithCustomer();
    const { mutateAsync: saveCustomerAttributes, isPending: saveCustomerAttributesPending } =
        useSaveCustomerAttributes();
    const { getVehicleSelection } = useVehicleSelectionStorage();
    const { getPropertySelection } = usePropertySelectionStorage();
    const { getMarketingConsent } = useMarketingConsentStorage();
    const { getOrigin, setOrigin } = useOriginStorage();
    const propertySelection = getPropertySelection();
    const vehicleSelection = getVehicleSelection();

    const origin = getOrigin();

    return {
        onAfterCustomerCreate: useCallback(
            async (customer: Customer) => {
                trackGenerateLead(customer, cart, origin);
                trackCustomerDetailsSubmitted(customer, origin);

                await associateCartWithCustomer({ cart, customer });
                const consent: ConsentInput = { copy: consentCopy ?? "", date: new Date() };
                const attributes = [
                    ...(propertySelection ? getPropertyCustomerAttributes(propertySelection) : []),
                    ...(vehicleSelection ? getVehicleCustomerAttributes(vehicleSelection) : []),
                    ...getOriginCustomerAttributes(origin),
                    ...getPhoneConsentCustomerAttributes(consent),
                    ...getEmailConsentCustomerAttributes(consent),
                    ...getMarketingConsentCustomerAttributes(getMarketingConsent()),
                    ...getSendWelcomeCustomerAttributes(customer),
                ].map((attribute) => ({
                    ...attribute,
                    customer_id: customer.id,
                }));

                const attrs = await saveCustomerAttributes(attributes);
                setOrigin(getOriginFromCustomerAttributes(attrs));
            },
            [
                associateCartWithCustomer,
                cart,
                consentCopy,
                getMarketingConsent,
                origin,
                propertySelection,
                saveCustomerAttributes,
                setOrigin,
                vehicleSelection,
            ]
        ),
        isPending: cartPending || associateCartWithCustomerPending || saveCustomerAttributesPending,
    };
};
