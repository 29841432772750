import React, { ButtonHTMLAttributes, forwardRef } from "react";
import classNames from "classnames";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    large?: boolean;
    outline?: boolean;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ disabled, className, outline, large, ...rest }: ButtonProps, ref) => {
        return (
            <button
                disabled={disabled}
                className={classNames(
                    "text-center px-4 py-2 leading-normal rounded-lg border border-green-400",
                    {
                        "cursor-not-allowed opacity-50": disabled,
                        "hover:bg-green-500": !disabled,
                    },
                    {
                        "font-extrabold text-xl": large,
                        "font-bold text-base": !large,
                    },
                    {
                        "text-green-400 hover:text-white": outline,
                        "text-white bg-green-400": !outline,
                    },
                    className
                )}
                {...rest}
                ref={ref}
            />
        );
    }
);
Button.displayName = "Button";

export default Button;
