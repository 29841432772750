import { capitalCase } from "change-case-all";
import { PageDefinition, Pages, PageTypes } from "../types/navigation";
import { getAdditionalServiceOptions } from "./useAdditionalServicesSelection";
import { usePropertySelectionStorage } from "./usePropertySelectionStorage";
import { useInstallationSelectionStorage } from "./useInstallationSelectionStorage";
import { useCustomerStorage } from "./useCustomerStorage";
import { useCartSelectionStorage } from "./useCartSelectionStorage";
import { useAuthentication } from "./useAuthentication";
import useLocation from "./useLocation";

interface Step {
    index: number;
    isCurrent: boolean;
    isPast: boolean;
    label: string;
    path: string;
    percent: number;
}

const isPage =
    (page: string) =>
    ({ pathname }: Location) =>
        page == pathname;

const getPageName = ({ pathname }: Location) => {
    const pageKey = Object.keys(Pages).find((key) => Pages[key as keyof typeof Pages] === pathname);

    return pageKey ? capitalCase(pageKey) : undefined;
};

/**
 * @todo Make all functions use pathname directly instead of Location.
 */
export const usePageResolver = () => {
    const { getPropertySelection } = usePropertySelectionStorage();
    const { selection: installationSelection } = useInstallationSelectionStorage();
    const { getCurrentCustomer } = useCustomerStorage();
    const { getVariantId } = useCartSelectionStorage();
    const { isMultiFactorChallenged } = useAuthentication();
    const propertySelection = getPropertySelection();
    const queryParams = new URLSearchParams(useLocation().search);

    /**
     * The order of the pages of type "flow" here is important. It will define
     * the order a customer would go through the pages within the ordering journey.
     */
    const pages: PageDefinition[] = [
        {
            path: Pages.HOME_PAGE,
            type: PageTypes.FLOW,
            condition: () => true,
        },
        {
            path: Pages.CHARGER_SELECTION,
            type: PageTypes.FLOW,
            condition: () => true,
        },
        {
            path: Pages.ACCESSORIES_SELECTION,
            type: PageTypes.FLOW,
            condition: () => !!getVariantId(),
        },
        {
            path: Pages.PROPERTY_TYPE_SELECTION,
            type: PageTypes.FLOW,
            condition: () => !!getVariantId(),
        },
        {
            path: Pages.CUSTOMER_DETAILS,
            type: PageTypes.FLOW,
            condition: () => !!getVariantId() && !!propertySelection?.type && !!propertySelection?.status,
        },
        {
            path: Pages.INSTALLATION_QUESTIONS,
            type: PageTypes.FLOW,
            condition: () =>
                !!getVariantId() &&
                !!propertySelection?.type &&
                !!propertySelection?.status &&
                !!getCurrentCustomer()?.id,
        },
        {
            path: Pages.ADDITIONAL_SERVICES,
            type: PageTypes.FLOW,
            condition: () =>
                !!getVariantId() &&
                !!propertySelection?.type &&
                !!propertySelection?.status &&
                !!getCurrentCustomer()?.id &&
                !!getCurrentCustomer()?.address_count &&
                !!installationSelection?.digRequired &&
                !!installationSelection.cableLength &&
                !!installationSelection.truckingLength,
        },
        {
            path: Pages.CART_REVIEW,
            type: PageTypes.FLOW,
            condition: () =>
                !!getVariantId() &&
                !!propertySelection?.type &&
                !!propertySelection?.status &&
                !!getCurrentCustomer()?.id &&
                !!getCurrentCustomer()?.address_count &&
                !!installationSelection?.digRequired &&
                !!installationSelection.cableLength &&
                !!installationSelection.truckingLength &&
                getAdditionalServiceOptions().length !== 0,
        },
        {
            path: Pages.ORDER_CONFIRMATION,
            type: PageTypes.FLOW,
            condition: () =>
                !!getVariantId() &&
                !!propertySelection?.type &&
                !!propertySelection?.status &&
                !!getCurrentCustomer()?.id &&
                !!getCurrentCustomer()?.address_count &&
                !!installationSelection?.digRequired &&
                !!installationSelection.cableLength &&
                !!installationSelection.truckingLength &&
                getAdditionalServiceOptions().length !== 0 &&
                queryParams.has("id"),
        },
        {
            path: Pages.LOGIN,
            type: PageTypes.AUTH,
            condition: () => !getCurrentCustomer()?.id,
        },
        {
            path: Pages.RESET_PASSWORD,
            type: PageTypes.AUTH,
            condition: () => !getCurrentCustomer()?.id,
        },
        {
            path: Pages.MULTI_FACTOR,
            type: PageTypes.AUTH,
            condition: () => !getCurrentCustomer()?.id && isMultiFactorChallenged,
        },
        {
            path: Pages.MAGIC_LINK_LOGIN,
            type: PageTypes.AUTH,
            condition: () => true,
        },
        {
            path: Pages.TROUBLESHOOTING,
            type: PageTypes.MISC,
            condition: () => true,
        },
        {
            path: Pages.PDP_CART_PROCESSOR,
            type: PageTypes.MISC,
            condition: () => true,
        },
    ];

    const isPageType = (pathname: string, type: PageTypes) =>
        pages
            .filter((page) => page.type === type)
            .map((page) => page.path)
            .includes(pathname);

    const isHomePage = isPage(Pages.HOME_PAGE);
    const isAuthPage = ({ pathname }: Location) => isPageType(pathname, PageTypes.AUTH);
    const isFlowPage = ({ pathname }: Location) => isPageType(pathname, PageTypes.FLOW);
    const isMiscPage = ({ pathname }: Location) => isPageType(pathname, PageTypes.MISC);

    const isPageConditionMet = ({ pathname }: Location) =>
        !!pages.filter((page) => page.path === pathname && page.condition()).pop();

    const pageExists = ({ pathname }: Location) => !!pages.find((page) => page.path === pathname);

    const pagesInFlow = pages.filter((page) => page.type === PageTypes.FLOW);

    const nextPageFor = (pathname: string): Pages => {
        const currentPageIndex = pagesInFlow.findIndex((page) => page.path === pathname);
        const page = pagesInFlow[currentPageIndex + 1]?.path as Pages;

        if (!page) {
            throw new Error(`Couldn't resolve the next page for "${pathname}".`);
        }

        return page;
    };

    const previousPageFor = (pathname: string): Pages => {
        const currentPageIndex = pagesInFlow.findIndex((page) => page.path === pathname);
        const page = pagesInFlow[currentPageIndex - 1]?.path as Pages;

        if (!page) {
            throw new Error(`Couldn't resolve the previous page for "${pathname}".`);
        }

        return page;
    };

    const latestPossiblePageInFlow =
        pagesInFlow
            .filter((page) => page.path !== Pages.HOME_PAGE)
            .filter((page) => page.condition())
            .pop()?.path ?? nextPageFor(Pages.HOME_PAGE);

    const getProgressInFlow = (location: Location, labels?: { label: string; page: string }[]) => {
        if (!isFlowPage(location)) {
            throw new Error(`Invalid path: unsupported 'flow' page ${location.pathname}`);
        }

        const currentIndex = pagesInFlow.findIndex((page) => page.path === location.pathname);
        const current = currentIndex + 1;
        const total = pagesInFlow.length;

        if (!labels) {
            // If no steps are given, let's calculate progress proportionally
            return { current, total, percent: currentIndex * (100 / (total - 1)), steps: [] };
        }

        const steps: Step[] = pagesInFlow
            .map((page, index) => ({
                index,
                isCurrent: index === currentIndex,
                isPast: index < currentIndex,
                label: labels.find((label) => label.page === page.path)?.label ?? "",
                path: page.path,
            }))
            // Filter out pages without an associated label defined
            .filter((step) => !!step.label)
            // Calculate target percentages for each step
            .map((step, index, steps) => ({
                ...step,
                percent: (index / (steps.length - 1)) * 100,
            }));

        // Check if the current page is a step
        const currentStep = steps.find((step) => step.path === location.pathname);
        if (currentStep) {
            return { current, total, percent: currentStep.percent, steps };
        }

        // If not, let's find the surrounding steps
        let prevStep, nextStep;
        for (let i = 0; i < steps.length - 1; i++) {
            const prev = pagesInFlow.findIndex((page) => page.path === steps[i].path);
            const next = pagesInFlow.findIndex((page) => page.path === steps[i + 1].path);

            if (currentIndex > prev && currentIndex < next) {
                prevStep = steps[i];
                nextStep = steps[i + 1];
                break;
            }
        }

        if (!prevStep && currentIndex === 0) {
            return { current, total, percent: 0, steps };
        }

        if (!nextStep && currentIndex === total - 1) {
            return { current, total, percent: 100, steps };
        }

        if (!prevStep || !nextStep) {
            throw new Error(`Invalid progress: could not find surrounding steps for "${location.pathname}"`);
        }

        // Calculate the percentage increment within this segment
        const increment = (nextStep.percent - prevStep.percent) / (nextStep.index - prevStep.index);

        // Calculate the percentage for a page in between steps
        const percent = prevStep.percent + (currentIndex - prevStep.index) * increment;

        return { current, total, percent, steps };
    };

    return {
        getPageName,
        getProgressInFlow,
        isAuthPage,
        isFlowPage,
        isHomePage,
        isMiscPage,
        isPageConditionMet,
        isPageType,
        nextPageFor,
        pageExists,
        previousPageFor,
        latestPossiblePageInFlow,
        pagesInFlow,
    };
};
