import { PropertySelection, PropertyStorage } from "../types/property";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const usePropertySelectionStorage = () => {
    const [getPropertySelection, storePropertySelection] = useTransactionalLocalStorage<PropertySelection>(
        PropertyStorage.SELECTION
    );

    return {
        getPropertySelection,
        storePropertySelection,
    };
};
