import { useFetchCart } from "../useFetchCart";
import { useUpdateChargerSelection } from "../charger/useUpdateChargerSelection";
import { useUpdateAccessorySelections } from "./useUpdateAccessorySelections";

export const useUpdateProductSelections = () => {
    const { data: cartData } = useFetchCart();
    const { mutateAsync: mutateUpdateChargerSelection, ...updateChargerSelection } = useUpdateChargerSelection();
    const { mutateAsync: mutateUpdateAccessorySelection, ...updateAccessorySelection } = useUpdateAccessorySelections();

    const updateProductSelections = async () => {
        if (!cartData || cartData?.line_items?.physical_items?.length < 1) {
            return;
        }
        await mutateUpdateChargerSelection();
        await mutateUpdateAccessorySelection();
    };

    return {
        updateProductSelections,
        updateChargerSelection,
        updateAccessorySelection,
    };
};
