import { CustomerAttributeName, MappedCustomerAttributeValue } from "../../types/customerAttributes";
import { VehicleSelection } from "../../types/vehicle";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";

export const getVehicleCustomerAttributes = (selection: VehicleSelection): MappedCustomerAttributeValue[] => {
    const { uid, model, brand, condition, connector, type } = selection;

    const uidAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_UID);
    const brandAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_MAKE);
    const modelAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_MODEL);
    const conditionAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_CONDITION);
    const connectorAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_CONNECTOR);
    const typeAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_TYPE);

    return [
        {
            attribute_id: uidAttributeId,
            value: uid ?? "",
        },
        {
            attribute_id: brandAttributeId,
            value: brand ?? "",
        },
        {
            attribute_id: modelAttributeId,
            value: model ?? "",
        },
        {
            attribute_id: conditionAttributeId,
            value: condition ?? "",
        },
        {
            attribute_id: connectorAttributeId,
            value: connector ?? "",
        },
        {
            attribute_id: typeAttributeId,
            value: type ?? "",
        },
    ];
};
