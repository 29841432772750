export enum ContentType {
    TEXT_WITH_CTA = "PrismicPageDataBodyTextWithCta",
    SIMPLE_IMAGE = "PrismicPageDataBodySimpleImage",
    ICON_LIST = "PrismicPageDataBodyIconList",
    FOOTNOTE = "PrismicPageDataBodyFootnote",
    FEATURE_CARD = "PrismicPageDataBodyFeatureCard",
    SIMPLE_CARD = "PrismicPageDataBodySimpleCard",
    ALERT_BANNER = "PrismicPageDataBodyAlertBanner",
    VEHICLE_SELECTION = "PrismicPageDataBodyVehicle",
    VEHICLE_WARNING = "PrismicPageDataBodyVehicleWarning",
    CHARGER_OPTIONS = "PrismicPageDataBodyChargerOptions",
    CHARGER_CATEGORY_INFO = "PrismicPageDataBodyChargerCategoryInfo",
    ACCESSORY = "PrismicPageDataBodyAccessory",
    STATIC_RECOMMENDATION = "PrismicPageDataBodyStaticRecommendation",
    POP_UP = "PrismicPageDataBodyPopUp",
    CUSTOMER_DETAILS = "PrismicPageDataBodyEmailCapture",
    MAGIC_LINK_VERIFICATION = "PrismicPageDataBodyMagicLinkVerification",
    CUSTOMER_ADDRESS = "PrismicPageDataBodyInstallationAddressCapture",
    INSTALLATION_PAGE = "PrismicPageDataBodyInstallationPage",
    INSTALLATION_QUESTIONS = "PrismicPageDataBodyQuestionsInstallation",
    INSTALLATION_RESULT = "PrismicPageDataBodyTheInstallationDetail",
    INSTALLATION_RESULT_INFO = "PrismicPageDataBodyNonStandardAndStandardUrl",
    CART_REVIEW_PAGE = "PrismicPageDataBodyCartPage",
    CONFIRMATION_PAGE = "PrismicPageDataBodySuccessfulOrder",
    MOBILE_APP = "PrismicPageDataBodyTheMobileApp",
    EDF_TARIFF = "PrismicPageDataBodyEdfTariff",
    NOT_FOUND = "PrismicPageDataBody404TextBlock",
    NOT_FOUND_OPTION = "PrismicPageDataBody404Option",
    MUTED_VEHICLE_PARAGRAPH = "PrismicPageDataBodyMutedVehicleParagraph",
    MUTED_UNKNOWN_PARAGRAPH = "PrismicPageDataBodyMutedUnknownParagraph",
    SIGN_IN = "PrismicPageDataBodySignIn",
    KLARNA_FOOTER = "PrismicPageDataBodyKlarnaFooter",
    LOGIN_FORM = "PrismicPageDataBodyLoginForm",
    RESET_PASSWORD_FORM = "PrismicPageDataBodyResetPasswordForm",
    MFA_FORM = "PrismicPageDataBodyMultiFactorForm",
    POWER_RATING = "PrismicPageDataBodyPowerRatingModal",
    TETHERED_VS_UNIVERSAL = "PrismicPageDataBodyTetheredVsUniversal",
    TROUBLESHOOT = "PrismicPageDataBodyTroubleshoot",
}

export interface Slice<T = object, I = readonly object[]> {
    __typename: string;
    primary?: T;
    items?: I;
}

/**
 * Technically the richText field in the autogenerated types is frequently `any`, but we know that there
 * are types which are returned in each richText field, so this custom type adds some missing context.
 *
 * It is VERY important to use `spans` and not `span` - you will get a lot of confusing error messages from
 * the prismic library if you don't.
 *
 * @see https://prismic.io/docs/rich-text
 */

export interface PrismicRichTextType {
    type: string;
    text: string;
    spans: any[];
    direction?: string;
}
