import React from "react";
import classNames from "classnames";

type Props = React.HTMLAttributes<HTMLHeadingElement>;

function ModalHeader({ className, ...rest }: Props) {
    return <h3 className={classNames("text-xl font-bold w-[calc(100%-2rem)] mb-6", className)} {...rest} />;
}

export default ModalHeader;
