import { useEffect } from "react";
import { trackPage } from "../services/tracking";
import { generateCustomerHash } from "../utils/customer/generateCustomerHash";
import { useCustomerStorage } from "./useCustomerStorage";
import { useLastPageVisited } from "./useLastPageVisited";
import { usePageResolver } from "./usePageResolver";

export const useLocationTracking = (location: Location) => {
    const { getCurrentCustomer } = useCustomerStorage();
    const { getPageName, isFlowPage } = usePageResolver();
    const { getLastPageVisited, storeLastPageVisited, saveLastPageVisited } = useLastPageVisited();
    const isOrdering = isFlowPage(location);
    const pageName = getPageName(location);

    useEffect(() => {
        if (location.href) {
            const userEmail = getCurrentCustomer()?.email;
            trackPage(location.href, userEmail ? { user_id: generateCustomerHash(userEmail) } : {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.href, getCurrentCustomer()?.email]);

    useEffect(() => {
        if (location.pathname && isOrdering && getLastPageVisited() !== location.pathname) {
            if (pageName) {
                saveLastPageVisited(pageName);
            }

            storeLastPageVisited(location.pathname);
        }
    }, [isOrdering, getLastPageVisited, location.pathname, pageName, saveLastPageVisited, storeLastPageVisited]);
};
