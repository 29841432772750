import { nanoid } from "nanoid";
import * as Sentry from "@sentry/gatsby";
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { StatusCodes } from "http-status-codes";
import { SentryStorage } from "../types/sentry";
import { FirebaseAuthErrorCodes } from "../types/firebase";
import { CurrentCustomer } from "../types/customer";
import { getClarityId } from "./clarity";
import { generateCustomerHash } from "./customer/generateCustomerHash";

export const SENTRY_SESSION_ID_HEADER = "X-Sentry-Session-ID";

const dontReport: string[] = [
    FirebaseAuthErrorCodes.INTERNAL_ERROR,
    FirebaseAuthErrorCodes.INVALID_CREDENTIAL,
    FirebaseAuthErrorCodes.INVALID_PASSWORD,
    FirebaseAuthErrorCodes.USER_DELETED,
    `${StatusCodes.CONFLICT}`,
    `${StatusCodes.UNPROCESSABLE_ENTITY}`,
];

const generateSessionId = () => saveSessionId(nanoid());

const saveSessionId = (sessionId: string) => {
    if (typeof window !== "undefined") {
        localStorage.setItem(SentryStorage.SESSION_ID, sessionId);
    }

    return sessionId;
};

export const getSessionId = () => typeof window !== "undefined" && localStorage.getItem(SentryStorage.SESSION_ID);

export const startSession = () => {
    const sessionId = getSessionId() || generateSessionId();

    Sentry.getCurrentScope().setTags({
        clarity_id: getClarityId(),
        sentry_session_id: sessionId,
    });

    return sessionId;
};

export const captureException = (error: any, message?: string, data?: any) => {
    if (error?.code && dontReport.includes(error.code.toString())) {
        return;
    }

    if (message || data) {
        Sentry.addBreadcrumb({
            level: "error",
            data: { message, data },
        });
    }

    Sentry.captureException(error);
};

export const setUser = (customer: CurrentCustomer) => {
    if (customer.email) {
        Sentry.getCurrentScope().setTag("customer_hash", generateCustomerHash(customer.email));
    }

    if (customer.customer_group_id) {
        Sentry.getCurrentScope().setTag("customer_group_id", customer.customer_group_id);
    }

    return Sentry.setUser({
        id: `${customer.id}`,
        email: customer.email,
    });
};

export const breadcrumbForAxiosRequest = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    Sentry.addBreadcrumb({
        level: "debug",
        category: "xhr",
        type: "http",
        data: {
            type: "request",
            method: request.method,
            url: `${request.baseURL}${request.url}`,
            payload: request?.data ? JSON.stringify(request.data) : "{}",
        },
    });

    return request;
};

export const breadcrumbForAxiosResponse = (response: AxiosResponse): AxiosResponse => {
    let payload;
    try {
        payload = JSON.parse(response?.data as string);
    } catch (e) {
        payload = response?.data;
    }

    Sentry.addBreadcrumb({
        level: "debug",
        category: "xhr",
        type: "http",
        data: {
            type: "response",
            method: response.config.method?.toUpperCase(),
            url: `${response.config.baseURL}${response.config.url}`,
            status: response.status,
            payload: payload ? JSON.stringify(payload) : "{}",
            apigw_requestid: response.headers["apigw-requestid"],
        },
    });

    return response;
};

export const breadcrumbForAxiosError = (error: AxiosError): AxiosError => {
    let payload;
    try {
        payload = JSON.parse(error.response?.data as string);
    } catch (e) {
        payload = error.response?.data;
    }

    Sentry.addBreadcrumb({
        level: "error",
        category: "xhr",
        type: "http",
        data: {
            type: "error",
            method: error.config?.method?.toUpperCase(),
            url: error.config?.url,
            status: error.response?.status,
            payload: payload ? JSON.stringify(payload) : "{}",
            apigw_requestid: error.response?.headers["apigw-requestid"],
        },
    });

    return error;
};
