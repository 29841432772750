export enum VehicleStorage {
    SELECTION = "vehicle.selection",
}

export interface VehicleSelection {
    uid: "" | string;
    brand: "" | string;
    model: "" | string;
    condition: "" | string;
    connector: "" | string;
    type: "" | string;
}

type Vehicle = Queries.VehicleFragment;
export default Vehicle;

export type VehicleBrand = Queries.VehicleBrand;
export type VehicleConnector = Queries.VehicleConnector;

export enum VehicleType {
    PHEV = "PHEV",
    BEV = "BEV",
}

export enum VehicleConnectors {
    TYPE_1 = "Type 1 socket",
    TYPE_2 = "Type 2 socket",
    DEFAULT = TYPE_2,
}
