import { Optional, Maybe } from "../../types/generics";
import { Cart } from "../../types/cart";
import { Product } from "../../types/products";
import { getChargerLineItem } from "../cart/cart";

/** @todo this should be renamed into getChargerFromCart() and moved into /src/utils/cart/ */
export const getCharger = (cartData: Optional<Maybe<Cart>>, chargers: Product[]) => {
    const chargerLineItem = getChargerLineItem(cartData, chargers);

    return chargers?.find((charger) => charger.id === chargerLineItem?.product_id);
};
