import { Product, Variant } from "../../types/products";
import { getProducts } from "../../store/ephemeral/products";
import { getPriceListRecord } from "../price/getPriceListRecord";

/**
 * For a given Customer Group, we will filter out any Product or Variant which may have its
 * price set to an "empty" value on the Price List associated to the Customer Group, so
 * only the Products and Variants we are happy to sell (available) for a Customer
 * Group are returned.
 */
export const getAvailableProducts = (customerGroupId: number): Product[] => {
    return getProducts()
        .filter((product: Product) => {
            return !!getPriceListRecord(product, undefined, customerGroupId)();
        })
        .map((product: Product) => ({
            ...product,
            variants: product.variants.filter((variant: Variant) => {
                return !!getPriceListRecord(product, variant, customerGroupId)();
            }),
        }));
};
