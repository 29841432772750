import { useEffect, useState } from "react";
import { CountryCode, getLocale, getLocaleForCountry, WebsiteLocale } from "../utils/locale";
import { Maybe } from "../types/generics";
import useLocation from "./useLocation";

const useUserLocale = () => {
    const location = useLocation();
    const [current, setCurrent] = useState<Maybe<WebsiteLocale>>(null);
    const [suggested, setSuggested] = useState<Maybe<WebsiteLocale>>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown>(null);

    const getUserLocale = async () => {
        setIsLoading(true);

        try {
            // the domain that the user is accessing
            const appLocale = getLocale();

            // the country the user resides in (based on IP)
            const countryCode = (await fetch(location.origin)).headers.get("x-country");
            const geoLocale = getLocaleForCountry(countryCode as Maybe<CountryCode>);

            setCurrent(appLocale);

            // the locale we support that we want to suggest the user to use
            setSuggested(geoLocale !== appLocale ? geoLocale : null);
        } catch (err) {
            setError(err);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getUserLocale();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { current, suggested, isLoading, error };
};

export default useUserLocale;
