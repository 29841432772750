import React from "react";
import classNames from "classnames";

interface Props {
    className?: string;
}

function Divider({ className }: Props) {
    return <hr className={classNames("border-gray-100 border border-y my-4", className)} />;
}

export default Divider;
