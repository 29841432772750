import { CurrencyCode } from "../../types/currency";
import { CustomerGroup } from "../../types/customerGroups";
import { getItem, storeItem } from "./index";

const CHANNEL_CURRENCY = "channel.currency";
const CHANNEL_CUSTOMER_GROUPS_DEFAULT_ID = "channel.customer_groups.default";
const CHANNEL_CUSTOMER_GROUPS_GUEST_ID = "channel.customer_groups.guest";

/**
 * Channel currency
 */
export const getChannelCurrency = () => getItem(CHANNEL_CURRENCY);
export const setChannelCurrency = (currency: CurrencyCode) => storeItem(CHANNEL_CURRENCY, currency);
/**
 * Channel default Customer Group ID
 */
export const getDefaultCustomerGroupId = () => getItem(CHANNEL_CUSTOMER_GROUPS_DEFAULT_ID);
export const setDefaultCustomerGroupId = (customerGroups: CustomerGroup[]) => {
    const customerGroup = customerGroups.filter((customerGroup) => customerGroup.is_default).pop();

    if (!customerGroup) {
        throw new Error("Unable to store CHANNEL_CUSTOMER_GROUPS_DEFAULT_ID");
    }

    storeItem(CHANNEL_CUSTOMER_GROUPS_DEFAULT_ID, customerGroup.id);
};
/**
 * Channel guest Customer Group ID
 */
export const getGuestCustomerGroupId = () => getItem(CHANNEL_CUSTOMER_GROUPS_GUEST_ID);
export const setGuestCustomerGroupId = (customerGroups: CustomerGroup[]) => {
    const customerGroup = customerGroups.filter((customerGroup) => customerGroup.is_group_for_guests).pop();

    if (!customerGroup) {
        throw new Error("Unable to store CHANNEL_CUSTOMER_GROUPS_GUEST_ID");
    }

    storeItem(CHANNEL_CUSTOMER_GROUPS_GUEST_ID, customerGroup.id);
};
