import { useCallback } from "react";
import { Origin, OriginChannelCategory, OriginSourceEnvironment, OriginType } from "../types/origin";
import { useFetchCustomer } from "../queries/useFetchCustomer";
import { useUpdateCustomer } from "../queries/useUpdateCustomer";
import { getCustomerGroupId } from "../utils/customer/customerGroups";
import { Customer } from "../types/customer";
import { Maybe } from "../types/generics";
import { defaultOrigin, useOriginStorage } from "./useOriginStorage";
import { useAffiliateTrackingStorage } from "./useAffiliateTrackingStorage";

const shouldUpdateOrigin = (previous: Origin, next: Origin): boolean => {
    return (Object.keys(previous) as Array<keyof Origin>).reduce((prev, key) => {
        if (["sourceChannelCategory", "sourceEnvironment"].includes(key)) {
            return prev;
        }

        return prev && previous[key] !== next[key];
    }, true);
};

const isValidData = (
    id: string,
    partner?: string,
    publisher?: string,
    sourceEnvironment?: string,
    customer?: Maybe<Customer>,
    customerGroupId?: number
): boolean =>
    !!id &&
    !!partner &&
    !!publisher &&
    !!sourceEnvironment &&
    (!customer || customer.customer_group_id === customerGroupId);

export const useAffiliateTracking = () => {
    const { data: customer } = useFetchCustomer();
    const { mutate: updateCustomer } = useUpdateCustomer();
    const { getOrigin, setOrigin } = useOriginStorage();

    const { setClickId, setPartner, setPublisher, setOriginSourceEnvironment } = useAffiliateTrackingStorage();

    const store = useCallback(
        (id: string, partner: string, publisher: string, sourceEnvironment: OriginSourceEnvironment) => {
            setClickId(id);
            setPartner(partner);
            setPublisher(publisher);
            setOriginSourceEnvironment(sourceEnvironment);

            const customerGroupId = getCustomerGroupId(customer, partner, publisher);
            const newOrigin: Origin = {
                type: OriginType.AFFILIATE,
                sourceChannelCategory: OriginChannelCategory.WEB,
                sourceEnvironment: sourceEnvironment,
                partner: `${partner}#${publisher}`,
                systemId: `${partner}#${id}`,
            };

            if (customer && customer.customer_group_id !== customerGroupId) {
                updateCustomer({
                    id: customer.id,
                    customer_group_id: customerGroupId,
                });
            }

            if (
                isValidData(id, partner, publisher, sourceEnvironment, customer, customerGroupId) &&
                shouldUpdateOrigin(getOrigin(), newOrigin)
            ) {
                setOrigin(newOrigin);
            }
        },
        [
            customer,
            setClickId,
            setOriginSourceEnvironment,
            setPartner,
            setPublisher,
            getOrigin,
            setOrigin,
            updateCustomer,
        ]
    );

    const clear = useCallback(() => {
        setClickId(null);
        setPartner(null);
        setPublisher(null);
        setOriginSourceEnvironment(null);
        if (shouldUpdateOrigin(getOrigin(), defaultOrigin)) {
            setOrigin(defaultOrigin);
        }
    }, [setClickId, setOriginSourceEnvironment, setPartner, setPublisher, getOrigin, setOrigin]);

    return {
        store,
        clear,
    };
};
