import { AdditionalServicesStorage } from "../types/additionalServices";
import { useAdditionalServicesSelectionStorage } from "./useAdditionalServicesSelectionStorage";

export const getAdditionalServiceOptions = () => {
    const additionalServiceIDs: number[] = JSON.parse(
        localStorage.getItem(AdditionalServicesStorage.SELECTED_SERVICES) || "[]"
    );

    return additionalServiceIDs;
};

export const useAdditionalServicesSelection = () => {
    const { getAdditionalServicesSelection, setAdditionalServicesSelection } = useAdditionalServicesSelectionStorage();

    const addServiceProductIdIfNotExisting = (additionalServiceProductId: number) => {
        const newAdditionalServicesSelection = [...getAdditionalServicesSelection()];
        if (!newAdditionalServicesSelection.includes(additionalServiceProductId)) {
            newAdditionalServicesSelection.push(additionalServiceProductId);
        }

        return newAdditionalServicesSelection;
    };

    const addAdditionalServiceOption = (additionalServiceProductId: number) => {
        const newAdditionalServicesSelection = addServiceProductIdIfNotExisting(additionalServiceProductId);

        setAdditionalServicesSelection(newAdditionalServicesSelection);
    };

    return {
        getAdditionalServicesSelection,
        setAdditionalServicesSelection,
        addAdditionalServiceOption,
        addServiceProductIdIfNotExisting,
    };
};
