import { OrderedProductType } from "./orders";
import { CurrencyCode } from "./currency";
import { Maybe, Optional } from "./generics";

export interface TrackingPurchaseEventBody {
    transaction_id: number;
    currency: string;
    value: number;
    tax: number;
    shipping: number;
    coupon_discount: number;
    items: TrackingPurchaseEventBodyItem[];
}

export interface TrackingPurchaseEventBodyItem {
    item_id: string;
    item_name: string;
    price: number;
    quantity: number;
    affiliation: string;
    item_brand: string;
    property_type: string;
    vehicle_brand: Maybe<string>;
    vehicle_model: Maybe<string>;
    vehicle_condition: Maybe<string>;
    connection_type: Maybe<string>;
    power_rating: Optional<string>;
    item_type: Optional<string>;
    item_variant: "Physical" | "Digital";
}

export interface LineItemTrackingEventBodyItem {
    item_id: Optional<Maybe<string>>;
    item_name: Optional<string>;
    price: Optional<number>;
    quantity: number;
    affiliation: string;
    item_brand: string;
    property_type: string;
    vehicle_brand: Maybe<string>;
    vehicle_model: Maybe<string>;
    vehicle_condition: Maybe<string>;
    connection_type: Maybe<string>;
    power_rating: Optional<string>;
    item_type: Optional<string>;
    item_variant: Optional<string>;
}

export interface AwinTrackingCookie {
    awp: Optional<Maybe<string>>;
    awc: Optional<Maybe<string>>;
    type: Optional<Maybe<string>>;
}

export interface LineItemTrackingEventBody {
    currency: Optional<CurrencyCode>;
    value: Optional<number>;
    items: LineItemTrackingEventBodyItem[];
}

type OrderToTracking = Record<OrderedProductType, TrackingPurchaseEventBodyItem["item_variant"]>;

export const ORDER_TO_TRACKING_TYPE: OrderToTracking = {
    [OrderedProductType.PHYSICAL]: "Physical",
    [OrderedProductType.DIGITAL]: "Digital",
};

export enum AffiliateDefaults {
    AWIN_PARTNER = "awin",
}

export enum AffiliateTrackingStorage {
    CLICK_ID = "affiliate.click",
    PUBLISHER = "affiliate.publisher",
    PARTNER = "affiliate.partner",
    ORIGIN_SOURCE_ENVIRONMENT = "affiliate.origin_source_environment",
}

export enum AffiliateUtmParams {
    AWIN_CLICK = "awc",
    AWIN_PUBLISHER = "awp",
    AWIN_TYPE = "utm_source",
}

export enum Affiliates {
    AWIN = "awin",
}
