import React, { FC, HTMLAttributes } from "react";
import AccessoryCard from "../Accessories/AccessoryCard";
import { Product } from "../../types/products";
import { getAccessoryLineItems, getAdditionalServiceLineItems, getCartLineItems } from "../../utils/cart/cart";
import { Cart, LineItem } from "../../types/cart";
import { getChargerAccessories } from "../../utils/products/getChargerAccessories";
import useAccessorySelectionPageContent from "../../hooks/Prismic/useAccessorySelectionPageContent";
import useChargers from "../../hooks/BigCommerce/useChargers";
import { useAddAccessory } from "../../queries/cart/lineItem/useAddAccessory";
import { getCustomerGroupId } from "../../utils/customer/customerGroups";
import { useFetchCustomer } from "../../queries/useFetchCustomer";
import { Maybe, Optional } from "../../types/generics";
import { useVehicleSelectionStorage } from "../../hooks/useVehicleSelectionStorage";
import { useAffiliateTrackingStorage } from "../../hooks/useAffiliateTrackingStorage";
import { VehicleConnectors } from "../../types/vehicle";

interface SuggestedAccessoriesProps extends HTMLAttributes<HTMLDivElement> {
    maxItems?: number;
    gridStyles?: string;
    cartData: Optional<Maybe<Cart>>;
}

export const SuggestedAccessories: FC<SuggestedAccessoriesProps> = ({ maxItems = 100, gridStyles, cartData }) => {
    const { data: customer } = useFetchCustomer();
    const { mutate: addAccessory } = useAddAccessory();
    const { getVehicleSelection } = useVehicleSelectionStorage();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();
    const chargers = useChargers();
    const cartLineItems = getCartLineItems(cartData);

    const accessoryLineItems = [
        ...getAccessoryLineItems(cartLineItems),
        ...getAdditionalServiceLineItems(cartLineItems),
    ] as LineItem[];
    const accessories = getChargerAccessories(
        getCustomerGroupId(customer, getPartner(), getPublisher()),
        cartData,
        chargers,
        getVehicleSelection()?.connector || VehicleConnectors.DEFAULT
    );
    const unselectedAccessories = accessories
        .filter((product: Product) => !accessoryLineItems.find((lineItem) => lineItem.product_id === product.id))
        .slice(0, maxItems);

    const { accessoryContent } = useAccessorySelectionPageContent();

    return (
        <>
            {unselectedAccessories.length > 0 && (
                <>
                    <h2 className="text-xl font-bold">Recommended accessories for you</h2>

                    <div
                        className={gridStyles ? gridStyles : "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 grid gap-4"}
                        data-testid="accessory-card-parent"
                    >
                        {unselectedAccessories.map((accessory) => (
                            <AccessoryCard
                                key={accessory.id}
                                product={accessory}
                                content={accessoryContent}
                                cartData={cartData}
                                onSelect={addAccessory}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};
