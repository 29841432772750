import { useMutation } from "@tanstack/react-query";
import { CustomerAttributeName } from "../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../store/ephemeral/customerAttributes";
import { VehicleSelection } from "../types/vehicle";
import { Maybe } from "../types/generics";
import { useSaveCustomerAttributes } from "./useSaveCustomerAttributes";

export interface SaveCustomerVehicleSelection {
    selection: VehicleSelection;
    customerId?: Maybe<number>;
}

export const useSaveVehicleSelection = () => {
    const { mutateAsync } = useSaveCustomerAttributes();

    return useMutation({
        mutationFn: async ({ selection, customerId }: SaveCustomerVehicleSelection) => {
            if (!customerId) {
                return null;
            }

            return await mutateAsync([
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_UID) as number,
                    customer_id: customerId,
                    value: selection.uid,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_MAKE) as number,
                    customer_id: customerId,
                    value: selection.brand,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_MODEL) as number,
                    customer_id: customerId,
                    value: selection.model,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_CONDITION) as number,
                    customer_id: customerId,
                    value: selection.condition,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_CONNECTOR) as number,
                    customer_id: customerId,
                    value: selection.connector,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.VEHICLE_TYPE) as number,
                    customer_id: customerId,
                    value: selection.type,
                },
            ]);
        },
    });
};
