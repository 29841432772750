import { parseISO } from "date-fns";
import { CustomerAttributeName, CustomerAttributeValue } from "../../types/customerAttributes";
import { getCustomerAttributeValueByName } from "../customer/getCustomerAttributeValueByName";
import { MarketingConsent, MarketingConsentGiven, MarketingConsentType } from "../../types/consent";
import { Maybe, Optional } from "../../types/generics";

export const getMarketingConsentFromCustomerAttributes = (
    attributes?: CustomerAttributeValue[],
    consent?: Maybe<MarketingConsent>
): Optional<Maybe<MarketingConsent>> => {
    if (!attributes) {
        return consent;
    }

    const isConsentGiven = getCustomerAttributeValueByName(
        CustomerAttributeName.CONSENT_MARKETING_IS_CONSENT_GIVEN,
        attributes
    );
    const copy = getCustomerAttributeValueByName(CustomerAttributeName.CONSENT_MARKETING_COPY, attributes);
    const date = getCustomerAttributeValueByName(CustomerAttributeName.CONSENT_MARKETING_DATE, attributes);
    const type = getCustomerAttributeValueByName(CustomerAttributeName.CONSENT_MARKETING_TYPE, attributes);

    return {
        isConsentGiven: (isConsentGiven?.attribute_value as Optional<Maybe<MarketingConsentGiven>>) ?? null,
        date: date && date.attribute_value.length > 0 ? parseISO(date.attribute_value) : null,
        type: (type?.attribute_value as Optional<Maybe<MarketingConsentType>>) ?? null,
        copy: copy?.attribute_value ?? "",
    };
};
