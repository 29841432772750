import { useStaticQuery, graphql } from "gatsby";
import { PriceListRecord } from "../../types/price";

const usePriceListRecords = (): PriceListRecord[] => {
    const { priceListRecords } = useStaticQuery(
        graphql`
            query {
                priceListRecords {
                    data {
                        price_list_id
                        product_id
                        variant_id
                        price
                        currency
                    }
                }
            }
        `
    );

    return priceListRecords.data;
};

export default usePriceListRecords;
