import React, { PropsWithChildren } from "react";
import { componentResolverFromMap, PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { MotionConfig } from "framer-motion";
import { LocationProvider } from "@reach/router";
import { GlobalErrorBoundary } from "../ErrorBoundary/GlobalErrorBoundary";
import AuthProvider from "./AuthProvider";
import QueryProvider from "./QueryProvider";
import { StaticDataProvider } from "./StaticDataProvider";
import { TrackingProvider } from "./TrackingProvider";

interface Props {
    children: React.ReactNode;
}

const PreviewProvider = ({ children }: PropsWithChildren) => {
    return (
        <PrismicPreviewProvider
            repositoryConfigs={[
                {
                    repositoryName: "ecommerce-web-app",
                    componentResolver: componentResolverFromMap({}),
                },
            ]}
        >
            {children}
        </PrismicPreviewProvider>
    );
};

export const WrapProvider = ({ children }: Props) => {
    return (
        <>
            {process.env.GATSBY_GTM_ID ? (
                <noscript>
                    <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_ID}`}
                        height="0"
                        width="0"
                        style={{ display: "none", visibility: "hidden" }}
                    ></iframe>
                </noscript>
            ) : null}
            <PreviewProvider>
                <StaticDataProvider>
                    <QueryProvider>
                        <LocationProvider>
                            <TrackingProvider>
                                <AuthProvider>
                                    <MotionConfig reducedMotion="user">
                                        <GlobalErrorBoundary>{children}</GlobalErrorBoundary>
                                    </MotionConfig>
                                </AuthProvider>
                            </TrackingProvider>
                        </LocationProvider>
                    </QueryProvider>
                </StaticDataProvider>
            </PreviewProvider>
        </>
    );
};
