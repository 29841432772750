import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import CardHighlightedHeader from "./CardHighlightedHeader";

export interface Props extends HTMLAttributes<HTMLElement> {
    highlightedHeader?: string;
    isSelected?: boolean;
    isUnpadded?: boolean;
    onSelect?: () => void;
    disabled?: boolean;
}

export default function Card({
    children,
    className,
    highlightedHeader,
    isSelected,
    isUnpadded,
    onSelect,
    disabled,
    ...rest
}: Props) {
    const classes = classNames(
        "rounded-b-lg bg-white border-none",
        {
            "rounded-t-lg": !highlightedHeader || (!!highlightedHeader && !isSelected),
        },
        { "p-4": !isUnpadded },
        {
            "ring-green-500 ring-4": isSelected,
            "cursor-pointer text-center": !!onSelect,
            "hover:ring-green-200 hover:ring-4": !isSelected && !!onSelect,
        },
        className
    );

    if (onSelect !== undefined) {
        return (
            <div
                role="button"
                aria-disabled={disabled ? "true" : "false"}
                className={classes}
                onClick={disabled ? () => undefined : onSelect}
                {...rest}
            >
                {isSelected && !!highlightedHeader && <CardHighlightedHeader text={highlightedHeader} />}
                {children}
            </div>
        );
    }

    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
}
