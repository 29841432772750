import { useStaticQuery, graphql } from "gatsby";
import { PriceListAssignment } from "../../types/price";

const usePriceListAssignments = (): PriceListAssignment[] => {
    const { priceListAssignments } = useStaticQuery(
        graphql`
            query {
                priceListAssignments {
                    data {
                        id
                        price_list_id
                        customer_group_id
                    }
                }
            }
        `
    );

    return priceListAssignments.data;
};

export default usePriceListAssignments;
