import { useStaticQuery, graphql } from "gatsby";
import { getLocale } from "../utils/locale";
import { SiteConfigData, SiteMainData } from "../graphqlFragments/siteConfig";

const useSiteConfig = () => {
    const { languageCode } = getLocale();
    const data = useStaticQuery<SiteConfigData>(
        graphql`
            query {
                ...SiteConfigDataFragment
            }
        `
    );

    return data.siteMainConfig.nodes.find(({ lang }) => lang === languageCode)?.data as SiteMainData;
};

export default useSiteConfig;
