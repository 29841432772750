import { useMutation } from "@tanstack/react-query";
import { CustomerAttributeName, CustomerAttributeValue } from "../types/customerAttributes";
import { Maybe } from "../types/generics";
import { MarketingConsent } from "../types/consent";
import { getCustomerAttributeIdByName } from "../store/ephemeral/customerAttributes";
import { useMarketingConsentStorage } from "../hooks/useMarketingConsentStorage";
import { getMarketingConsentCustomerAttributes } from "../utils/consent/getMarketingConsentCustomerAttributes";
import { useSaveCustomerAttributes } from "./useSaveCustomerAttributes";

export interface SaveCustomerMarketingConsent {
    consent: MarketingConsent;
    customerId?: Maybe<number>;
}

export const useSaveMarketingConsent = () => {
    const { mutateAsync } = useSaveCustomerAttributes();
    const { getMarketingConsent } = useMarketingConsentStorage();
    const customerAttributes = getMarketingConsentCustomerAttributes(getMarketingConsent());

    return useMutation({
        mutationFn: async ({
            consent,
            customerId,
        }: SaveCustomerMarketingConsent): Promise<Maybe<CustomerAttributeValue[]>> => {
            if (!customerId) {
                return null;
            }

            const marketingConsentAttributeId = getCustomerAttributeIdByName(
                CustomerAttributeName.CONSENT_MARKETING_IS_CONSENT_GIVEN
            );

            return mutateAsync(
                customerAttributes.map((customerAttribute) => ({
                    attribute_id: customerAttribute.attribute_id as number,
                    customer_id: customerId as number,
                    value: (customerAttribute.attribute_id === marketingConsentAttributeId
                        ? consent.isConsentGiven
                        : customerAttribute.value) as string,
                }))
            );
        },
    });
};
