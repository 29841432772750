import { ConsentStorage, MarketingConsent } from "../types/consent";
import { Maybe, Optional } from "../types/generics";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

const serializer = (consent?: Maybe<MarketingConsent>) =>
    consent
        ? ({
              ...consent,
              date: consent.date ? consent.date.getTime() : null,
          } as Maybe<MarketingConsent>)
        : null;

const parser = (consent: Maybe<MarketingConsent>) => {
    return consent
        ? {
              ...consent,
              date: consent.date ? new Date(consent.date) : null,
          }
        : null;
};

export const useMarketingConsentStorage = (): {
    getMarketingConsent: () => Maybe<MarketingConsent>;
    storeMarketingConsent: (value: Optional<Maybe<MarketingConsent>>) => void;
} => {
    const [getMarketingConsent, storeMarketingConsent] = useTransactionalLocalStorage<Maybe<MarketingConsent>>(
        ConsentStorage.MARKETING_CONSENT_SELECTION
    );

    return {
        getMarketingConsent: () => parser(getMarketingConsent()),
        storeMarketingConsent: (value?: Maybe<MarketingConsent>) => storeMarketingConsent(serializer(value)),
    };
};
