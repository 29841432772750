import {
    CustomerAttributeName,
    CustomerAttributeValue,
    MappedCustomerAttributeValue,
} from "../../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";
import { getCustomerAttributeValueByName } from "../customer/getCustomerAttributeValueByName";
import { Origin, OriginChannelCategory, OriginSourceEnvironment } from "../../types/origin";

export const getOriginCustomerAttributes = (
    origin?: Origin,
    attributes?: CustomerAttributeValue[]
): MappedCustomerAttributeValue[] => {
    const sourcePartnerValue = getCustomerAttributeValueByName(CustomerAttributeName.PARTNER, attributes);

    return [
        {
            attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.SOURCE_ENVIRONMENT),
            value: origin?.sourceEnvironment ?? OriginSourceEnvironment.STOREFRONT,
        },
        {
            attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.SOURCE_CHANNEL_CATEGORY),
            value: origin?.sourceChannelCategory ?? OriginChannelCategory.WEB,
        },
        {
            attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.PARTNER),
            value: origin?.partner ?? sourcePartnerValue?.attribute_value ?? "",
        },
        {
            attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.ORIGIN_SYSTEM_ID),
            value: origin?.systemId ?? "",
        },
    ];
};
