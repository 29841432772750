import classNames from "classnames";
import React from "react";

export enum Icons {
    ABB = "abb",
    ARROW_HEAD_DOWN = "arrow-head-down",
    ARROW_HEAD_LEFT = "arrow-head-left",
    ARROW_HEAD_RIGHT = "arrow-head-right",
    ARROW_HEAD_UP = "arrow-head-up",
    ARROW_LEFT = "arrow-left",
    ARROW_UP_RIGHT = "arrow-up-right",
    BAN_LIGHT = "ban-light",
    BATTERY_LIGHT = "battery-light",
    BATTERY_SOLID = "battery-solid",
    BELL_LIGHT = "bell-light",
    BOLT_LIGHT = "bolt-light",
    BOLT_SOLID = "bolt-solid",
    CABLE_LIGHT = "cable-light",
    CALENDAR_LIGHT = "calendar-light",
    CAMERA_LIGHT = "camera-light",
    CARET_DOWN = "caret-down",
    CARET_LEFT = "caret-left",
    CARET_RIGHT = "caret-right",
    CARET_UP = "caret-up",
    CCS_LIGHT = "ccs-light",
    CCS_SOLID = "ccs-solid",
    CHADEMO_LIGHT = "chademo-light",
    CHADEMO_SOLID = "chademo-solid",
    CHART_DONUT = "chart-donut",
    CHART_PIE = "chart-pie",
    CHECKLIST = "checklist",
    CHECKLIST_ALT = "checklist-alt",
    CHECKMARK = "checkmark",
    CHECKMARK_CIRCLE_LIGHT = "checkmark-circle-light",
    CHIP_LIGHT = "chip-light",
    CLOCK_LIGHT = "clock-light",
    CLOSE = "close",
    CLOSE_CIRCLE_LIGHT = "close-circle-light",
    CLOSE_SMALL = "close-small",
    COMPANY_LIGHT = "company-light",
    CROWN_LIGHT = "crown-light",
    DELETE_LIGHT = "delete-light",
    DELIVERY_VAN_LIGHT = "delivery-van-light",
    DELIVERY_VAN_SMALL_LIGHT = "delivery-van-small-light",
    DELTA = "delta",
    DIRECTION_LIGHT = "direction-light",
    DIRECTION_SOLID = "direction-solid",
    DO_NOT_LIGHT = "do-not-light",
    DOCUMENT_LIGHT = "document-light",
    DOT = "dot",
    EDIT_LIGHT = "edit-light",
    END_LIGHT = "end-light",
    END_SOLID = "end-solid",
    ENERGY_CIRCLE = "energy-circle",
    EV_ZONE_LIGHT = "ev-zone-light",
    EV_ZONE_SOLID = "ev-zone-solid",
    EXCHANGE = "exchange",
    EXPAND_ALT = "expand-alt",
    EXPAND_LIGHT = "expand-light",
    EXPORT_LIGHT = "export-light",
    FILTER_LIGHT = "filter-light",
    HEARTH_BROKEN_LIGHT = "hearth-broken-light",
    HIDE_PASSWORD_LIGHT = "hide-password-light",
    HOME_LIGHT = "home-light",
    HOME_SOLID = "home-solid",
    HOMECHARGE_LIGHT = "homecharge-light",
    IDEA_LIGHT = "idea-light",
    IMAGE_GALLERY_LIGHT = "image-gallery-light",
    INFO_LIGHT = "info-light",
    INFO_SOLID = "info-solid",
    INSIGHTS = "insights",
    LIST = "list",
    LOCATION_BOLT_LIGHT = "location-bolt-light",
    LOCATION_BOLT_SOLID = "location-bolt-solid",
    LOCATION_CARPARK = "location-carpark",
    LOCATION_EDU = "location-edu",
    LOCATION_GOVERNMENT = "location-government",
    LOCATION_LEISURE = "location-leisure",
    LOCATION_LIGHT = "location-light",
    LOCATION_SOLID = "location-solid",
    LOCATION_STREET = "location-street",
    LOCATION_SUPERMARKET = "location-supermarket",
    LOCATION_TRAIN_LIGHT = "location-train-light",
    LOCATION_TRAIN_SOLID = "location-train-solid",
    LOCATION_UNKNOWN_LIGHT = "location-unknown-light",
    LOGOUT_LIGHT = "logout-light",
    MEDIA_CHARGER = "media-charger",
    MOBILE_QR_LIGHT = "mobile-qr-light",
    MONEY_LIGHT = "money-light",
    MONEY_POUND_LIGHT = "money-pound-light",
    NIGHT_RATE_LIGHT = "night-rate-light",
    NIGHT_RATE_SOLID = "night-rate-solid",
    ORGANISATION_ALT_LIGHT = "organisation-alt-light",
    ORGANISATION_OPTIONS_LIGHT = "organisation-options-light",
    PADLOCK_LIGHT = "padlock-light",
    PAYMENT_CARD_LIGHT = "payment-card-light",
    PAYMENT_CARD_RECURRING_LIGHT = "payment-card-recurring-light",
    PLUG_LIGHT = "plug-light",
    PLUG_SOLID = "plug-solid",
    PRICE = "price",
    RECEIPT_LIGHT = "receipt-light",
    REFRESH = "refresh",
    REMOVE_USER_LIGHT = "remove-user-light",
    SAD_FACE_LIGHT = "sad-face-light",
    SCROLL_LIGHT = "scroll-light",
    SCROLL_LIGHT_ALT = "scroll-light-alt",
    SEARCH_INSIGHTS = "search-insights",
    SEARCH_LIGHT = "search-light",
    SEND_CHIP_LIGHT = "send-chip-light",
    SETTINGS_LIGHT = "settings-light",
    SHOPPING_LIGHT = "shopping-light",
    SHOW_PASSWORD_LIGHT = "show-password-light",
    SOCIAL_FACEBOOK = "social-facebook",
    SOCIAL_INSTAGRAM = "social-instagram",
    SOCIAL_LINKEDIN = "social-linkedin",
    SOCIAL_TWITTER = "social-twitter",
    SOCIAL_YOUTUBE = "social-youtube",
    SOCKET_CIRCLE = "socket-circle",
    SOCKET_UK_LIGHT = "socket-uk-light",
    SOLO2_LIGHT = "solo2-light",
    SOLO3_LIGHT = "solo3-light",
    STAR_LIGHT = "star-light",
    STAR_SOLID = "star-solid",
    STARS_LIGHT = "stars-light",
    STARS_SOLID = "stars-solid",
    START_LIGHT = "start-light",
    START_SOLID = "start-solid",
    STATS_LIGHT = "stats-light",
    STATS_SOLID = "stats-solid",
    TO_MOBILE_LIGHT = "to-mobile-light",
    TRITIUM = "tritium",
    TWIN = "twin",
    TYPE2_LIGHT = "type2-light",
    TYPE2_SOLID = "type2-solid",
    UNDO = "undo",
    USER_LIGHT = "user-light",
    USER_SOLID = "user-solid",
    USERS_LIGHT = "users-light",
    VEHICLE_LIGHT = "vehicle-light",
    WARNING_BUBBLE_LIGHT = "warning-bubble-light",
    WARNING_BUBBLE_SOLID = "warning-bubble-solid",
    WARNING_CIRCLE_LIGHT = "warning-circle-light",
    WIFI_LIGHT = "wifi-light",
    WIFI_SOLID = "wifi-solid",
}

interface Props {
    icon: Icons;
    title?: string;
    className?: string;
}

export default function Icon({ icon, title, className }: Props) {
    return <i className={classNames("icon", `icon-${icon}`, className)} title={title} aria-label={title} role="img" />;
}
