import React, { useEffect, useMemo, useState } from "react";
import { getCartLineItems } from "../../utils/cart/cart";
import { Product } from "../../types/products";
import { productName } from "../../utils/productName";
import Button from "../Buttons/Button";
import Link from "../Buttons/Link";
import Card from "../Card/Card";
import Price from "../Price";
import ItemQuantityAndRemoval from "../Cart/ItemQuantityAndRemoval";
import { getCartTracking, trackAddToCart } from "../../services/tracking";
import Working from "../Icons/Working";
import { useProductPrice } from "../../hooks/useProductPrice";
import useCartConfig from "../../hooks/useCartConfig";
import useChargers from "../../hooks/BigCommerce/useChargers";
import { Cart } from "../../types/cart";
import { getCustomerGroupId } from "../../utils/customer/customerGroups";
import { useFetchCustomer } from "../../queries/useFetchCustomer";
import { Maybe, Optional } from "../../types/generics";
import { usePropertySelectionStorage } from "../../hooks/usePropertySelectionStorage";
import { useVehicleSelectionStorage } from "../../hooks/useVehicleSelectionStorage";
import { useAffiliateTrackingStorage } from "../../hooks/useAffiliateTrackingStorage";
import { BigCommerceImage } from "../BigCommerceImage";
import AccessoryModal from "./AccessoryModal";

interface Props {
    product: Product;
    content: any;
    cartData: Optional<Maybe<Cart>>;
    onSelect: (attributes: { product: Product; quantity?: number }) => void;
}

export default function AccessoryCard({ product, content, cartData, onSelect }: Props) {
    const { item_in_cart_label } = useCartConfig();
    const { data: customer } = useFetchCustomer();
    const { getPropertySelection } = usePropertySelectionStorage();
    const { getVehicleSelection } = useVehicleSelectionStorage();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();

    const propertySelection = getPropertySelection();
    const vehicleSelection = getVehicleSelection();
    const chargers = useChargers();
    const cartLineItems = getCartLineItems(cartData);
    const productPricing = useProductPrice(getCustomerGroupId(customer, getPartner(), getPublisher()), product.id);

    const lineItem = useMemo(
        () => cartLineItems.find((cartProduct) => cartProduct.product_id === product.id),
        [cartLineItems, product.id]
    );

    const cartEvent = getCartTracking(product, productPricing, cartData, chargers, propertySelection, vehicleSelection);

    const [isAddItemModalOpen, setAddItemModalOpen] = useState(false);
    const [isProductAddingToCart, setIsProductAddingToCart] = useState(false);

    useEffect(() => {
        if (lineItem) {
            setIsProductAddingToCart(false);
        }
    }, [lineItem]);

    const onAddToCart = () => {
        setIsProductAddingToCart(true);

        onSelect({ product });
        trackAddToCart(cartEvent);
    };

    const getFirstTagOfDescription = (mark: string) => {
        const doc = new DOMParser().parseFromString(mark, "text/html");

        return doc.querySelector("p")?.textContent;
    };

    const minQuantity = product.order_quantity_minimum;
    const maxQuantity = product.order_quantity_maximum === 0 ? Infinity : product.order_quantity_maximum;

    return (
        <div className="flex flex-col" data-testid={`accessory-${product.id}`}>
            <Card
                onSelect={() => setAddItemModalOpen(true)}
                isSelected={!!lineItem}
                highlightedHeader={item_in_cart_label}
                className="relative flex items-center justify-center my-4"
            >
                {product.images.length > 0 && (
                    <BigCommerceImage image={product.images[0]} loading="eager" size="standard" />
                )}
            </Card>
            <div className="flex-1 flex flex-col items-start mb-4 mx-1">
                <h3 data-testid={product.name} className="md:text-xl text-lg font-bold mb-2">
                    {productName(product)}
                </h3>
                <p className="mb-4 flex-1">{getFirstTagOfDescription(product.description)}</p>
                <Link onClick={() => setAddItemModalOpen(true)} className="mb-4">
                    {content.primary.more_info}
                </Link>

                <h3 className="text-lg font-bold">
                    <Price product={product} format="{price}" />
                </h3>
            </div>
            <div className="mb-4 mx-1">
                {lineItem ? (
                    <ItemQuantityAndRemoval
                        lineItem={lineItem}
                        minQuantity={minQuantity}
                        maxQuantity={maxQuantity}
                        styleVariant="light"
                    />
                ) : (
                    <Button
                        data-testid={`add-to-cart-button-${product.id}`}
                        aria-label={`Add ${product.name.toLowerCase()} to order`}
                        onClick={() => onAddToCart()}
                        outline={!lineItem && !isProductAddingToCart}
                        disabled={!!lineItem || isProductAddingToCart}
                    >
                        {isProductAddingToCart ? (
                            <>
                                <Working className="mr-2 font-bold" />
                                <span>{content.primary.adding}...</span>
                            </>
                        ) : lineItem ? (
                            content.primary.added
                        ) : (
                            content.primary.add_to_order
                        )}
                    </Button>
                )}
            </div>
            {isAddItemModalOpen && (
                <AccessoryModal
                    data-testid={`accessory-modal-${product.id}`}
                    product={product}
                    closeModal={() => setAddItemModalOpen(false)}
                    onAddToCart={onAddToCart}
                    addToCartText={content.primary.add_to_order}
                    isInCart={!!lineItem}
                />
            )}
        </div>
    );
}
