import { useLocation as useRouterLocation } from "@reach/router";

/**
 * @todo directly use useLocation() from @reach/router instead of ours.
 */
const useLocation = () => {
    const routerLocation = useRouterLocation();

    return typeof window !== "undefined" ? window.location : routerLocation;
};

export default useLocation;
