export enum Pages {
    ACCESSORIES_SELECTION = "/accessory/",
    ADDITIONAL_SERVICES = "/additionalservices/",
    CART_REVIEW = "/review/",
    CHARGER_SELECTION = "/charger/",
    CUSTOMER_DETAILS = "/details/",
    HOME_PAGE = "/",
    INSTALLATION_QUESTIONS = "/installation/",
    LOGIN = "/login/",
    MAGIC_LINK_LOGIN = "/login/link/",
    MULTI_FACTOR = "/login/multi-factor/",
    ORDER_CONFIRMATION = "/confirmation/",
    PDP_CART_PROCESSOR = "/cart/",
    PROPERTY_TYPE_SELECTION = "/property/",
    RESET_PASSWORD = "/resetpassword/",
    TROUBLESHOOTING = "/troubleshoot/",
}

export enum PageTypes {
    AUTH = "auth",
    FLOW = "flow",
    MISC = "misc",
}

export enum NavigationStorage {
    PAGE = "navigation.page",
}

export interface PageDefinition {
    path: string;
    type: string;
    condition: () => boolean;
}
