import { useStaticQuery, graphql } from "gatsby";
import { SiteConfigData, SiteProgressBarData } from "../../graphqlFragments/siteConfig";
import { getLocale } from "../../utils/locale";

const useProgressBarConfig = () => {
    const { languageCode } = getLocale();
    const { siteProgressBarConfig } = useStaticQuery<SiteConfigData>(
        graphql`
            query {
                ...SiteConfigDataFragment
            }
        `
    );

    return siteProgressBarConfig.nodes.find(({ lang }) => languageCode === lang)?.data as SiteProgressBarData;
};

export default useProgressBarConfig;
