import { useMemo } from "react";
import { getChannelId } from "../utils/getChannelId";
import { Maybe } from "../types/generics";
import useCustomerGroupProductsPricing from "./BigCommerce/useCustomerGroupProductsPricing";

const useStoreCustomerGroupProductsPricing = (customerGroupId?: Maybe<number>) => {
    const channelId = getChannelId();
    const customerGroupProductsPricing = useCustomerGroupProductsPricing();

    return useMemo(
        () =>
            customerGroupId
                ? customerGroupProductsPricing.find(
                      (item) =>
                          customerGroupId === item.customer_group_id && (!channelId || channelId === item.channel_id)
                  )?.data ?? []
                : [],
        [customerGroupProductsPricing, channelId, customerGroupId]
    );
};

export default useStoreCustomerGroupProductsPricing;
