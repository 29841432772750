import { useStaticQuery, graphql } from "gatsby";
import { Channel } from "../../types/channel";

const useChannels = (): Channel[] => {
    const { channels } = useStaticQuery(
        graphql`
            query {
                channels {
                    data {
                        id
                        currencies {
                            default_currency
                        }
                        name
                    }
                }
            }
        `
    );

    return channels.data;
};

export default useChannels;
