import React, { PropsWithChildren, useEffect } from "react";
import Cookies from "js-cookie";
import { AffiliateDefaults, Affiliates, AffiliateUtmParams, AwinTrackingCookie } from "../../types/tracking";
import { useAffiliateTracking } from "../../hooks/useAffiliateTracking";
import { OriginSourceEnvironment } from "../../types/origin";
import useLocation from "../../hooks/useLocation";

export const TrackingProvider = ({ children }: PropsWithChildren) => {
    const { store } = useAffiliateTracking();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const cookieParams = JSON.parse(Cookies.get("discount_info") ?? "{}") as AwinTrackingCookie;
    const awc = params.get(AffiliateUtmParams.AWIN_CLICK) ?? cookieParams.awc;
    const awp = params.get(AffiliateUtmParams.AWIN_PUBLISHER) ?? cookieParams.awp ?? AffiliateDefaults.AWIN_PARTNER;
    const type = params.get(AffiliateUtmParams.AWIN_TYPE) ?? cookieParams.type;
    const originSourceEnvironment = type?.match(/partner$/)
        ? OriginSourceEnvironment.AWIN_PARTNER
        : OriginSourceEnvironment.AWIN_WEB;

    useEffect(() => {
        if (awc && awp && originSourceEnvironment) {
            store(awc, Affiliates.AWIN, awp, originSourceEnvironment);
        }
    }, [awc, awp, originSourceEnvironment, store]);

    return <>{children}</>;
};
