import { CustomerAttributeName, CustomerAttributeValue } from "../../types/customerAttributes";
import { getCustomerAttributeValueByName } from "../customer/getCustomerAttributeValueByName";
import { VehicleSelection } from "../../types/vehicle";
import { Maybe, Optional } from "../../types/generics";

export const getVehicleSelectionFromCustomerAttributes = (
    attributes?: CustomerAttributeValue[],
    selection?: Maybe<VehicleSelection>
): Optional<Maybe<VehicleSelection>> => {
    if (!attributes || !!selection) {
        return selection;
    }

    const uidAttribute = getCustomerAttributeValueByName(CustomerAttributeName.VEHICLE_UID, attributes);
    const brandAttribute = getCustomerAttributeValueByName(CustomerAttributeName.VEHICLE_MAKE, attributes);
    const modelAttribute = getCustomerAttributeValueByName(CustomerAttributeName.VEHICLE_MODEL, attributes);
    const conditionAttribute = getCustomerAttributeValueByName(CustomerAttributeName.VEHICLE_CONDITION, attributes);
    const connectorAttribute = getCustomerAttributeValueByName(CustomerAttributeName.VEHICLE_CONNECTOR, attributes);
    const typeAttribute = getCustomerAttributeValueByName(CustomerAttributeName.VEHICLE_TYPE, attributes);

    return {
        uid: uidAttribute?.attribute_value ?? "",
        brand: brandAttribute?.attribute_value ?? "",
        model: modelAttribute?.attribute_value ?? "",
        condition: conditionAttribute?.attribute_value ?? "",
        connector: connectorAttribute?.attribute_value ?? "",
        type: typeAttribute?.attribute_value ?? "",
    };
};
