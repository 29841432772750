import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Product } from "../../../types/products";
import { getProductLineItem } from "../../../utils/cart/cart";
import * as bigCommerce from "../../../services/bigCommerce/proxy";
import { Cart, CartQueryKey } from "../../../types/cart";
import { useFetchCart } from "../useFetchCart";
import { useUpdateLineItemQuantity } from "./useUpdateLineItemQuantity";

export const useAddAccessory = () => {
    const queryClient = useQueryClient();
    const { data: cartData } = useFetchCart();
    const { mutate: updateLineItemQuantity } = useUpdateLineItemQuantity();

    return useMutation<any, any, { product: Product; quantity?: number }>({
        mutationKey: ["addAccessory"],
        mutationFn: async ({ product, quantity = 1 }) => {
            if (!cartData) {
                throw new Error("Cart not found to add product");
            }

            const lineItem = getProductLineItem(product, cartData);
            if (lineItem) {
                return updateLineItemQuantity({ lineItem, quantity: lineItem.quantity + 1 });
            }

            const response = await bigCommerce.addCartLineItems(cartData.id, {
                line_items: [
                    {
                        product_id: product.id,
                        quantity,
                    },
                ],
            });

            return response.data.data;
        },
        onSuccess: (data: Cart) => {
            queryClient.setQueryData([CartQueryKey.FETCH, data.id], data);
        },
    });
};
