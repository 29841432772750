import { graphql, useStaticQuery } from "gatsby";
import { ProductPriceGroup } from "../../types/products";

const useCustomerGroupProductsPricing = () => {
    const { customerGroupProductsPricing } = useStaticQuery<Queries.CustomerGroupProductsPricingListQuery>(
        graphql`
            query CustomerGroupProductsPricingList {
                customerGroupProductsPricing {
                    data {
                        ...CustomerGroupsProductPricing
                    }
                }
            }
        `
    );

    return (customerGroupProductsPricing?.data ?? []) as unknown as ProductPriceGroup[];
};

export default useCustomerGroupProductsPricing;
