import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { getLocale } from "../../utils/locale";
import { IndividualCustomerContentCommon } from "../../graphqlFragments/individualCustomerContent";

const useIndividualCustomerContent = () => {
    const { languageCode } = getLocale();
    const published = useStaticQuery<Queries.individualCustomerContentQuery>(
        graphql`
            query individualCustomerContent {
                ...IndividualCustomerContentData
            }
        `
    );

    const { data: dataWithPreviews } = useMergePrismicPreviewData(published);

    return dataWithPreviews.individualCustomerContent.nodes
        .filter(({ lang }) => lang === languageCode)
        .map(
            ({ data, uid }) =>
                ({
                    uid,
                    ...data,
                } as IndividualCustomerContentCommon)
        );
};

export default useIndividualCustomerContent;
