import { FirebaseApp, FirebaseError, FirebaseOptions, initializeApp } from "firebase/app";
import { AppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import {
    Auth,
    browserLocalPersistence,
    browserSessionPersistence,
    getAuth,
    getMultiFactorResolver,
    isSignInWithEmailLink,
    MultiFactorError,
    MultiFactorInfo,
    MultiFactorResolver,
    onAuthStateChanged,
    Persistence,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    setPersistence,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signOut,
    type User,
} from "firebase/auth";
import jwtDecode from "jwt-decode";
import { FirebaseAuthErrorCodes } from "../types/firebase";
import { Maybe } from "../types/generics";

export enum AuthCommonFormFields {
    EMAIL = "email",
    PASSWORD = "password",
}

export enum LoginFormFields {
    REMEMBER = "remember",
}

export enum MultiFactorFormFields {
    VERIFICATION_CODE = "code",
}

export interface LoginFormData {
    [AuthCommonFormFields.EMAIL]: string;
    [AuthCommonFormFields.PASSWORD]: string;
    [LoginFormFields.REMEMBER]: boolean;
}

export interface ResetPasswordFormData {
    [AuthCommonFormFields.EMAIL]: string;
}

export interface MultiFactorFormData {
    [MultiFactorFormFields.VERIFICATION_CODE]: string;
}

export interface MultiFactorSmsInfo extends MultiFactorInfo {
    phoneNumber: string;
}

export interface MultiFactorSmsRequest {
    verificationId: string;
    phoneNumber: string;
}

interface FirebaseServiceContainer {
    app: FirebaseApp | null;
    auth: Auth;
    mfaResolver: MultiFactorResolver | null;
    recaptchaVerifier: RecaptchaVerifier | null;
}

const config = (): Partial<FirebaseOptions> & { recaptchaKey: string | undefined } => ({
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    recaptchaKey: process.env.GATSBY_RECAPTCHA_ENTERPRISE_API_KEY,
});

let app: Maybe<FirebaseApp> = null;
let auth: Auth;
let appCheck: Maybe<AppCheck> = null;
let mfaResolver: MultiFactorResolver | null = null;
let recaptchaVerifier: RecaptchaVerifier | null = null;

export const init = (): FirebaseServiceContainer => {
    const { apiKey, appId, authDomain, projectId, recaptchaKey } = config();

    if (!app) {
        app = initializeApp({ apiKey, authDomain, projectId, appId });
    }

    if (!auth) {
        auth = getAuth(app);
    }

    if (!appCheck) {
        if (recaptchaKey) {
            appCheck = initializeAppCheck(app, {
                provider: new ReCaptchaEnterpriseProvider(recaptchaKey),
                isTokenAutoRefreshEnabled: true,
            });
        }
    }

    mfaResolver = mfaResolver || null;
    recaptchaVerifier = recaptchaVerifier || null;

    return {
        app,
        auth,
        mfaResolver,
        recaptchaVerifier,
    };
};

export const transformAccessToken = (token: string) => {
    const decodedToken = jwtDecode(token) as { exp: number };

    return {
        access_token: token,
        expires_at: new Date(decodedToken.exp).toString(),
    };
};

export const logIn = async (email: string, password: string, persist: Persistence = browserSessionPersistence) => {
    const { auth } = init();

    await setPersistence(auth, persist);

    return signInWithEmailAndPassword(auth, email, password);
};

export const isMagicLinkValid = async (link: string) => {
    const { auth } = init();

    return isSignInWithEmailLink(auth, link);
};

export const logInWithMagicLink = async (
    email: string,
    link: string,
    persist: Persistence = browserSessionPersistence
) => {
    const { auth } = init();

    await setPersistence(auth, persist);

    return signInWithEmailLink(auth, email, link);
};

export const logOut = () => {
    const { auth } = init();

    return signOut(auth);
};

export const getUser = () => {
    const { auth } = init();

    return auth.currentUser;
};

export const isMultiFactorRequired = (error: FirebaseError) => error.code === FirebaseAuthErrorCodes.MFA_REQUIRED;
export const isMultiFactorNotRequired = (error: FirebaseError) => !isMultiFactorRequired(error);

export const initializeInvisibleRecaptchaById = (id: string) => {
    const { auth } = init();

    recaptchaVerifier = recaptchaVerifier || new RecaptchaVerifier(auth, id, { size: "invisible" });
};

export const clearInvisibleRecaptcha = () => {
    const { recaptchaVerifier: instance } = init();

    if (instance && typeof instance.clear === "function") {
        instance.clear();
    }

    recaptchaVerifier = null;
};

export const multiFactorSms = async (error?: MultiFactorError) => {
    const { auth, recaptchaVerifier } = init();

    mfaResolver = mfaResolver || (error ? getMultiFactorResolver(auth, error) : null);

    if (!mfaResolver) {
        throw new Error("Multi factor resolver misconfigured.");
    }

    const smsFactor = mfaResolver.hints.find((factor) => factor.factorId === "phone");

    if (!smsFactor) {
        throw new FirebaseError(
            FirebaseAuthErrorCodes.MFA_SMS_UNAVAILABLE,
            `Firebase: Error (${FirebaseAuthErrorCodes.MFA_SMS_UNAVAILABLE}).`
        );
    }

    if (!recaptchaVerifier) {
        throw new Error("Recaptcha verifier misconfigured.");
    }

    const verificationId = await new PhoneAuthProvider(auth).verifyPhoneNumber(
        {
            multiFactorHint: smsFactor,
            session: mfaResolver.session,
        },
        recaptchaVerifier
    );

    return {
        verificationId,
        phoneNumber: (smsFactor as MultiFactorSmsInfo).phoneNumber,
    };
};

export const multiFactorSmsVerify = (
    verificationId: string,
    verificationCode: string,
    resolver?: MultiFactorResolver
) => {
    mfaResolver = resolver || mfaResolver || init().mfaResolver;

    const credentials = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

    if (!mfaResolver) {
        throw new Error("Multi factor resolver misconfigured.");
    }

    return mfaResolver.resolveSignIn(multiFactorAssertion);
};

export { browserLocalPersistence, onAuthStateChanged, User };
