import { useStaticQuery, graphql } from "gatsby";
import { getLocale } from "../../utils/locale";
import { ContentType } from "../../types/prismic";
import { findSlice } from "../../utils/prismic";

const useAuthErrorContent = () => {
    const { languageCode } = getLocale();
    const data = useStaticQuery<Queries.AuthErrorScreenQuery>(
        graphql`
            query AuthErrorScreen {
                authErrorContent: allPrismicPage(filter: { data: { page_type: { eq: "login" } } }) {
                    nodes {
                        id
                        lang
                        data {
                            header
                            body {
                                __typename
                                ...PrismicAuthError
                            }
                        }
                    }
                }
            }
        `
    );

    const localePageData = data.authErrorContent.nodes.find(({ lang }) => lang === languageCode)?.data;
    const signInData = findSlice<Queries.PrismicAuthErrorFragment>(localePageData?.body, ContentType.SIGN_IN);

    return { signInData, localePageData };
};

export default useAuthErrorContent;
