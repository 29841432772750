import React, { FC, useState } from "react";
import { Dialog } from "@headlessui/react";
import useCartConfig from "../../hooks/useCartConfig";
import { getCartChargerLineItemCount, getCartLineItems, getCartLineItemsTotalQuantity } from "../../utils/cart/cart";
import { trackNavbarClick } from "../../services/tracking";
import { usePriceFormatting } from "../../services/PriceFormat";
import useChargers from "../../hooks/BigCommerce/useChargers";
import { useFetchCart } from "../../queries/cart/useFetchCart";
import Icon, { Icons } from "../Icons/Icon";
import Card from "../Card/Card";
import { MultipleChargersAlert } from "./MultipleChargersAlert";
import { CartItemLines } from "./CartItemLines";
import { CartRowVariant } from "./ChargerCartRow";
import EmptyCart from "./Empty";
import { SuggestedAccessories } from "./SuggestedAccessories";

export const Cart: FC = () => {
    const cartConfigData = useCartConfig();
    const { data: cartData } = useFetchCart();
    const chargers = useChargers();
    const cartLineItems = getCartLineItems(cartData);
    const itemCount = getCartLineItemsTotalQuantity(cartLineItems);
    const chargerCount = getCartChargerLineItemCount(cartLineItems, chargers);
    const { formatPrice } = usePriceFormatting();

    const cartHasItems = itemCount > 0;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const CartModalTotal = () => (
        <div className="flex flex-row gap-2 text-xl font-bold">
            <p>{cartConfigData?.cart_total ?? ""}</p>
            <p>
                <>{formatPrice(cartData?.cart_amount ?? 0)}</>
            </p>
        </div>
    );

    return (
        <>
            <button
                aria-label="mini-cart-icon"
                className="flex text-sm rounded-full p-4 text-center hover:bg-gray-200/50"
                id="cart-popover-button"
                onClick={() => {
                    trackNavbarClick("Header", "Basket");
                    setIsOpen(true);
                }}
            >
                <img
                    className="h-5 w-5 icon-shop"
                    src={cartConfigData.cart_header_icon.url}
                    alt={cartConfigData.cart_header_icon.alt}
                />
                {cartHasItems ? (
                    <div
                        aria-label="mini-cart-count"
                        className="absolute right-6 bottom-2 bg-green-500 w-4 h-4 rounded-md text-white text-xs"
                    >
                        {itemCount}
                    </div>
                ) : null}
            </button>

            {isOpen && (
                <Dialog open={true} onClose={() => setIsOpen(false)}>
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-20 h-full" />
                    <Dialog.Panel
                        className="fixed inset-right-0 inset-y-0 bg-gray-100 sm:w-[450px] w-full right-0 top-0 rounded-lg z-20"
                        data-testid="modal-content"
                        aria-label="modal-content"
                    >
                        <button
                            onClick={() => setIsOpen(false)}
                            className="flex items-center rounded-full w-8 h-8 justify-center absolute top-4 right-8 bg-gray-200 z-50"
                            data-testid="close-cart-button"
                            aria-label="close-cart-button"
                        >
                            <Icon icon={Icons.CLOSE} className="text-xl text-gray-600 font-bold" />
                        </button>
                        <div className="flex flex-col gap-4 p-4 w-full supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh] h-screen overflow-y-auto">
                            <h1 className="text-2xl font-bold">{cartConfigData?.cart_title ?? ""}</h1>
                            {chargerCount > 1 && <MultipleChargersAlert />}
                            <Card className="bg-white flex flex-col gap-2 w-full">
                                {cartHasItems ? (
                                    <>
                                        <CartModalTotal />
                                        <CartItemLines rowVariant={CartRowVariant.MINIMAL} lineItems={cartLineItems} />
                                        <CartModalTotal />
                                    </>
                                ) : (
                                    <EmptyCart data={cartConfigData} />
                                )}
                            </Card>
                            {cartHasItems && (
                                <SuggestedAccessories
                                    maxItems={4}
                                    gridStyles="grid grid-cols-2 gap-2"
                                    cartData={cartData}
                                />
                            )}
                        </div>
                    </Dialog.Panel>
                </Dialog>
            )}
        </>
    );
};
