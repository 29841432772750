import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { CountryCode, getLocale } from "../../utils/locale";
import { Maybe, Optional } from "../../types/generics";

type ConfirmCountryModalContentProps = {
    cancel_button: Optional<Maybe<string>>;
    action_button: Optional<Maybe<string>>;
    title: Optional<Maybe<string>>;
    description: Optional<Maybe<string>>;
};

const useConfirmCountryModalContent = (
    suggestedCountryCode: Optional<CountryCode>
): ConfirmCountryModalContentProps => {
    const { languageCode } = getLocale();
    const publishedData = useStaticQuery<Queries.ConfirmCountryModalFragmentFragment>(
        graphql`
            query ConfirmCountryModals {
                ...ConfirmCountryModalFragment
            }
        `
    );

    const { data: dataWithPreviews } = useMergePrismicPreviewData(publishedData);

    const dataForLocale = dataWithPreviews.confirmCountryModals.nodes.find(
        ({ lang }: { lang: string }) => languageCode === lang
    )?.data;

    return {
        cancel_button: dataForLocale?.cancel_button,
        action_button: dataForLocale?.action_button,
        title:
            dataForLocale && suggestedCountryCode ? dataForLocale[`title_suggest_${suggestedCountryCode}`] : undefined,
        description:
            dataForLocale && suggestedCountryCode
                ? dataForLocale[`description_suggest_${suggestedCountryCode}`]
                : undefined,
    };
};

export default useConfirmCountryModalContent;

export type CountryWarningModalContent = ReturnType<typeof useConfirmCountryModalContent>;
