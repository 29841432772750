import { useMutation } from "@tanstack/react-query";
import { CustomerAttributeName } from "../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../store/ephemeral/customerAttributes";
import { useFetchCustomer } from "./useFetchCustomer";
import { useSaveCustomerAttributes } from "./useSaveCustomerAttributes";

export const useSaveLastPageVisited = () => {
    const { mutateAsync } = useSaveCustomerAttributes();
    const { data: customer } = useFetchCustomer();

    return useMutation({
        mutationFn: async (page: string) => {
            if (!customer) {
                return null;
            }

            const attributeId = getCustomerAttributeIdByName(CustomerAttributeName.LAST_PAGE_VISITED);

            return mutateAsync([
                {
                    attribute_id: attributeId,
                    customer_id: customer.id,
                    value: page,
                },
            ]);
        },
    });
};
