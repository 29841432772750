import React, { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

function QueryProvider(props: PropsWithChildren<object>) {
    return (
        <QueryClientProvider client={queryClient}>
            {props.children}
            {process.env.GATSBY_ENVIRONMENT === "local" ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </QueryClientProvider>
    );
}

export default QueryProvider;
