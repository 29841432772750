import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { exchangeToken } from "../services/auth";
import { init, onAuthStateChanged, transformAccessToken } from "../services/firebaseService";
import { useAuthentication } from "./useAuthentication";

/**
 * Use the current logged in firebase user to query the user from ecommerce-service.
 * If not logged in, the query is disabled and `user` will be `undefined`.
 * Otherwise, `user` will be the current user.
 *
 * `query` is the reactQuery object if more details are needed.
 * `isAuthReady` becomes true when firebase is ready and the user query is done fetching -
 * if `user` is `undefined` once `isReady` is true, we can assume no user is logged in.
 */
export const useUser = () => {
    const [token, setToken] = useState<string>();
    const [firebaseReady, setFirebaseReady] = useState(false);
    const { clearMultiFactorChallenge } = useAuthentication();

    useEffect(() => {
        const { auth } = init();

        return onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                clearMultiFactorChallenge();
                setToken(await firebaseUser.getIdToken());
            }

            setFirebaseReady(true);
        });
    }, [clearMultiFactorChallenge]);

    const query = useQuery({
        queryKey: ["getUser", token],
        queryFn: async () => {
            return token ? await exchangeToken(transformAccessToken(token)) : undefined;
        },
        enabled: firebaseReady && token != undefined,
        staleTime: Infinity,
    });

    return {
        ...query,
        isAuthReady: firebaseReady && !query.isFetching,
    };
};
