import React, { FC } from "react";
import { ErrorBoundary } from "@sentry/gatsby";
import { LocationProvider } from "@reach/router";
import Layout from "../Layout/Layout";
import PageHeader from "../Layout/Page/Header";
import Card from "../Card/Card";
import Button from "../Buttons/Button";
import useErrorBoundaryConfig from "../../hooks/useErrorBoundaryConfig";

interface GlobalErrorBoundaryProps {
    children: any;
}

export const FallbackErrorComp = () => {
    if (typeof window !== "undefined" && typeof window.clarity === "function") {
        window.clarity("upgrade", "error boundary reached");
    }

    const errorBoundaryPageData = useErrorBoundaryConfig();

    const reset = () => {
        location.reload();
    };

    return (
        <LocationProvider>
            <Layout>
                <div className="w-full flex flex-col gap-4 mx-auto relative" data-testid="global-error">
                    <PageHeader title={errorBoundaryPageData.page_header} />
                    <div className="flex w-full justify-center items-center">
                        <Card className="h-min md:min-w-[600px] min-w-full flex flex-col gap-4 p-8">
                            <h3 className="md:text-xl text-lg font-bold mb-2">{errorBoundaryPageData.card_header}</h3>
                            <p className="mb-2">{errorBoundaryPageData.card_body}</p>
                            <div className="flex flex-row gap-2 justify-center">
                                <Button onClick={reset} outline className="w-full">
                                    {errorBoundaryPageData.card_button}
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </Layout>
        </LocationProvider>
    );
};

export const GlobalErrorBoundary: FC<GlobalErrorBoundaryProps> = ({ children }) => {
    return <ErrorBoundary fallback={FallbackErrorComp}>{children}</ErrorBoundary>;
};
