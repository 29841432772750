import { CustomerAttribute, CustomerAttributeName } from "../../types/customerAttributes";
import { getItem, storeItem } from "./index";

const CUSTOMER_ATTRIBUTES_DICTIONARY = "customer_attributes.dictionary";

export const setCustomerAttributes = (customerAttributes: CustomerAttribute[]) =>
    storeItem(
        CUSTOMER_ATTRIBUTES_DICTIONARY,
        customerAttributes.reduce(
            (prev, attr) => ({
                ...prev,
                [attr.name]: attr.id,
            }),
            {}
        )
    );

export const getCustomerAttributes = () =>
    getItem<Record<CustomerAttributeName, number>>(
        CUSTOMER_ATTRIBUTES_DICTIONARY,
        {} as Record<CustomerAttributeName, number>
    );

export const getCustomerAttributeIdByName = (name: CustomerAttributeName) => getCustomerAttributes()[name];
