import { getChannelId } from "../../utils/getChannelId";
import { useChannelById } from "./useChannelById";

export const useChannel = () => {
    const channelId = getChannelId();
    const channel = useChannelById(channelId);

    if (!channel) {
        throw new Error(`Couldn't retrieve current Channel [ID: ${channelId}]`);
    }

    return channel;
};
