import { Maybe } from "./generics";

export enum ConsentEnvironment {
    STOREFRONT = "Storefront",
}

export interface ConsentInput {
    copy: string;
    date: Date;
}

export enum PhoneConsent {
    IS_CONSENT_GIVEN = "yes",
    TYPE = "Legitimate Interest",
}

export enum EmailConsent {
    IS_CONSENT_GIVEN = "yes",
    TYPE = "Legitimate Interest",
}

export enum MarketingConsentGiven {
    CONSENT_NOT_GIVEN = "no",
    CONSENT_GIVEN = "yes",
}

export enum MarketingConsentType {
    EXPRESS_CONSENT = "Express Consent",
}

export interface MarketingConsent {
    isConsentGiven: Maybe<MarketingConsentGiven>;
    type: Maybe<MarketingConsentType>;
    date: Maybe<Date>;
    copy: Maybe<string>;
}

export enum ConsentStorage {
    MARKETING_CONSENT_SELECTION = "consent.marketing",
}
