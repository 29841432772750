import { useMemo } from "react";
import { getChannelId } from "../utils/getChannelId";
import useDefaultProductsPricing from "./BigCommerce/useDefaultProductsPricing";

const useStoreDefaultProductsPricing = () => {
    const channelId = getChannelId();
    const defaultProductsPricing = useDefaultProductsPricing();

    return useMemo(
        () => defaultProductsPricing.find((item) => channelId === item.channel_id)?.data ?? [],
        [defaultProductsPricing, channelId]
    );
};

export default useStoreDefaultProductsPricing;
