import React, { useState } from "react";
import { LineItem } from "../../types/cart";
import { productName } from "../../utils/productName";
import Link from "../Buttons/Link";
import CardSection from "../Card/Section";
import AccessoryModal from "../Accessories/AccessoryModal";
import useProducts from "../../hooks/BigCommerce/useProducts";
import { kebabCase } from "../../utils/str/case";
import CartRow from "./CartRow";
import { CartRowVariant } from "./ChargerCartRow";
import ItemQuantityAndRemoval from "./ItemQuantityAndRemoval";
import CartPrice from "./CartPrice";

interface Props {
    lineItem: LineItem;
    content?: Queries.PrismicCartPageFragment;
    rowVariant?: CartRowVariant;
}

export default function AccessoryCartRow({ lineItem, content, rowVariant = CartRowVariant.STANDARD }: Props) {
    const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false);
    const allProducts = useProducts();
    const product = allProducts.find((product) => lineItem.product_id === product.id);

    if (!product) {
        return null;
    }
    const minQuantity = product.order_quantity_minimum;
    const maxQuantity = product.order_quantity_maximum === 0 ? Infinity : product.order_quantity_maximum;

    return (
        <CartRow
            testId={`accessory-cart-row-${kebabCase(product.name)}`}
            image={
                product.images.length > 0
                    ? product.images.find((img) => img.is_thumbnail) ?? product.images[0]
                    : undefined
            }
            content={
                <div
                    className={`flex w-full ${
                        rowVariant === CartRowVariant.STANDARD ? "flex-row justify-between" : "flex-col"
                    }`}
                >
                    <CardSection>
                        <h2 className="text-xl font-bold mb-4">{productName(product)}</h2>

                        <ItemQuantityAndRemoval
                            lineItem={lineItem}
                            minQuantity={minQuantity}
                            maxQuantity={maxQuantity}
                        />
                        <Link onClick={() => setIsMoreInfoModalOpen(true)} className="mt-2">
                            {content?.primary.more_info}
                        </Link>
                    </CardSection>
                    <CardSection>
                        <h3 className="text-2xl font-bold">
                            <CartPrice lineItem={lineItem} />
                        </h3>
                    </CardSection>
                    {isMoreInfoModalOpen && (
                        <AccessoryModal product={product} closeModal={() => setIsMoreInfoModalOpen(false)} />
                    )}
                </div>
            }
        />
    );
}
