import React from "react";
import useFooterConfig from "../../hooks/useFooterConfig";
import Link from "../Buttons/Link";

function Footer() {
    const footerConfigData = useFooterConfig();

    return (
        <footer className="bg-gray-600 text-white">
            <div className="md:flex items-center container px-4">
                <ul className="md:flex-1 md:flex py-2">
                    {footerConfigData?.footer_menu?.map((item: any, key: number) => (
                        <li className="px-4 my-2 text-center" key={key}>
                            <Link
                                className="text-xs font-bold uppercase text-white hover:text-white hover:underline"
                                isToOpenInNewTab={item.open_in_new_tab_link_target}
                                to={item.link_target.url}
                            >
                                {item.link_text}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="p-4">
                    <p className="font-bold text-xs text-right">
                        {footerConfigData?.copyrights?.replace("{current_year}", new Date().getFullYear().toString())}
                        <br />
                        <Link
                            className="text-white hover:text-white hover:underline"
                            to="https://pod-point.com"
                            isToOpenInNewTab
                        >
                            pod-point.com
                        </Link>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
