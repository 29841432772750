import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import { PrismicRichText } from "@prismicio/react";
import useMarketingBanners from "../../hooks/useMarketingBanners";
import { MarketingBannerCommon } from "../../graphqlFragments/marketingBanners";
import { Maybe } from "../../types/generics";
import useLocation from "../../hooks/useLocation";
import Banner from "./Banner";

export interface MarketingBannerProps extends HTMLAttributes<HTMLDivElement> {
    types?: Maybe<string>[];
    uid?: MarketingBannerCommon["uid"];
    customerGroupName?: string;
}

interface ParagraphRichText {
    type: "paragraph";
    text: string;
}

// Exception paths for pages where banners should not be displayed
const exceptionPaths = [
    "/success", // list all location.pathname
];
const styleColor = (custom_color: Maybe<string>, custom_background: Maybe<string>) => ({
    ...(custom_color ? { color: custom_color } : {}),
    ...(custom_background ? { background: custom_background } : {}),
});

const isValidType = (types: MarketingBannerProps["types"], bannerPosition: Maybe<string>) =>
    !types || types?.includes(bannerPosition);

export const isValidUid = (uid?: string, elementUid?: string) => !uid || uid === elementUid;

export const isValidCustomerGroup = (customerGroup?: string, customerGroupFieldValue?: ParagraphRichText[]) =>
    !customerGroup || customerGroupFieldValue?.map(({ text }) => text).includes(customerGroup);

const filterBanners =
    (types: MarketingBannerProps["types"], uid?: string, customerGroup?: string) => (banner: MarketingBannerCommon) =>
        banner.is_enabled &&
        isValidType(types, banner.position) &&
        isValidUid(uid, banner.uid) &&
        isValidCustomerGroup(customerGroup, banner.customer_groups?.richText);

function MarketingBanners({ types, uid, className, customerGroupName }: MarketingBannerProps) {
    const location = useLocation();

    // load all available banners
    const marketingBannersData = useMarketingBanners();

    // check if page url is set for exception
    if (exceptionPaths.indexOf(location.pathname) >= 0) {
        return null;
    }

    return (
        <>
            {marketingBannersData
                .filter(filterBanners(types, uid, customerGroupName))
                .map(({ icon, color, custom_color, custom_background, text, is_dismissible, hero_image, hash }) => (
                    <Banner
                        key={hash}
                        hash={hash}
                        className={classNames(className)}
                        icon={icon}
                        style={styleColor(custom_color, custom_background)}
                        dismissible={is_dismissible}
                        image={hero_image}
                        alertColor={color}
                    >
                        <div>
                            <PrismicRichText field={text?.richText} />
                        </div>
                    </Banner>
                ))}
        </>
    );
}

export default MarketingBanners;
