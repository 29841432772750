import store from "store2";
import { Maybe } from "../types/generics";
import { captureException } from "../utils/sentry";

type Getter<T> = () => Maybe<T>;
type Setter<T> = (value?: T) => void;

const getItem = <T>(key: string): Maybe<T> => {
    if (typeof window === "undefined") {
        return null;
    }

    try {
        return store.get(key);
    } catch (error) {
        captureException(error, `localStorage hook: Couldn't retrieve value for [key: ${key}]`);

        return null;
    }
};

export const deleteItem = (key: string) => {
    if (typeof window === "undefined") {
        return;
    }

    store.remove(key);
};

const setItem = <T>(key: string, value?: T): void => {
    if (typeof window === "undefined") {
        return;
    }

    if (value === undefined) {
        deleteItem(key);

        return;
    }

    try {
        store.set(key, value);
    } catch (error) {
        captureException(error, `localStorage hook: Couldn't set value for [key: ${key}]`, value);
    }
};

export const useTransactionalLocalStorage = <T>(key: string): [Getter<T>, Setter<T>] => {
    return [
        () => getItem(key),
        (value?: T) => {
            setItem(key, value);
        },
    ];
};
