import React, { PropsWithChildren } from "react";
import { setPriceListAssignments, setPriceListRecords, setPriceLists } from "../../store/ephemeral/prices";
import usePriceLists from "../../hooks/BigCommerce/usePriceLists";
import usePriceListAssignments from "../../hooks/BigCommerce/usePriceListAssignments";
import usePriceListRecords from "../../hooks/BigCommerce/usePriceListRecords";
import useProducts from "../../hooks/BigCommerce/useProducts";
import useCategories from "../../hooks/BigCommerce/useCategories";
import useChargers from "../../hooks/BigCommerce/useChargers";
import { setCategories, setProducts } from "../../store/ephemeral/products";
import Spinner from "../Spinner";
import useCustomerAttributes from "../../hooks/BigCommerce/useCustomerAttributes";
import useCustomerGroups from "../../hooks/BigCommerce/useCustomerGroups";
import { setCustomerAttributes } from "../../store/ephemeral/customerAttributes";
import useChannelCurrency from "../../hooks/BigCommerce/useChannelCurrency";
import { setChannelCurrency, setDefaultCustomerGroupId, setGuestCustomerGroupId } from "../../store/ephemeral/channel";
import { setAffiliateCustomerGroups } from "../../store/ephemeral/customerGroups";

export const StaticDataProvider = ({ children }: PropsWithChildren) => {
    const lists = usePriceLists();
    const listAssignments = usePriceListAssignments();
    const listRecords = usePriceListRecords();
    const categories = useCategories();
    const products = useProducts();
    const chargers = useChargers();
    const customerAttributes = useCustomerAttributes();
    const customerGroups = useCustomerGroups();
    const channelCurrency = useChannelCurrency();

    if (
        !lists ||
        !listAssignments ||
        !listRecords ||
        !products ||
        !categories ||
        !customerAttributes ||
        !customerGroups
    ) {
        return <Spinner />;
    }

    setPriceLists(lists);
    setPriceListAssignments(listAssignments);
    setPriceListRecords(listRecords);
    setCategories(categories);
    setProducts(
        products.map((product) => {
            const charger = chargers.find((charger) => charger.id === product.id);

            return charger ?? product;
        })
    );
    setCustomerAttributes(customerAttributes);
    setDefaultCustomerGroupId(customerGroups);
    setGuestCustomerGroupId(customerGroups);
    setAffiliateCustomerGroups(customerGroups);
    setChannelCurrency(channelCurrency);

    return <>{children}</>;
};
