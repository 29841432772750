import React, { FC, HTMLAttributes } from "react";
import classNames from "classnames";
import DOMPurify from "isomorphic-dompurify";
import { IndividualCustomerContentCommon } from "../../graphqlFragments/individualCustomerContent";
import useIndividualCustomerContent from "../../hooks/utils/useIndividualCustomerContent";
import { appendLinkIcons } from "../../utils/appendLinkIcons";
import { isValidCustomerGroup, isValidUid } from "./MarketingBanners";

interface IndividualCustomerContentProps extends HTMLAttributes<HTMLDivElement> {
    uid?: IndividualCustomerContentCommon["uid"];
    customerGroupName?: string;
}

export const IndividualCustomerContent: FC<IndividualCustomerContentProps> = ({ uid, customerGroupName, ...props }) => {
    const customerContentData = useIndividualCustomerContent();

    const filterContent = (uid?: string, customerGroup?: string) => (customContent: IndividualCustomerContentCommon) =>
        customContent.is_enabled &&
        isValidUid(uid, customContent.uid) &&
        isValidCustomerGroup(customerGroup, customContent.customer_groups?.richText);

    return (
        <>
            {customerContentData.filter(filterContent(uid, customerGroupName)).map(({ uid, content }) => (
                <div key={uid} className={classNames(props.className)}>
                    <p
                        className="prose-a:text-green-400 hover:prose-a:underline"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(appendLinkIcons(content?.html || ""), { ADD_ATTR: ["target"] }),
                        }}
                    />
                </div>
            ))}
        </>
    );
};
