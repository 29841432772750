import { Product, ProductCategory, ProductCategoryName } from "../../types/products";
import { getItem, storeItem } from "./index";

const PRODUCTS = "products";
const PRODUCT_CATEGORIES = "products.categories";

export const setCategories = (categories: ProductCategory[]) => storeItem(PRODUCT_CATEGORIES, categories);
export const getCategories = () => getItem<ProductCategory[]>(PRODUCT_CATEGORIES, []);
export const getChargersProductCategory = () => {
    const categories = getCategories();

    return categories.find((category) => category.name === ProductCategoryName.Chargers) as ProductCategory;
};
export const getAccessoryCategory = () => {
    const categories = getCategories();

    return categories.find((category) => category.name === ProductCategoryName.Accessories) as ProductCategory;
};

export const getAdditionalServicesCategory = () => {
    const categories = getCategories();

    return categories.find((category) => category.name === ProductCategoryName.AdditionalServices) as ProductCategory;
};
export const getChargersProductSubCategories = (forChargers: Product[] = []): ProductCategory[] => {
    const categories = getCategories();
    const chargerCategory = getChargersProductCategory();

    return categories
        .filter((category) => category.parent_id === chargerCategory.category_id)
        .filter((category) =>
            forChargers.length
                ? forChargers.flatMap((charger) => charger.categories).includes(category.category_id)
                : true
        )
        .sort((a, b) => a.sort_order - b.sort_order);
};
export const setProducts = (products: Product[]) => storeItem(PRODUCTS, products);
export const getProducts = (): Product[] => getItem(PRODUCTS, []);
export const getAccessories = (): Product[] =>
    getProducts().filter((product) => product.categories.includes(getAccessoryCategory().category_id));
export const getAdditionalServices = (): Product[] =>
    getProducts().filter((product) => product.categories.includes(getAdditionalServicesCategory().category_id));
