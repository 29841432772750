import { useMutation } from "@tanstack/react-query";
import { InstallationSelection } from "../types/installation";
import { getCustomerAttributeIdByName } from "../store/ephemeral/customerAttributes";
import { CustomerAttributeName } from "../types/customerAttributes";
import { getInstallationType } from "../utils/installation/getInstallationType";
import { useSaveCustomerAttributes } from "./useSaveCustomerAttributes";
import { useFetchCustomer } from "./useFetchCustomer";

export const useSaveInstallationSelection = () => {
    const { data: customer } = useFetchCustomer();
    const { mutateAsync: saveCustomerAttributes } = useSaveCustomerAttributes();

    return useMutation({
        mutationFn: async ({ truckingLength, cableLength, digRequired }: InstallationSelection) => {
            if (!customer || !cableLength || !truckingLength || !digRequired) {
                return null;
            }

            return saveCustomerAttributes([
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.INSTALLATION_CABLE_LENGTH),
                    customer_id: customer.id,
                    value: cableLength,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.INSTALLATION_TRUCKING_LENGTH),
                    customer_id: customer.id,
                    value: truckingLength,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.INSTALLATION_DIG_REQUIRED),
                    customer_id: customer.id,
                    value: digRequired,
                },
                {
                    attribute_id: getCustomerAttributeIdByName(CustomerAttributeName.INSTALLATION_TYPE),
                    customer_id: customer.id,
                    value: getInstallationType({ truckingLength, cableLength, digRequired }),
                },
            ]);
        },
    });
};
