import { CartStorage } from "../types/cart";
import { Maybe } from "../types/generics";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const useCartSelectionStorage = () => {
    const [getCartId, setCartId] = useTransactionalLocalStorage<Maybe<string>>(CartStorage.CART_ID);
    const [getVariantId, setVariantId] = useTransactionalLocalStorage<Maybe<number>>(CartStorage.SELECTED_VARIANT_ID);

    return {
        getCartId,
        setCartId,
        getVariantId,
        setVariantId,
    };
};
