import { useStaticQuery, graphql } from "gatsby";
import { CustomerAttribute } from "../../types/customerAttributes";

const useCustomerAttributes = (): CustomerAttribute[] => {
    const { customerAttributes } = useStaticQuery(
        graphql`
            query {
                customerAttributes {
                    data {
                        id
                        name
                        type
                    }
                }
            }
        `
    );

    return customerAttributes.data;
};

export default useCustomerAttributes;
