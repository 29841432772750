export interface Order {
    id: number;
    customer_id: number;
    total_inc_tax: string;
    total_tax: string;
    shipping_cost_inc_tax: string;
    currency_code: string;
    cart_id: string;
    coupon_discount: string;
    products: OrderedProduct[];
}

export interface OrderedProduct {
    id: number;
    product_id: number;
    sku: string;
    name: string;
    variant_id: number;
    type: OrderedProductType;
    price_inc_tax: string;
    quantity: number;
    product_options: OrderedProductOption[];
}

export interface OrderedProductOption {
    display_name: string;
    display_value: string;
}

export enum OrderedProductType {
    PHYSICAL = "physical",
    DIGITAL = "digital",
}

export enum OrdersQueryKeys {
    ORDER = "order",
}
