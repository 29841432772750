import React, { PropsWithChildren, useEffect } from "react";
import { Pages } from "../../types/navigation";
import AuthErrorScreen from "../AuthErrorScreen";
import { useCustomer } from "../../hooks/customer/useCustomer";
import { init, onAuthStateChanged } from "../../services/firebaseService";
import { trackLoginEvent } from "../../services/tracking";
import { usePageUtils } from "../../hooks/utils/usePageUtils";

function AuthProvider(props: PropsWithChildren<unknown>) {
    const { isError } = useCustomer();
    const { pageIs } = usePageUtils();

    useEffect(() => {
        const { auth } = init();

        return onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                trackLoginEvent(firebaseUser.uid);
            }
        });
    }, []);

    if (isError && !pageIs(Pages.ORDER_CONFIRMATION)) {
        return <AuthErrorScreen />;
    }

    return <>{props.children}</>;
}

export default AuthProvider;
