import React from "react";
import DOMPurify from "isomorphic-dompurify";
import { Product } from "../../types/products";
import Modal, { ModalProps } from "../Modal/Modal";
import { productName } from "../../utils/productName";
import ModalSection from "../Modal/Section";
import ModalHeader from "../Modal/Header";
import Price from "../Price";
import Button from "../Buttons/Button";

interface Props extends Partial<Omit<ModalProps, "onClose" | "children" | "isOpen">> {
    closeModal: () => void;
    product: Product;
    isInCart?: boolean;
    onAddToCart?: () => void;
    addToCartText?: string;
}

function AccessoryModal({ closeModal, product, addToCartText, onAddToCart, isInCart, ...rest }: Props) {
    return (
        <Modal isOpen={true} onClose={() => closeModal()} {...rest}>
            <ModalHeader>{productName(product)}</ModalHeader>
            <div className="sm:flex">
                {product.images.length > 0 && (
                    <ModalSection className="flex sm:w-1/3">
                        <img
                            className="w-1/2 sm:w-full h-full object-contain"
                            src={product.images[0].url_standard}
                            alt={product.name}
                        />
                    </ModalSection>
                )}
                <ModalSection className="flex flex-col flex-1">
                    <div
                        className="overflow-y-auto wysiwyg-enabled flex-grow"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description) }}
                    />
                    <h3 className="text-lg font-bold my-4">
                        <Price product={product} format="{price}" />
                    </h3>
                </ModalSection>
            </div>
            {!isInCart && !!addToCartText && !!onAddToCart && (
                <ModalSection>
                    <Button
                        data-testid="add-to-cart-modal"
                        aria-label={`Add ${product.name.toLowerCase()} to order`}
                        onClick={() => {
                            onAddToCart();
                            closeModal();
                        }}
                        className="w-full"
                    >
                        {addToCartText}
                    </Button>
                </ModalSection>
            )}
        </Modal>
    );
}

export default AccessoryModal;
