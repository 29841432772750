import React from "react";

interface Props {
    text: string;
}

export default function CardHighlightedHeader({ text, ...rest }: Props) {
    return (
        <span
            className="absolute bottom-full block w-full py-0.5 bg-green-500 rounded-t-lg uppercase text-center text-white font-bold ring-4 ring-green-500"
            {...rest}
        >
            {text}
        </span>
    );
}
