import React, { FC } from "react";
import Divider from "../Layout/Divider";
import { getAccessoryLineItems, getAdditionalServiceLineItems, getAllChargerLineItems } from "../../utils/cart/cart";
import useCartReviewPageContent from "../../hooks/Prismic/useCartReviewPageContent";
import Card from "../Card/Card";
import useChargers from "../../hooks/BigCommerce/useChargers";
import { LineItem } from "../../types/cart";
import AccessoryCartRow from "./AccessoryCartRow";
import ChargerCartRow, { CartRowVariant } from "./ChargerCartRow";

interface CartItemLinesProps {
    rowVariant?: CartRowVariant;
    lineItems: LineItem[];
}

export const CartItemLines: FC<CartItemLinesProps> = ({ rowVariant = CartRowVariant.STANDARD, lineItems }) => {
    const { reviewContent } = useCartReviewPageContent();
    const chargers = useChargers();

    const chargerLineItems = getAllChargerLineItems(lineItems, chargers);
    const accessoryLineItems = [...getAccessoryLineItems(lineItems), ...getAdditionalServiceLineItems(lineItems)];

    return (
        <Card className="flex flex-col space-y-4 overflow-auto p-2">
            {chargerLineItems.map((lineItem) => {
                return (
                    <ChargerCartRow
                        lineItem={lineItem}
                        pageContent={reviewContent}
                        rowVariant={rowVariant}
                        allowRemoval={chargerLineItems.length > 1}
                        key={lineItem.id}
                    />
                );
            })}

            <Divider className="mx-2" />

            {accessoryLineItems.map((lineItem) => {
                return (
                    <AccessoryCartRow
                        key={lineItem.id}
                        lineItem={lineItem}
                        content={reviewContent}
                        rowVariant={rowVariant}
                    />
                );
            })}
        </Card>
    );
};
