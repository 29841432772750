/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@fontsource-variable/quicksand";
import "./src/assets/styles/global.css";
import React from "react";
import { initTracking } from "./src/services/tracking";
import { WrapProvider } from "./src/components/HigherOrderComponents/WrapProvider";
import { initClaritySession } from "./src/utils/clarity";
import { startSession } from "./src/utils/sentry";
import { useLocationTracking } from "./src/hooks/useLocationTracking";

export const wrapRootElement = ({ element }) => {
    return <WrapProvider>{element}</WrapProvider>;
};

export const wrapPageElement = ({ props, element }) => {
    useLocationTracking(props.location);

    return element;
};

export const onClientEntry = () => {
    initTracking();
    initClaritySession(startSession());

    // Replace double forward slashes with single
    if (typeof window !== "undefined" && window.location.pathname.includes("//")) {
        window.location.replace(window.location.pathname.replace(/\/{2,}/g, "/"));
    }
};
