import { useStaticQuery, graphql } from "gatsby";
import { Product } from "../../types/products";

const useProducts = (): Product[] => {
    const { products } = useStaticQuery(
        graphql`
            query {
                products {
                    data {
                        id
                        sku
                        categories
                        name
                        price
                        sale_price
                        calculated_price
                        type
                        description
                        categories
                        sort_order
                        related_products
                        order_quantity_minimum
                        order_quantity_maximum
                        images {
                            id
                            product_id
                            description
                            is_thumbnail
                            sort_order
                            url_standard
                            url_thumbnail
                            url_tiny
                            url_zoom
                            image_cart {
                                gatsbyImageData {
                                    width
                                    height
                                    images {
                                        fallback {
                                            sizes
                                            src
                                            srcSet
                                        }
                                    }
                                    layout
                                }
                            }
                            image_thumbnail {
                                gatsbyImageData {
                                    width
                                    height
                                    images {
                                        fallback {
                                            sizes
                                            src
                                            srcSet
                                        }
                                    }
                                    layout
                                }
                            }
                        }
                        variants {
                            id
                            sku
                            option_values {
                                id
                                label
                                option_display_name
                                option_id
                            }
                            price
                            sale_price
                            calculated_price
                            image_url
                        }
                        custom_fields {
                            name
                            value
                        }
                    }
                }
            }
        `
    );

    return products.data;
};

export default useProducts;
