import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import Icon, { Icons } from "../Icons/Icon";
import { Maybe } from "../../types/generics";
import { useStatefulLocalStorage } from "../../hooks/useStatefulLocalStorage";

interface BannerProps extends HTMLAttributes<HTMLDivElement> {
    hash: string;
    icon: Maybe<string>;
    dismissible: Maybe<boolean>;
    image: Maybe<{ url: Maybe<string>; alt: Maybe<string> }>;
    alertColor: Maybe<string>;
}

export enum BannerColors {
    GREEN = "Green",
    AMBER = "Amber",
    RED = "Red",
    LIGHT_BLUE = "Light Blue",
    PURPLE = "Purple",
    DARK_GREY = "Dark Grey",
}

const colorClasses = new Map<BannerColors, string>([
    [BannerColors.GREEN, "bg-green-50 text-green-800"],
    [BannerColors.AMBER, "bg-yellow-200 text-yellow-500"],
    [BannerColors.RED, "bg-red-200 text-red-500"],
    [BannerColors.LIGHT_BLUE, "bg-blue-100 text-blue-500"],
    [BannerColors.PURPLE, "bg-purple-200 text-purple-800"],
    [BannerColors.DARK_GREY, "bg-gray-600 text-white"],
]);

const getIcon = (icon: string) => (icon === "info" ? Icons.INFO_LIGHT : Icons.WARNING_CIRCLE_LIGHT);

const Banner = ({ className, hash, style, icon, children, dismissible, image, alertColor }: BannerProps) => {
    const [isDismissed, setIsDismissed] = useStatefulLocalStorage(`marketing_banner.${hash}`, false);

    return (
        <>
            {!isDismissed && (
                <div
                    className={classNames(
                        "relative flex flex-row py-3 gap-2",
                        alertColor && colorClasses.get(alertColor as BannerColors),
                        className
                    )}
                    style={style}
                >
                    {icon && (
                        <div className="px-4 content-start">
                            <Icon icon={getIcon(icon)} className="font-bold" />
                        </div>
                    )}
                    <div className="basis-auto flex flex-row gap-2 break-normal text-left prose-a:underline">
                        {children}
                        {image?.url && (
                            <div className="basis-2/12 mr-2 ml-2 hidden md:block">
                                <img src={image.url || ""} alt={image.alt || ""} />
                            </div>
                        )}
                    </div>
                    {dismissible && (
                        <a
                            className="px-2 cursor-pointer text-2xl flex-grow text-right"
                            onClick={() => {
                                setIsDismissed(true);
                            }}
                        >
                            <Icon icon={Icons.CLOSE} className="font-bold" title="Close" />
                        </a>
                    )}
                </div>
            )}
        </>
    );
};

export default Banner;
