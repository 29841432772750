import { graphql, useStaticQuery } from "gatsby";
import { ProductPriceGroup } from "../../types/products";

const useDefaultProductsPricing = () => {
    const { defaultProductsPricing } = useStaticQuery<Queries.DefaultProductsPricingListQuery>(
        graphql`
            query DefaultProductsPricingList {
                defaultProductsPricing {
                    data {
                        ...DefaultProductsPricing
                    }
                }
            }
        `
    );

    return (defaultProductsPricing?.data ?? []) as unknown as ProductPriceGroup[];
};

export default useDefaultProductsPricing;
