import React from "react";
import classNames from "classnames";

type Props = React.HTMLAttributes<HTMLDivElement> & Required<Pick<React.HTMLAttributes<HTMLDivElement>, "children">>;

function CardSection({ className, ...rest }: Props) {
    return <div className={classNames("p-2", className)} {...rest} />;
}

export default CardSection;
