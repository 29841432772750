import React, { ReactNode } from "react";

interface Props {
    title: string;
    description?: string;
    children?: ReactNode;
}

function PageHeader({ title, description, children }: Props) {
    return (
        <header className="p-4">
            <h1 className="text-2xl font-bold">{title}</h1>
            {description && <p className="mt-4">{description}</p>}
            {children}
        </header>
    );
}

export default PageHeader;
