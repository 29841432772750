export enum AuthStorage {
    ACCESS_TOKEN = "auth.token",
    ACCESS_TOKEN_EXPIRY = "auth.token.expiry",
    MFA_CHALLENGE = "auth.mfa.challenge",
    MAGIC_LINK_EMAIL = "auth.magic_link.email",
}

export interface AccessToken {
    access_token: string;
    expires_at: string;
}

export enum AuthStatus {
    SUCCESS = "auth.success",
    REQUIRES_MFA = "auth.requires_mfa",
    INVALID_MAGIC_LINK = "auth.invalid_magic_link",
}

export interface MultiFactorChallenge {
    verificationId: string;
    factor: string;
    email: string;
}
