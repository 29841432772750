import { CustomFieldName, Product } from "../../types/products";
import { getChargersProductCategory } from "../../store/ephemeral/products";
import { Maybe } from "../../types/generics";
import { getAvailableProducts } from "./getAvailableProducts";

const sortProductBySortOrderAsc = (a: Product, b: Product) => a.sort_order - b.sort_order;

/**
 * This will retrieve all of the Products within the Chargers category available for a given
 * Customer Group through the existence of non-empty Price List Records associated to that
 * Customer Group. Not only this could filter out Chargers which should not be purchasable
 * (with an empty price) but it will also filter out Charger Variants which could be set with
 * empty prices too. Prices set to 0 will remain and returned as they will be advertised
 * as "free" at render time.
 */
const getChargers = (customerGroupId: number): Product[] => {
    const products = getAvailableProducts(customerGroupId);
    const chargerCategory = getChargersProductCategory();

    return products
        .filter((product: Product) => product.categories.includes(chargerCategory.category_id))
        .sort(sortProductBySortOrderAsc);
};

export const getChargersByConnectorAndCustomerGroup = (
    customerGroupId: number,
    connector?: Maybe<string>
): Product[] => {
    const chargers = getChargers(customerGroupId);

    if (!connector) {
        return chargers;
    }

    return chargers.filter((product) => {
        let match = false;

        product.custom_fields.forEach((customField) => {
            if (customField.name === CustomFieldName.CONNECTOR && customField.value === connector) {
                match = true;
            }
        });

        return match;
    });
};
