import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { StatusCodes } from "http-status-codes";
import {
    AddCartLineItemsRequest,
    Cart,
    CreateCartRequest,
    UpdateCartLineItemRequest,
    UpdateCartRequest,
} from "../../types/cart";
import {
    CreateCustomerAddressRequest,
    CreateCustomerRequest,
    Customer,
    CustomerAddress,
    UpdateCustomerAddressRequest,
    UpdateCustomerRequest,
    UpsertCustomerAttributesRequest,
} from "../../types/customer";
import { CustomerAttributeValue } from "../../types/customerAttributes";
import { Order, OrderedProduct } from "../../types/orders";
import { GetProductPricesRequest, ProductPrice } from "../../types/products";
import { Checkout } from "../../types/checkout";
import { BillingAddressPutBody, ConsignmentAddressPutBody } from "../../utils/api/checkouts";
import {
    breadcrumbForAxiosError,
    breadcrumbForAxiosRequest,
    breadcrumbForAxiosResponse,
    getSessionId as getSentrySessionId,
    SENTRY_SESSION_ID_HEADER,
} from "../../utils/sentry";
import { createClient, ResponseEnvelope } from "./index";

export const proxy = createClient();

proxy.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    const { data, method, url, headers } = request;

    if (!url) {
        throw new Error("Invalid path");
    }

    const urlParts: string[] = url.split("/").filter((part) => part.length);
    const version = urlParts.shift();
    const path = urlParts.join("/");

    const proxyRequest = {
        ...request,
        headers,
        withCredentials: true,
        baseURL: process.env.GATSBY_BIG_COMMERCE_PROXY_API_URL,
        url: "/big-commerce",
        method: "POST",
        data: JSON.stringify({
            method: method?.toUpperCase(),
            payload: data,
            path,
            version,
        }),
    };

    headers.set(SENTRY_SESSION_ID_HEADER, getSentrySessionId());

    return breadcrumbForAxiosRequest(proxyRequest);
});

proxy.interceptors.response.use(
    (response: AxiosResponse) => {
        breadcrumbForAxiosResponse(response);

        return {
            ...response,
            data: response.data ? JSON.parse(response.data as string) : null,
        };
    },
    (error: AxiosError) => {
        breadcrumbForAxiosError(error);

        // Will allow 422 errors to be handled by preventing Axios to error out when submitting an invalid form.
        const status = error?.response?.status ?? error?.status ?? error.code;
        if ([StatusCodes.UNPROCESSABLE_ENTITY].includes(status as StatusCodes)) {
            return {
                ...error.response,
                data: error?.response?.data ? JSON.parse(error.response.data as string) : null,
            };
        }

        throw error;
    }
);

export const createCart = async (request: CreateCartRequest): Promise<AxiosResponse<ResponseEnvelope<Cart>>> => {
    return proxy.post("/v3/carts", request);
};

export const updateCart = async (
    cartId: string,
    request: UpdateCartRequest
): Promise<AxiosResponse<ResponseEnvelope<Cart>>> => {
    return proxy.put(`/v3/carts/${cartId}`, request);
};

export const fetchCart = async (cartId: string): Promise<AxiosResponse<ResponseEnvelope<Cart>>> => {
    return proxy.get(`/v3/carts/${cartId}?include=line_items.digital_items.options,line_items.physical_items.options`);
};

export const addCartLineItems = async (
    cartId: string,
    request: AddCartLineItemsRequest
): Promise<AxiosResponse<ResponseEnvelope<Cart>>> => {
    return proxy.post(`/v3/carts/${cartId}/items`, request);
};

export const updateCartLineItem = async (
    cartId: string,
    itemId: string,
    request: UpdateCartLineItemRequest
): Promise<AxiosResponse<ResponseEnvelope<Cart>>> => {
    return proxy.put(`/v3/carts/${cartId}/items/${itemId}`, request);
};

export const removeCartLineItem = async (
    cartId: string,
    itemId: string
): Promise<AxiosResponse<ResponseEnvelope<Cart>>> => {
    return proxy.delete(`/v3/carts/${cartId}/items/${itemId}`);
};

export const createCustomer = async (
    request: CreateCustomerRequest[]
): Promise<AxiosResponse<ResponseEnvelope<Customer[]>>> => {
    return proxy.post("/v3/customers?include=addresses,attributes", request);
};

export const updateCustomer = async (
    request: UpdateCustomerRequest[]
): Promise<AxiosResponse<ResponseEnvelope<Customer[]>>> => {
    return proxy.put(`/v3/customers?include=addresses,attributes`, request);
};

export const fetchCustomer = async (customerId: number): Promise<AxiosResponse<ResponseEnvelope<Customer[]>>> => {
    return proxy.get(`/v3/customers?id:in=${customerId}&include=addresses,attributes`);
};

export const createCustomerAddress = async (
    request: CreateCustomerAddressRequest[]
): Promise<AxiosResponse<ResponseEnvelope<CustomerAddress[]>>> => {
    return proxy.post("/v3/customers/addresses", request);
};

export const updateCustomerAddress = async (
    request: UpdateCustomerAddressRequest[]
): Promise<AxiosResponse<ResponseEnvelope<CustomerAddress[]>>> => {
    return proxy.put(`/v3/customers/addresses`, request);
};

export const fetchCustomerAddress = async (
    customerId: number
): Promise<AxiosResponse<ResponseEnvelope<Customer[]>>> => {
    return proxy.get(`/v3/customers/addresses?customer_id:in=${customerId}`);
};

export const upsertCustomerAttributes = async (
    request: UpsertCustomerAttributesRequest[]
): Promise<AxiosResponse<ResponseEnvelope<CustomerAttributeValue[]>>> => {
    return proxy.put(`/v3/customers/attribute-values`, request);
};

/** @note v2: Envelope-less response */
export const fetchOrderById = async (orderId: number, customerId: number): Promise<AxiosResponse<Order[] | string>> =>
    proxy.get(`/v2/orders?customer_id=${customerId}&min_id=${orderId}&max_id=${orderId}`);

/** @note v2: Envelope-less response */
export const fetchOrderedProducts = async (
    orderId: number,
    customerId: number
): Promise<AxiosResponse<OrderedProduct[]>> => {
    return proxy.get(`/v2/orders/${orderId}/products?customer_id=${customerId}`);
};

export const fetchProductPrices = async (
    request: GetProductPricesRequest
): Promise<AxiosResponse<ResponseEnvelope<ProductPrice[]>>> => {
    return proxy.post(`/v3/pricing/products`, request);
};

export const fetchCheckout = async (checkoutId: string): Promise<AxiosResponse<ResponseEnvelope<Checkout>>> => {
    return proxy.get(`/v3/checkouts/${checkoutId}`);
};

export const updateBillingAddress = async (
    checkoutId: string,
    addressId: string,
    request: BillingAddressPutBody
): Promise<AxiosResponse<ResponseEnvelope<Checkout>>> => {
    return proxy.put(`/v3/checkouts/${checkoutId}/billing-address/${addressId}`, request);
};

export const updateConsignment = async (
    checkoutId: string,
    consignmentId: string,
    request: ConsignmentAddressPutBody
): Promise<AxiosResponse<ResponseEnvelope<Checkout>>> => {
    return proxy.put(`/v3/checkouts/${checkoutId}/consignments/${consignmentId}`, request);
};
