import React from "react";
import Alert, { AlertType } from "../Alerts/Alert";
import useCartConfig from "../../hooks/useCartConfig";

export const MultipleChargersAlert = () => {
    const cartConfigData = useCartConfig();
    const alertType = cartConfigData.multiple_chargers_alert_type as AlertType;
    const alertContent = cartConfigData.multiple_chargers_alert_content.richText;

    return <Alert type={alertType} richText={alertContent} />;
};
