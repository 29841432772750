import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MarketingBanners from "./MarketingBanners";
import ConfirmCountryModal from "./ConfirmCountryModal";

interface Props {
    children: React.ReactNode;
    header?: boolean;
    footer?: boolean;
}

function Layout({ children, header = true, footer = true }: Props) {
    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <MarketingBanners types={["header"]} className="font-extrabold text-center" />
            <ConfirmCountryModal />
            {header && <Header />}
            <section className="flex sm:container w-full flex-1">
                <main className="flex-1 px-4 overflow-x-hidden">{children}</main>
            </section>
            {footer && <Footer />}
        </div>
    );
}

export default Layout;
