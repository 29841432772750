import { CustomerAttributeValue } from "../../types/customerAttributes";

export const mergeAttributes = (
    existingAttributes: CustomerAttributeValue[],
    newAttributes: CustomerAttributeValue[]
) => {
    const newAttributeIDs = newAttributes.map((newAttribute) => newAttribute.attribute_id);

    return [
        ...existingAttributes.filter((existingAttribute) => !newAttributeIDs.includes(existingAttribute.attribute_id)),
        ...newAttributes,
    ];
};
