import { Origin, OriginChannelCategory, OriginSourceEnvironment, OriginType } from "../../types/origin";
import { CustomerAttributeName, CustomerAttributeValue } from "../../types/customerAttributes";
import { getCustomerAttributeValueByName } from "../customer/getCustomerAttributeValueByName";

export const getOriginFromCustomerAttributes = (attributes?: CustomerAttributeValue[], origin?: Origin) => {
    if (!attributes) {
        return origin;
    }

    const sourceAttribute = getCustomerAttributeValueByName(CustomerAttributeName.SOURCE_ENVIRONMENT, attributes);
    const channelAttribute = getCustomerAttributeValueByName(CustomerAttributeName.SOURCE_CHANNEL_CATEGORY, attributes);
    const partnerAttribute = getCustomerAttributeValueByName(CustomerAttributeName.PARTNER, attributes);
    const systemIdAttribute = getCustomerAttributeValueByName(CustomerAttributeName.ORIGIN_SYSTEM_ID, attributes);

    const sourceEnvironmentValue = sourceAttribute?.attribute_value as OriginSourceEnvironment;
    const sourceChannelCategoryValue = channelAttribute?.attribute_value as OriginChannelCategory;
    const partnerAttributeValue = partnerAttribute?.attribute_value;
    const systemIdAttributeValue = systemIdAttribute?.attribute_value;

    const sourceEnvironment = sourceEnvironmentValue ?? OriginSourceEnvironment.STOREFRONT;
    const sourceChannelCategory = sourceChannelCategoryValue ?? OriginChannelCategory.WEB;
    const type = sourceEnvironment === OriginSourceEnvironment.STOREFRONT ? OriginType.ORGANIC : OriginType.REFERRAL;
    const partner = partnerAttributeValue ?? null;
    const systemId = systemIdAttributeValue ?? null;

    return {
        partner,
        sourceEnvironment,
        sourceChannelCategory,
        type,
        systemId,
    };
};
