import {
    InstallationAnswer,
    InstallationCableLength,
    InstallationDigRequired,
    InstallationQuestions,
    InstallationSelection,
    InstallationTruckingLength,
    InstallationType,
} from "../../types/installation";
import { Optional, Maybe } from "../../types/generics";

export const installationAnswers = {
    [InstallationQuestions.CABLE_LENGTH]: {
        [InstallationType.STANDARD]: InstallationCableLength.SHORT,
        [InstallationType.NON_STANDARD]: InstallationCableLength.LONG,
    },
    [InstallationQuestions.TRUCKING_LENGTH]: {
        [InstallationType.STANDARD]: InstallationTruckingLength.SHORT,
        [InstallationType.NON_STANDARD]: InstallationTruckingLength.LONG,
    },
    [InstallationQuestions.DIG_REQUIRED]: {
        [InstallationType.STANDARD]: InstallationDigRequired.NO,
        [InstallationType.NON_STANDARD]: InstallationDigRequired.YES,
    },
} as const;

const getResult = (value: Optional<Maybe<InstallationAnswer>>, question: InstallationQuestions) => {
    if (!value) {
        return null;
    } else if (value === installationAnswers[question][InstallationType.STANDARD]) {
        return InstallationType.STANDARD;
    } else {
        return InstallationType.NON_STANDARD;
    }
};

export const getInstallationQuestionResults = ({ cableLength, truckingLength, digRequired }: InstallationSelection) => {
    {
        return {
            [InstallationQuestions.CABLE_LENGTH]: getResult(cableLength, InstallationQuestions.CABLE_LENGTH),
            [InstallationQuestions.TRUCKING_LENGTH]: getResult(truckingLength, InstallationQuestions.TRUCKING_LENGTH),
            [InstallationQuestions.DIG_REQUIRED]: getResult(digRequired, InstallationQuestions.DIG_REQUIRED),
        };
    }
};
