import React, { FC } from "react";
import { Image } from "../types/products";

const sizeMap = [
    { name: "tiny", width: 44 },
    { name: "thumbnail", width: 220 },
    { name: "standard", width: 386 },
    { name: "zoom", width: 1200 },
];
interface BigCommerceImageProps {
    image: Image;
    loading?: "eager" | "lazy";
    size: "tiny" | "thumbnail" | "standard" | "zoom";
    testId?: string;
}

export const BigCommerceImage: FC<BigCommerceImageProps> = ({ image, loading, size, testId, ...props }) => {
    const width = sizeMap.find((s) => s.name === size)?.width as number;
    const srcSet = sizeMap
        .filter((s) => s.width <= width)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .map((s) => `${image[`url_${s.name}`]} ${s.width}w`)
        .join(",\n");

    return (
        <img
            {...props}
            alt={image.description}
            className="h-full object-cover rounded-lg aspect-auto"
            data-testid={testId}
            decoding="async"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority={loading === "eager" ? "high" : "low"}
            loading={loading}
            onDragStart={(e) => e.preventDefault()}
            sizes={`(min-width: ${width}px) ${width}px, 100vw`}
            src={image.url_standard}
            srcSet={srcSet}
        />
    );
};
