import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import useHeaderConfig from "../../hooks/useHeaderConfig";
import { Cart } from "../Cart/Cart";
import { trackNavbarClick } from "../../services/tracking";
import useLocation from "../../hooks/useLocation";
import { usePageResolver } from "../../hooks/usePageResolver";
import Menu from "./Header/Menu";
import ProgressBar from "./Header/ProgressBar";

function Header() {
    const location = useLocation();
    const headerConfigData = useHeaderConfig();
    const { isFlowPage, isHomePage } = usePageResolver();

    const showProgressBar = !isHomePage(location) && isFlowPage(location);
    const logo = getImage(headerConfigData?.logo ?? null) as IGatsbyImageData;

    return (
        <header
            className="grid grid-cols-[1fr,minmax(200px,1fr)] items-center p-4 sm:container w-full sticky top-0 bg-gray-100 z-20"
            id="navbar-header"
        >
            <div>
                {logo && (
                    <a
                        aria-label="pod-point-logo"
                        className="inline-block"
                        href={headerConfigData?.header_logo_link?.url || ""}
                        onClick={() => trackNavbarClick("Header", headerConfigData?.logo?.alt || "Pod Point logo")}
                    >
                        <GatsbyImage
                            data-testid="logo"
                            image={logo}
                            alt={headerConfigData?.logo?.alt ?? "Pod Point logo"}
                            loading="eager"
                        />
                    </a>
                )}
            </div>
            <div className="relative px-4">
                <div className="flex justify-end">
                    <Menu />
                    <Cart />
                </div>
            </div>
            {showProgressBar ? <ProgressBar /> : <></>}
        </header>
    );
}

export default Header;
