import { Axios, AxiosHeaders } from "axios";

export interface ResponseEnvelope<T> {
    data: T;
    meta: {
        pagination?: {
            total: number;
            count: number;
            per_page: number;
            current_page: number;
            total_pages: number;
        };
    };
    status: number;
}

export function createClient() {
    return new Axios({
        baseURL: `https://api.bigcommerce.com/stores/${process.env.GATSBY_BIGCOMMERCE_STORE_HASH}`,
        headers: new AxiosHeaders({
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        validateStatus: (status) => status >= 200 && status < 300,
    });
}
