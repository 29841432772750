import { LineItem } from "../../types/cart";
import { getAvailableProducts } from "./getAvailableProducts";

/**
 * For a given Customer Group, we get all products associated with it.
 * Then we check if the supplied line item exists within the returned products,
 * matching on base product ID and also variant ID.
 */
export const isLineItemInPricelist = (customerGroupId: number, targetLineItem: LineItem): boolean => {
    const priceListProducts = getAvailableProducts(customerGroupId);

    return priceListProducts.some(
        (product) =>
            product.id === targetLineItem.product_id &&
            product.variants.some((variant) => variant.id === targetLineItem.variant_id)
    );
};
