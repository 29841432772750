import { Product } from "../../types/products";
import {
    getAccessoryCategory,
    getAdditionalServicesCategory,
    getChargersProductCategory,
    getProducts as getStoredProducts,
} from "../../store/ephemeral/products";
import { Optional } from "../../types/generics";

export enum ProductType {
    CHARGER = "Charger",
    ACCESSORY = "Accessory",
    ADDITIONAL_SERVICE = "AdditionalService",
}

const isCharger = (productId: Product["id"]) =>
    getStoredProducts()
        .find((product) => product.id === productId)
        ?.categories.includes(getChargersProductCategory().category_id);

const isAccessory = (productId: Product["id"]) =>
    getStoredProducts()
        .find((product) => product.id === productId)
        ?.categories.includes(getAccessoryCategory().category_id);

const isAdditionalService = (productId: Product["id"]) =>
    getStoredProducts()
        .find((product) => product.id === productId)
        ?.categories.includes(getAdditionalServicesCategory().category_id);

export const getProductType = (productId: Optional<Product["id"]>): Optional<ProductType> => {
    if (!productId) return undefined;

    return isCharger(productId)
        ? ProductType.CHARGER
        : isAccessory(productId)
        ? ProductType.ACCESSORY
        : isAdditionalService(productId)
        ? ProductType.ADDITIONAL_SERVICE
        : undefined;
};
