import { Customer } from "../../types/customer";
import { CustomerAttributeName, MappedCustomerAttributeValue } from "../../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";
import { getCustomerAttributeValueByName } from "./getCustomerAttributeValueByName";

export const getSendWelcomeCustomerAttributes = (customer: Customer): MappedCustomerAttributeValue[] => {
    const sendWelcomeAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.SEND_WELCOME);
    const sendWelcomeValue = getCustomerAttributeValueByName(CustomerAttributeName.SEND_WELCOME, customer.attributes);

    return [
        {
            attribute_id: sendWelcomeAttributeId,
            value: sendWelcomeValue ? sendWelcomeValue.attribute_value : "yes",
        },
    ];
};
