import { useQuery } from "@tanstack/react-query";
import { StatusCodes } from "http-status-codes";
import { fetchCustomer } from "../services/bigCommerce/proxy";
import { useUser } from "../hooks/useUser";
import { useCustomerStorage } from "../hooks/useCustomerStorage";
import { logout } from "../services/auth";
import { Pages } from "../types/navigation";
import useNavigationPages from "../hooks/useNavigationPages";

export const CUSTOMER_QUERY_KEY = "customer";

export const useFetchCustomer = () => {
    const { data: user, isAuthReady } = useUser();
    const { navigateTo } = useNavigationPages();
    const { getCurrentCustomer, setCurrentCustomer } = useCustomerStorage();
    const customerId = user?.big_commerce_id ?? getCurrentCustomer()?.id ?? 0;
    const isEnabled = !!user || customerId > 0;

    return {
        isEnabled,
        isAuthReady,
        user,
        ...useQuery({
            queryKey: [CUSTOMER_QUERY_KEY, customerId],
            queryFn: async () => {
                if (!user && customerId <= 0) {
                    return null;
                }

                try {
                    const response = await fetchCustomer(customerId);
                    const customer = response.data.data[0];

                    setCurrentCustomer({
                        address_count: customer.address_count ?? 0,
                        attribute_count: customer.attribute_count ?? 0,
                        customer_group_id: customer.customer_group_id,
                        email: customer.email,
                        id: customer.id,
                    });

                    return customer;
                } catch (error: any) {
                    const status = error?.response?.status ?? error?.status ?? error.code;

                    if ([StatusCodes.UNAUTHORIZED, StatusCodes.FORBIDDEN].includes(status)) {
                        await logout();
                        await navigateTo(Pages.LOGIN);

                        return null;
                    }

                    throw error;
                }
            },
            enabled: isEnabled,
            staleTime: Infinity,
        }),
    };
};
