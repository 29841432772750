import { AuthStorage } from "../types/auth";
import { CartStorage } from "../types/cart";
import { CustomerStorage } from "../types/customer";
import { VehicleStorage } from "../types/vehicle";
import { PropertyStorage } from "../types/property";
import { InstallationStorage } from "../types/installation";
import { NavigationStorage } from "../types/navigation";
import { ConsentStorage } from "../types/consent";
import { OriginStorage } from "../types/origin";

export const clearStorage = () => {
    if (typeof window !== "undefined") {
        localStorage.removeItem(AuthStorage.ACCESS_TOKEN);
        localStorage.removeItem(AuthStorage.ACCESS_TOKEN_EXPIRY);
        localStorage.removeItem(AuthStorage.MAGIC_LINK_EMAIL);
        localStorage.removeItem(AuthStorage.MFA_CHALLENGE);
        localStorage.removeItem(CartStorage.CART_ID);
        localStorage.removeItem(CartStorage.SELECTED_VARIANT_ID);
        localStorage.removeItem(ConsentStorage.MARKETING_CONSENT_SELECTION);
        localStorage.removeItem(CustomerStorage.CURRENT);
        localStorage.removeItem(InstallationStorage.SELECTION);
        localStorage.removeItem(NavigationStorage.PAGE);
        localStorage.removeItem(OriginStorage.SELECTION);
        localStorage.removeItem(PropertyStorage.SELECTION);
        localStorage.removeItem(VehicleStorage.SELECTION);
    }
};
