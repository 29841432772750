import { PriceList, PriceListAssignment, PriceListRecord } from "../../types/price";
import { getItem, storeItem } from "./index";

const PRICE_LISTS = "price_lists.entries";
const PRICE_LIST_ASSIGNMENTS = "price_list.assignments";
const PRICE_LIST_RECORDS = "price_list.records";

export const setPriceLists = (lists: PriceList[]) => storeItem(PRICE_LISTS, lists);
export const setPriceListAssignments = (listAssignments: PriceListAssignment[]) =>
    storeItem(PRICE_LIST_ASSIGNMENTS, listAssignments);
export const setPriceListRecords = (listRecords: PriceListRecord[]) => storeItem(PRICE_LIST_RECORDS, listRecords);

export const getPriceLists = () => getItem<PriceList[]>(PRICE_LISTS, []);
export const getPriceListAssignments = () => getItem<PriceListAssignment[]>(PRICE_LIST_ASSIGNMENTS, []);
export const getPriceListRecords = () => getItem<PriceListRecord[]>(PRICE_LIST_RECORDS, []);
