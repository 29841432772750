import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomerAttributeName } from "../../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";
import * as bigCommerce from "../../services/bigCommerce/proxy";
import { Cart, CartQueryKey } from "../../types/cart";
import { Customer } from "../../types/customer";
import { getCustomerAttributeValueByName } from "../../utils/customer/getCustomerAttributeValueByName";
import { Maybe } from "../../types/generics";

export const useAssociateCartWithCustomer = () => {
    const queryClient = useQueryClient();
    const customerCartAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CART_ID);

    return useMutation<any, any, { cart?: Maybe<Cart>; customer?: Maybe<Customer> }>({
        mutationKey: ["associateCartWithCustomer"],
        mutationFn: async ({ cart, customer }) => {
            if (!cart || !customer) {
                return;
            }

            const customerCartAttributeValue = getCustomerAttributeValueByName(
                CustomerAttributeName.CART_ID,
                customer?.attributes
            );

            if (customerCartAttributeValue?.attribute_value !== cart.id) {
                await bigCommerce.upsertCustomerAttributes([
                    {
                        attribute_id: customerCartAttributeId,
                        customer_id: customer.id,
                        value: cart.id,
                    },
                ]);
            }

            if (cart.customer_id !== customer.id) {
                const response = await bigCommerce.updateCart(cart.id, { customer_id: customer.id });
                queryClient.setQueryData([CartQueryKey.FETCH, response.data.data.id], response.data.data);
            }
        },
    });
};
