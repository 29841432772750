import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StatusCodes } from "http-status-codes";
import { AxiosError } from "axios";
import { updateCustomer } from "../services/bigCommerce/proxy";
import { Customer, UpdateCustomerRequest } from "../types/customer";
import { OriginType } from "../types/origin";
import { useOriginStorage } from "../hooks/useOriginStorage";
import { getCustomerGroupId } from "../utils/customer/customerGroups";
import { useAffiliateTrackingStorage } from "../hooks/useAffiliateTrackingStorage";
import { Maybe } from "../types/generics";
import { CUSTOMER_QUERY_KEY } from "./useFetchCustomer";

export const useUpdateCustomer = () => {
    const queryClient = useQueryClient();
    const { getOrigin } = useOriginStorage();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();

    return useMutation<Customer, AxiosError, UpdateCustomerRequest>({
        mutationFn: async (request) => {
            const customer = queryClient.getQueryData<Maybe<Customer>>([CUSTOMER_QUERY_KEY, request.id]);
            const response = await updateCustomer([
                {
                    ...request,
                    customer_group_id: getCustomerGroupId(customer, getPartner(), getPublisher()),
                    accepts_product_review_abandoned_cart_emails:
                        !getOrigin() || getOrigin().type == OriginType.ORGANIC,
                },
            ]);

            if (response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
                throw new AxiosError(
                    "Unprocessable entity",
                    `${StatusCodes.UNPROCESSABLE_ENTITY}`,
                    undefined,
                    undefined,
                    response
                );
            }

            return {
                ...customer,
                ...response.data.data[0],
            };
        },
        onSuccess: (customer) => {
            queryClient.setQueryData([CUSTOMER_QUERY_KEY, customer.id], customer);
        },
    });
};
