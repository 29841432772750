import React from "react";
import { usePriceFormatting } from "../services/PriceFormat";
import { Product, Variant } from "../types/products";
import { useProductPrice } from "../hooks/useProductPrice";
import { useFetchCustomer } from "../queries/useFetchCustomer";
import { getCustomerGroupId } from "../utils/customer/customerGroups";
import { Maybe } from "../types/generics";
import { kebabCase } from "../utils/str/case";
import { productName } from "../utils/productName";
import { useAffiliateTrackingStorage } from "../hooks/useAffiliateTrackingStorage";
import { getPowerRatingOptionValue } from "../utils/products/getPowerRatingOptionValue";

interface Props {
    product: Product;
    format?: string;
    decimals?: boolean;
    clipTrailingZeros?: boolean;
    quantity?: number;
    variant?: Maybe<Variant>;
}

const Price = ({
    product,
    format = "{price}",
    decimals = true,
    clipTrailingZeros = false,
    quantity = 1,
    variant = null,
}: Props) => {
    const { data: customer } = useFetchCustomer();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();
    const customerGroupId = getCustomerGroupId(customer, getPartner(), getPublisher());
    const price = useProductPrice(customerGroupId, product.id, variant?.id);
    const { formatPrice } = usePriceFormatting();

    const powerRatingOptionValue = getPowerRatingOptionValue(product, variant ?? undefined);
    const testId = powerRatingOptionValue
        ? `${kebabCase(productName(product))}-${powerRatingOptionValue.label.toLowerCase()}`
        : kebabCase(productName(product));

    /** @todo Remove this functionality once we use the new useFromPrice() hook */
    /** @see https://github.com/Pod-Point/ecommerce-web-app/pull/1787 */
    if (price?.from) {
        return (
            <span data-testid={`${testId}-from-price`}>
                {formatPrice(price.from * quantity, { decimals: false, format })}
            </span>
        );
    }

    if (price?.is_discounted) {
        return (
            <span className="flex flex-col">
                <span className="line-through font-extrabold" data-testid={`${testId}-retail-price`}>
                    {formatPrice(price.retail_price * quantity, {
                        format: "{price}",
                        decimals,
                        clipTrailingZeros,
                    })}
                </span>
                <span className="text-green-500 font-extrabold" data-testid={`${testId}-calculated-price`}>
                    {formatPrice(price.calculated_price * quantity, {
                        format,
                        decimals,
                        clipTrailingZeros,
                    })}
                </span>
            </span>
        );
    }

    return (
        <span data-testid={`${testId}-price`}>
            {price ? formatPrice(price.calculated_price * quantity, { format, decimals, clipTrailingZeros }) : null}
        </span>
    );
};

export default Price;
