import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomerAttributeValue, MappedCustomerAttributeValue } from "../types/customerAttributes";
import { chunk } from "../utils/array/chunk";
import { upsertCustomerAttributes } from "../services/bigCommerce/proxy";
import { Customer, UpsertCustomerAttributesRequest } from "../types/customer";
import { mergeAttributes } from "../utils/customerAttributes/mergeAttributes";
import { Maybe, Optional } from "../types/generics";
import { CUSTOMER_QUERY_KEY } from "./useFetchCustomer";

export const useSaveCustomerAttributes = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (
            attributes: (MappedCustomerAttributeValue & { customer_id: number })[]
        ): Promise<CustomerAttributeValue[]> => {
            const requests = attributes
                .map(
                    ({ attribute_id, value, customer_id }) =>
                        ({
                            attribute_id: attribute_id ?? 0,
                            value: value,
                            customer_id,
                        } as UpsertCustomerAttributesRequest)
                )
                .filter(({ attribute_id }) => attribute_id > 0);

            const chunkedAttributes = chunk(requests, 10);

            const responses = await Promise.all(chunkedAttributes.map((attrs) => upsertCustomerAttributes(attrs)));

            return responses.map((response) => response.data.data).flat();
        },
        onSuccess: (attributes) => {
            const customer = queryClient.getQueryData([CUSTOMER_QUERY_KEY, attributes?.[0]?.id]) as Optional<
                Maybe<Customer>
            >;

            if (customer) {
                queryClient.setQueryData([CUSTOMER_QUERY_KEY, customer.id], {
                    ...(customer as Customer),
                    attributes: mergeAttributes((customer as Customer).attributes ?? [], attributes),
                });
            }
        },
    });
};
