/**
 * This will replace some placeholders such as `"some {foo} string"` from a string using an object
 * with matching key/values. If an object like `{ foo: "bar" }` is given, the output will be
 * `"some bar string"`. This can be useful for building URLs with dynamic parameters.
 *
 * @param text String
 * @param params Object
 * @returns String
 */
export const parseParams = (text: string, params?: Record<string, string | number>) => {
    if (!params) {
        return text;
    }

    const keys = Object.keys(params);

    if (keys.length === 0) {
        return text;
    }

    return keys.reduce((prev, next) => prev.replace(`{${next}}`, `${params[next]}`), text);
};
