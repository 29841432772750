import { Product, Variant } from "../../types/products";
import { getPriceListAssignments, getPriceListRecords, getPriceLists } from "../../store/ephemeral/prices";

export const getPriceListRecord = (product: Product, variant?: Variant, customerGroupId?: number) => () => {
    const listAssignments = getPriceListAssignments();
    const listRecords = getPriceListRecords();
    const lists = getPriceLists();

    const priceListAssignments = listAssignments.filter(
        (priceListAssignment) => priceListAssignment.customer_group_id === customerGroupId
    );

    const priceLists = lists.filter(
        (priceList) =>
            priceListAssignments
                .map((priceListAssignment) => priceListAssignment.price_list_id)
                .includes(priceList.id) && priceList.active
    );

    const priceListRecords = listRecords.filter((listRecord) =>
        priceLists.map((priceList) => priceList.id).includes(listRecord.price_list_id)
    );

    return priceListRecords.find((listRecord) => {
        let match = listRecord.product_id === product.id;

        if (variant) {
            match = match && listRecord.variant_id === variant.id;
        }

        return match;
    });
};
