import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Cart, CartQueryKey, LineItem } from "../../../types/cart";
import * as bigCommerce from "../../../services/bigCommerce/proxy";
import { useFetchCart } from "../useFetchCart";

export const useUpdateLineItemQuantity = () => {
    const queryClient = useQueryClient();
    const { data: cart } = useFetchCart();

    return useMutation<any, any, { lineItem: LineItem; quantity: number }>({
        mutationKey: ["updateLineItemQuantity"],
        mutationFn: async ({ lineItem, quantity }) => {
            if (!cart) {
                throw new Error("Cart not found to update line item");
            }

            const response = await bigCommerce.updateCartLineItem(cart.id, lineItem.id, {
                line_item: {
                    product_id: lineItem.product_id,
                    variant_id: lineItem.variant_id,
                    quantity,
                },
            });

            return response.data.data;
        },
        onSuccess: (data: Cart) => {
            queryClient.setQueryData([CartQueryKey.FETCH, cart?.id], data);
        },
    });
};
