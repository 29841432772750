import React from "react";
import { Dialog } from "@headlessui/react";
import Icon, { Icons } from "../Icons/Icon";

export enum ModalWidth {
    NORMAL,
    WIDE,
    FULL,
}
export interface ModalProps {
    children: React.ReactNode;
    className?: string;
    stickyHeader?: React.ReactNode;
    "data-testid"?: string;
    // wide?: boolean;
    width?: ModalWidth;
    isOpen: boolean;
    onClose: () => void;
}

const Modal = ({ children, className, stickyHeader, width, isOpen, onClose, ...rest }: ModalProps) => {
    let widthClass: string;
    if (width == ModalWidth.FULL) {
        widthClass = "w-full";
    } else if (width == ModalWidth.WIDE) {
        widthClass = "w-11/12";
    } else {
        widthClass = "w-5/6";
    }

    return (
        <Dialog open={isOpen} onClose={onClose} {...rest}>
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-30" />
            <Dialog.Panel
                className={`${widthClass} flex flex-col sm:container max-h-full bg-white rounded-lg fixed top-2/4 left-2/4 transform -translate-x-1/2 -translate-y-1/2 z-40 ${className}`}
                data-testid="modal-content"
            >
                {stickyHeader && <div className="p-4">{stickyHeader}</div>}
                <div className="overflow-auto h-full p-4">
                    <button
                        onClick={onClose}
                        className="flex flex-grow flex-shrink items-center rounded-full w-8 h-8 justify-center absolute top-4 right-4 bg-gray-100 hover:ring-4 hover:ring-green-200"
                        aria-label="Close"
                        data-testid="close-button"
                    >
                        <Icon icon={Icons.CLOSE} className="text-xl text-blue-300 font-bold" />
                    </button>
                    <div data-testid="modal-content-overflow">{children}</div>
                </div>
            </Dialog.Panel>
        </Dialog>
    );
};

export default Modal;
