import { CustomerAttributeValue } from "./customerAttributes";
import { Maybe } from "./generics";

export interface CreateCustomerAttributes {
    email: string;
    first_name: string;
    last_name: string;
    phone?: string;
    customer_group_id?: number | null;
}

export interface CreateCustomerRequest extends CreateCustomerAttributes {
    accepts_product_review_abandoned_cart_emails?: boolean;
    origin_channel_id: number;
    channel_ids: number[];
}

export interface UpdateCustomerRequest extends Partial<CreateCustomerAttributes> {
    accepts_product_review_abandoned_cart_emails?: boolean;
    id: number;
}

export interface CustomerAddressInput {
    address1: string;
    address2?: string;
    city: string;
    postcode: string;
    county: string;
    country?: string;
    countryCode?: string;
    phone?: string;
}

export interface CustomerDetailsData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    consent: string;
}

export interface CreateCustomerAddressRequest {
    address1: string;
    address2?: string;
    address_type?: AddressType;
    city: string;
    company?: string;
    country?: string;
    country_code?: string;
    customer_id: number;
    first_name: string;
    last_name: string;
    phone?: string;
    postal_code: string;
    state_or_province: string;
}

export interface UpdateCustomerAddressRequest {
    address1?: string;
    address2?: string;
    address_type?: AddressType;
    city?: string;
    company?: string;
    country?: string;
    country_code?: string;
    customer_id?: number;
    first_name?: string;
    id: number;
    last_name?: string;
    phone?: string;
    postal_code?: string;
    state_or_province?: string;
}

export interface UpsertCustomerAttributesRequest {
    id?: number;
    customer_id: number;
    attribute_id: number;
    value: string;
}

export enum CustomerStorage {
    CURRENT = "customer.current",
}

export interface CurrentCustomer {
    address_count: number;
    attribute_count: number;
    customer_group_id: Maybe<number>;
    email: string;
    id: Maybe<number>;
}

export interface Customer {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone: Maybe<string>;
    customer_group_id: Maybe<number>;
    address_count?: number;
    addresses?: CustomerAddress[];
    attribute_count?: number;
    attributes?: CustomerAttributeValue[];
}

export interface CustomerAddress {
    address1: string;
    address2?: string;
    address_type?: AddressType;
    city: string;
    company?: string;
    country?: string;
    country_code: string;
    customer_id: number;
    first_name: string;
    id: number;
    last_name: string;
    phone?: string;
    postal_code: string;
    state_or_province: string;
}

export enum AddressType {
    RESIDENTIAL = "residential",
    COMMERCIAL = "commercial",
}
