import { OptionDisplayName, Product, Variant } from "../../types/products";

export const getPowerRatingOptionValue = (charger?: Product, variant?: Variant) => {
    if (!charger || !charger.options) {
        return undefined;
    }

    const powerRatingOption = charger.options.find((option) => option.display_name === OptionDisplayName.POWER_RATING);

    return powerRatingOption
        ? variant?.option_values.find((optionValue) => optionValue.option_id === powerRatingOption.id)
        : undefined;
};
