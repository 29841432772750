import store from "store2";
import { AuthStorage, MultiFactorChallenge } from "../../types/auth";

export default {
    getMultiFactorChallenge: (): MultiFactorChallenge | null => store.get(AuthStorage.MFA_CHALLENGE),
    setMultiFactorChallenge: (challenge: MultiFactorChallenge) => store.set(AuthStorage.MFA_CHALLENGE, challenge),
    hasMultiFactorChallenge: () => store.has(AuthStorage.MFA_CHALLENGE),
    removeMultiFactorChallenge: () => store.remove(AuthStorage.MFA_CHALLENGE),
    getMagicLinkEmail: (): string | null => store.get(AuthStorage.MAGIC_LINK_EMAIL),
    setMagicLinkEmail: (email: string) => store.set(AuthStorage.MAGIC_LINK_EMAIL, email),
    removeMagicLinkEmail: () => store.remove(AuthStorage.MAGIC_LINK_EMAIL),
};
