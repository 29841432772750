import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { getChargerAccessories } from "../../../utils/products/getChargerAccessories";
import useChargers from "../../../hooks/BigCommerce/useChargers";
import { getAccessoryLineItems, getCartLineItems } from "../../../utils/cart/cart";
import { Cart, CartQueryKey, LineItem } from "../../../types/cart";
import { Product } from "../../../types/products";
import { productName } from "../../../utils/productName";
import * as bigCommerce from "../../../services/bigCommerce/proxy";
import { useFetchCart } from "../useFetchCart";
import { getProducts } from "../../../store/ephemeral/products";
import { getCustomerGroupId } from "../../../utils/customer/customerGroups";
import { CUSTOMER_QUERY_KEY } from "../../useFetchCustomer";
import { Maybe } from "../../../types/generics";
import { Customer } from "../../../types/customer";
import { useVehicleSelectionStorage } from "../../../hooks/useVehicleSelectionStorage";
import { useAffiliateTrackingStorage } from "../../../hooks/useAffiliateTrackingStorage";
import { ResponseEnvelope } from "../../../services/bigCommerce";

type OriginalAccessory = {
    lineItem: LineItem;
    product: Product;
};
type AccessoryReplacement = {
    from: OriginalAccessory;
    to: Product;
};

const isCompatible = (accessory: Product, compatibleAccessories: Product[]): boolean => {
    return !!compatibleAccessories.find((product) => {
        return product.id === accessory.id;
    });
};
const canBeReplaced = (incompatibleAccessory: Product, compatibleAccessories: Product[]): Product | undefined => {
    return compatibleAccessories.find((compatibleAccessory) => {
        return productName(compatibleAccessory) === productName(incompatibleAccessory);
    });
};

export const useUpdateAccessorySelections = () => {
    const { data: cartData } = useFetchCart();
    const { getVehicleSelection } = useVehicleSelectionStorage();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();
    const queryClient = useQueryClient();

    const products = getProducts();
    const chargers = useChargers();

    return useMutation<any>({
        mutationKey: ["updateAccessorySelections"],
        mutationFn: async () => {
            const selection = getVehicleSelection();

            if (!selection?.connector || !cartData) {
                return;
            }

            const promises: Promise<AxiosResponse<ResponseEnvelope<Cart>>>[] = [];
            const replacements: AccessoryReplacement[] = [];

            const customer = queryClient.getQueryData<Maybe<Customer>>([CUSTOMER_QUERY_KEY, cartData?.customer_id]);
            const compatibleAccessories = getChargerAccessories(
                getCustomerGroupId(customer, getPartner(), getPublisher()),
                cartData,
                chargers,
                selection.connector
            );
            const accessoryLineItemsInCart = getAccessoryLineItems(getCartLineItems(cartData));
            const accessoriesInCart: OriginalAccessory[] = accessoryLineItemsInCart.map((accessoryLineItem) => {
                return {
                    lineItem: accessoryLineItem,
                    product: products.find((product) => product.id === accessoryLineItem.product_id) as Product,
                };
            });

            accessoriesInCart.forEach((accessory) => {
                if (!isCompatible(accessory.product, compatibleAccessories)) {
                    const canBeReplacedBy = canBeReplaced(accessory.product, compatibleAccessories);

                    if (canBeReplacedBy) {
                        // replace incompatible accessory with a compatible one
                        replacements.push({ from: accessory, to: canBeReplacedBy });

                        return;
                    }

                    // remove incompatible accessory
                    promises.push(bigCommerce.removeCartLineItem(cartData.id, accessory.lineItem.id));

                    return;
                }
                // compatible - accessory remains
            });

            replacements.forEach((replacement) => {
                promises.push(
                    bigCommerce.removeCartLineItem(cartData.id, replacement.from.lineItem.id).then(() =>
                        bigCommerce.addCartLineItems(cartData.id, {
                            line_items: [
                                {
                                    product_id: replacement.to.id,
                                    quantity: replacement.from.lineItem.quantity,
                                },
                            ],
                        })
                    )
                );
            });

            await Promise.all(promises);

            const response = await bigCommerce.fetchCart(cartData.id);

            return response.data.data;
        },
        onSuccess: (data: Cart) => {
            queryClient.setQueryData([CartQueryKey.FETCH, cartData?.id], data);
        },
    });
};
