import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import useChargers from "../../../hooks/BigCommerce/useChargers";
import { getChargersByConnectorAndCustomerGroup } from "../../../utils/products/getChargersByConnectorAndCustomerGroup";
import { getAllChargerLineItems, getCartLineItems } from "../../../utils/cart/cart";
import { Product } from "../../../types/products";
import { productName } from "../../../utils/productName";
import * as bigCommerce from "../../../services/bigCommerce/proxy";
import { Cart, CartQueryKey } from "../../../types/cart";
import { useFetchCart } from "../useFetchCart";
import { getCustomerGroupId } from "../../../utils/customer/customerGroups";
import { CUSTOMER_QUERY_KEY } from "../../useFetchCustomer";
import { getProducts } from "../../../store/ephemeral/products";
import { getPowerRatingOptionValue } from "../../../utils/products/getPowerRatingOptionValue";
import { Maybe } from "../../../types/generics";
import { Customer } from "../../../types/customer";
import { useVehicleSelectionStorage } from "../../../hooks/useVehicleSelectionStorage";
import { useAffiliateTrackingStorage } from "../../../hooks/useAffiliateTrackingStorage";
import { ResponseEnvelope } from "../../../services/bigCommerce";

export const useUpdateChargerSelection = () => {
    const queryClient = useQueryClient();
    const chargers = useChargers();
    const products = getProducts();
    const { data: cartData } = useFetchCart();
    const { getVehicleSelection } = useVehicleSelectionStorage();
    const { getPartner, getPublisher } = useAffiliateTrackingStorage();

    const chargerLineItems = getAllChargerLineItems(getCartLineItems(cartData), chargers);

    return useMutation<any>({
        mutationKey: ["updateChargerSelection"],
        mutationFn: async () => {
            const promises: Promise<AxiosResponse<ResponseEnvelope<Cart>>>[] = [];

            const customer = queryClient.getQueryData<Maybe<Customer>>([CUSTOMER_QUERY_KEY, cartData?.customer_id]);
            const validChargers = getChargersByConnectorAndCustomerGroup(
                getCustomerGroupId(customer, getPartner(), getPublisher()),
                getVehicleSelection()?.connector
            );
            const lineItemsToUpdate = chargerLineItems.filter(
                (lineItem) => !validChargers.find((product) => product.id === lineItem.product_id)
            );

            if (!cartData || lineItemsToUpdate.length === 0) {
                return;
            }

            lineItemsToUpdate.forEach((chargerLineItem) => {
                const charger = products.find((product) => product.id === chargerLineItem.product_id) as Product;
                const chargerVariant = charger.variants.find((variant) => variant.id === chargerLineItem.variant_id);
                const powerRating = getPowerRatingOptionValue(charger, chargerVariant);

                const newCharger =
                    validChargers.find((product) => productName(product) === productName(charger)) ?? validChargers[0];

                const newVariant =
                    newCharger.variants
                        .filter((variant) => !variant.purchasing_disabled)
                        .find(
                            (variant) => getPowerRatingOptionValue(newCharger, variant)?.label === powerRating?.label
                        ) ?? newCharger.variants[0];

                const newLineItem = {
                    product_id: newCharger.id,
                    variant_id: newVariant.id,
                    quantity: 1,
                };

                promises.push(
                    bigCommerce
                        .addCartLineItems(cartData.id, { line_items: [newLineItem] })
                        .then(() => bigCommerce.removeCartLineItem(cartData.id, chargerLineItem.id))
                );
            });

            const responses = await Promise.all(promises);
            const response = responses.pop() as AxiosResponse<ResponseEnvelope<Cart>>;

            return response.data.data;
        },
        onSuccess: (data: Cart) => {
            queryClient.setQueryData([CartQueryKey.FETCH, cartData?.id], data);
        },
    });
};
