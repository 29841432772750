import React from "react";
import useRemoveItemFromCartModalContent from "../../hooks/Prismic/useRemoveItemFromCartModalContent";
import Button from "../Buttons/Button";
import Modal, { ModalProps } from "../Modal/Modal";
import ModalSection from "../Modal/Section";
import Working from "../Icons/Working";
import ModalHeader from "../Modal/Header";

interface Props extends Omit<ModalProps, "children"> {
    onCancel: () => void;
    onRemoveItemConfirm: () => void;
    removeItemButtonIsDisabled: boolean;
}

const RemoveItemFromCartModal = ({
    onClose,
    isOpen,
    onRemoveItemConfirm,
    onCancel,
    removeItemButtonIsDisabled,
    ...rest
}: Props) => {
    const content = useRemoveItemFromCartModalContent();

    return (
        <Modal isOpen={isOpen} onClose={onClose} {...rest}>
            <ModalHeader>{content?.title}</ModalHeader>
            <ModalSection>
                <p className="mb-8">{content?.description}</p>
            </ModalSection>
            <ModalSection>
                <div data-testid="remove-confirm-dialog" className="flex flex-col md:flex-row justify-between gap-4">
                    <Button onClick={onCancel} outline>
                        {content?.cancel_button}
                    </Button>
                    <Button onClick={onRemoveItemConfirm} disabled={removeItemButtonIsDisabled}>
                        {removeItemButtonIsDisabled ? (
                            <>
                                <Working className="mr-2 font-bold" />
                                <span>Removing...</span>
                            </>
                        ) : (
                            content?.action_button
                        )}
                    </Button>
                </div>
            </ModalSection>
        </Modal>
    );
};

export default RemoveItemFromCartModal;
