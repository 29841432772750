import { Maybe } from "../types/generics";
import { AffiliateTrackingStorage } from "../types/tracking";
import { OriginSourceEnvironment } from "../types/origin";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const useAffiliateTrackingStorage = () => {
    const [getClickId, setClickId] = useTransactionalLocalStorage<Maybe<string>>(AffiliateTrackingStorage.CLICK_ID);
    const [getPartner, setPartner] = useTransactionalLocalStorage<Maybe<string>>(AffiliateTrackingStorage.PARTNER);
    const [getPublisher, setPublisher] = useTransactionalLocalStorage<Maybe<string>>(
        AffiliateTrackingStorage.PUBLISHER
    );
    const [getOriginSourceEnvironment, setOriginSourceEnvironment] = useTransactionalLocalStorage<
        Maybe<OriginSourceEnvironment>
    >(AffiliateTrackingStorage.ORIGIN_SOURCE_ENVIRONMENT);

    return {
        getClickId,
        setClickId,
        getPartner,
        setPartner,
        getPublisher,
        setPublisher,
        getOriginSourceEnvironment,
        setOriginSourceEnvironment,
    };
};
