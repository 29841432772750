import useLocation from "../useLocation";
import { Pages } from "../../types/navigation";

export const usePageUtils = () => {
    const { pathname } = useLocation();

    const pageIs = (pageUrl: Pages) => {
        if (pathname === pageUrl) {
            return true;
        }

        return pathname.slice(0, -1) === pageUrl;
    };

    return {
        pageIs,
    };
};
