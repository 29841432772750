import { MultiFactorError } from "firebase/auth";
import { login as authServiceLogin, multiFactor as authServiceMultiFactor } from "../services/auth";
import {
    AuthCommonFormFields,
    clearInvisibleRecaptcha,
    initializeInvisibleRecaptchaById,
    isMagicLinkValid,
    isMultiFactorNotRequired,
    LoginFormData,
    logInWithMagicLink,
    MultiFactorFormData,
    multiFactorSms,
} from "../services/firebaseService";
import { AuthStatus, MultiFactorChallenge } from "../types/auth";
import authStore from "../store/localStorage/authStore";
import { trackEmailAndPasswordLoginFailure, trackEmailAndPasswordLoginSuccess } from "../services/tracking";
import { generateCustomerHash } from "../utils/customer/generateCustomerHash";
import { useOriginStorage } from "./useOriginStorage";

export const useAuthentication = () => {
    const { getOrigin } = useOriginStorage();

    const login = async (data: LoginFormData) => {
        try {
            await authServiceLogin(data);
            trackEmailAndPasswordLoginSuccess(generateCustomerHash(data[AuthCommonFormFields.EMAIL]), getOrigin());

            return AuthStatus.SUCCESS;
        } catch (error: any) {
            if (isMultiFactorNotRequired(error)) {
                trackEmailAndPasswordLoginFailure(generateCustomerHash(data[AuthCommonFormFields.EMAIL]), getOrigin());
                throw error;
            }

            try {
                await requestMultiFactorSms(error as MultiFactorError);

                return AuthStatus.REQUIRES_MFA;
            } catch (error: any) {
                clearInvisibleRecaptcha();
                trackEmailAndPasswordLoginFailure(generateCustomerHash(data[AuthCommonFormFields.EMAIL]), getOrigin());

                throw error;
            }
        }
    };

    const loginWithMagicLink = async (email: string, link: string) => {
        if (email && link && (await isMagicLinkValid(link))) {
            await logInWithMagicLink(email, link);

            return AuthStatus.SUCCESS;
        } else {
            throw new Error(AuthStatus.INVALID_MAGIC_LINK);
        }
    };

    const requestMultiFactorSms = async (error?: any) => {
        const challenge = await multiFactorSms(error);

        authStore.setMultiFactorChallenge({
            verificationId: challenge.verificationId,
            factor: challenge.phoneNumber,
            email: error.customData._serverResponse.email,
        });
    };

    const multiFactor = async (data: MultiFactorFormData) => {
        if (!authStore.hasMultiFactorChallenge()) {
            throw new Error("Multi factor challenge missing.");
        }

        const challenge = authStore.getMultiFactorChallenge() as MultiFactorChallenge;

        await authServiceMultiFactor(data, challenge.verificationId);
    };

    return {
        login,
        loginWithMagicLink,
        multiFactor,
        requestMultiFactorSms,
        isMultiFactorChallenged: authStore.hasMultiFactorChallenge(),
        getMultiFactorChallenge: authStore.getMultiFactorChallenge,
        clearMultiFactorChallenge: authStore.removeMultiFactorChallenge,
        initializeInvisibleRecaptchaById,
        clearInvisibleRecaptcha,
    };
};
