import { Optional, Maybe } from "./generics";

export enum InstallationStorage {
    SELECTION = "installation.selection",
}

export enum InstallationQuestions {
    CABLE_LENGTH = "cableLength",
    TRUCKING_LENGTH = "truckingLength",
    DIG_REQUIRED = "digRequired",
}

export enum InstallationCableLength {
    SHORT = "upTo15Meters",
    LONG = "greaterThan15Meters",
}

export enum InstallationTruckingLength {
    SHORT = "upTo3Meters",
    LONG = "greaterThan3Meters",
}

export enum InstallationDigRequired {
    NO = "no",
    YES = "yes",
}

export enum InstallationType {
    STANDARD = "standard",
    NON_STANDARD = "non-standard",
}

export type InstallationAnswer = InstallationCableLength | InstallationTruckingLength | InstallationDigRequired;

export interface InstallationSelection {
    cableLength: Optional<Maybe<InstallationCableLength>>;
    truckingLength: Optional<Maybe<InstallationTruckingLength>>;
    digRequired: Optional<Maybe<InstallationDigRequired>>;
}
