import { CurrencyCode } from "./currency";
import { Maybe } from "./generics";

export enum CartQueryKey {
    FETCH = "fetchCart",
}

export interface OptionSelections {
    option_id: number;
    option_value: number;
}

export interface LineItemInput {
    product_id: number;
    variant_id?: number;
    quantity: number;
    option_selections?: OptionSelections[];
}

export interface CreateCartRequest {
    line_items: LineItemInput[];
    locale: string;
    customer_id?: number;
    channel_id: number;
    currency: {
        code: CurrencyCode;
    };
}

export interface UpdateCartRequest {
    customer_id: number;
}

export interface AddCartLineItemsRequest {
    line_items: LineItemInput[];
}

export interface UpdateCartLineItemRequest {
    line_item: LineItemInput;
}

export enum CartStorage {
    CART_ID = "cart.id",
    SELECTED_VARIANT_ID = "cart.variant.id",
}

export interface Cart {
    id: string;
    customer_id: Maybe<number>;
    line_items: {
        digital_items: LineItem[];
        physical_items: LineItem[];
    };
    email: string; // empty "" by default
    currency: {
        code: string;
    };
    locale: string;
    is_tax_included: boolean;
    base_amount: number;
    discount_amount: number;
    cart_amount: number;
    coupons: [];
    discounts: [];
}

export interface Option {
    name: string;
    nameId: number;
    value: string;
    valueId: number;
}

export interface LineItem {
    id: string;
    variant_id?: number;
    product_id: number;
    sku: Maybe<string>;
    name: string;
    quantity: number;
    options?: Option[];
    discount_amount: number;
    coupon_amount: number;
    original_price: number;
    list_price: number;
    sale_price: number;
    extended_list_price: number;
    extended_sale_price: number;
    added_by_promotion: boolean;
}
