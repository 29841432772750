exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessory-tsx": () => import("./../../../src/pages/accessory.tsx" /* webpackChunkName: "component---src-pages-accessory-tsx" */),
  "component---src-pages-additionalservices-tsx": () => import("./../../../src/pages/additionalservices.tsx" /* webpackChunkName: "component---src-pages-additionalservices-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-charger-tsx": () => import("./../../../src/pages/charger.tsx" /* webpackChunkName: "component---src-pages-charger-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-details-tsx": () => import("./../../../src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installation-tsx": () => import("./../../../src/pages/installation.tsx" /* webpackChunkName: "component---src-pages-installation-tsx" */),
  "component---src-pages-login-link-tsx": () => import("./../../../src/pages/login/link.tsx" /* webpackChunkName: "component---src-pages-login-link-tsx" */),
  "component---src-pages-login-multi-factor-tsx": () => import("./../../../src/pages/login/multi-factor.tsx" /* webpackChunkName: "component---src-pages-login-multi-factor-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-property-tsx": () => import("./../../../src/pages/property.tsx" /* webpackChunkName: "component---src-pages-property-tsx" */),
  "component---src-pages-resetpassword-tsx": () => import("./../../../src/pages/resetpassword.tsx" /* webpackChunkName: "component---src-pages-resetpassword-tsx" */),
  "component---src-pages-review-tsx": () => import("./../../../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-troubleshoot-tsx": () => import("./../../../src/pages/troubleshoot.tsx" /* webpackChunkName: "component---src-pages-troubleshoot-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

