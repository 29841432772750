import { Maybe } from "./generics";

export enum OriginSourceEnvironment {
    AWIN_PARTNER = "Awin Partner",
    AWIN_WEB = "Awin Web",
    BMW_PARTNER_TOOL = "BMW Partner Tool",
    PARTNER_PORTAL = "Partner Portal",
    STOREFRONT = "Storefront",
}

export enum OriginChannelCategory {
    WEB = "Web",
}

export enum OriginType {
    AFFILIATE = "Affiliate",
    ORGANIC = "Organic",
    REFERRAL = "Referral",
}

export interface Origin {
    partner: Maybe<string>;
    systemId: Maybe<string>;
    sourceChannelCategory: Maybe<OriginChannelCategory>;
    sourceEnvironment: Maybe<OriginSourceEnvironment>;
    type: Maybe<OriginType>;
}

export enum OriginStorage {
    SELECTION = "origin.selection",
}
