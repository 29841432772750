import { NavigationStorage } from "../types/navigation";
import { Maybe } from "../types/generics";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const useLastPageVisitedStorage = () => {
    const [getLastPageVisited, setLastPageVisited] = useTransactionalLocalStorage<Maybe<string>>(
        NavigationStorage.PAGE
    );

    return {
        getLastPageVisited,
        setLastPageVisited,
    };
};
