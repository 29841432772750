import { useStaticQuery, graphql } from "gatsby";
import { getLocale } from "../utils/locale";
import { SiteCartData, SiteConfigData } from "../graphqlFragments/siteConfig";

const useCartConfig = () => {
    const { languageCode } = getLocale();
    const data = useStaticQuery<SiteConfigData>(
        graphql`
            query {
                ...SiteConfigDataFragment
            }
        `
    );

    return data.siteCartConfig.nodes.find(({ lang }) => lang === languageCode)?.data as SiteCartData;
};

export default useCartConfig;
