import React from "react";
import { LocationProvider } from "@reach/router";
import { PrismicRichText } from "@prismicio/react";
import useAuthErrorContent from "../hooks/Prismic/useAuthErrorContent";
import { Pages } from "../types/navigation";
import { clearStorage } from "../utils/storage";
import useNavigationPages from "../hooks/useNavigationPages";
import { logout } from "../services/auth";
import Button from "./Buttons/Button";
import Card from "./Card/Card";
import Layout from "./Layout/Layout";
import Link from "./Buttons/Link";

function AuthErrorScreen() {
    const { signInData } = useAuthErrorContent();
    const { navigateTo } = useNavigationPages();

    return (
        <LocationProvider>
            <Layout>
                <div className="py-12 md:w-7/12 mx-auto" data-testid="login-container">
                    <h1 className="text-2xl font-bold mb-8">{signInData?.primary.title}</h1>
                    <p className="mb-8">{signInData?.primary.subtitle}</p>
                    <Card className="p-4 flex-col">
                        <h2 className="p-4 text-xl font-bold">{signInData?.primary?.heading?.text}</h2>
                        <div className="p-4 space-y-4 prose-a:text-green-400 hover:prose-a:underline">
                            <PrismicRichText field={signInData?.primary.text?.richText} />
                        </div>
                        <div className="p-4 text-center">
                            <Button
                                className="w-full"
                                onClick={async () => {
                                    await logout();
                                    await navigateTo(Pages.LOGIN);
                                }}
                            >
                                {signInData?.primary.button_text}
                            </Button>
                            <Link
                                className="mt-4"
                                onClick={async () => {
                                    clearStorage();
                                    await navigateTo(Pages.HOME_PAGE);
                                }}
                            >
                                {signInData?.primary.secondary_button_text}
                            </Link>
                        </div>
                    </Card>
                </div>
            </Layout>
        </LocationProvider>
    );
}

export default AuthErrorScreen;
