import { Maybe } from "../types/generics";

/**
 * ISO 3166-2 format
 */
export enum CountryName {
    UNITED_KINGDOM = "United Kingdom",
    IRELAND = "Ireland",
}

/**
 * ISO 3166-2 format
 */
export enum CountryCode {
    UNITED_KINGDOM = "GB",
    IRELAND = "IE",
}

/**
 * IETF BCP 47 format
 */
export enum CountryLanguageCode {
    UNITED_KINGDOM = "en-gb",
    IRELAND = "en-ie",
}

export enum DistanceUnit {
    miles = "miles",
    kilometers = "kilometers",
}

export enum TopLevelDomain {
    UNITED_KINGDOM = ".com",
    IRELAND = ".ie",
}

export type WebsiteLocale = {
    name: CountryName;
    countryCode: CountryCode;
    languageCode: CountryLanguageCode;
    distanceUnit: DistanceUnit;
    tld: TopLevelDomain;
};

const locales: WebsiteLocale[] = [
    {
        name: CountryName.UNITED_KINGDOM,
        countryCode: CountryCode.UNITED_KINGDOM,
        languageCode: CountryLanguageCode.UNITED_KINGDOM,
        distanceUnit: DistanceUnit.miles,
        tld: TopLevelDomain.UNITED_KINGDOM,
    },
    {
        name: CountryName.IRELAND,
        countryCode: CountryCode.IRELAND,
        languageCode: CountryLanguageCode.IRELAND,
        distanceUnit: DistanceUnit.kilometers,
        tld: TopLevelDomain.IRELAND,
    },
];

const getPrismicLanguageCode = (): string => process.env.GATSBY_PRISMIC_LOCALE as string;

export const getLocale = (forLanguageCode?: string): WebsiteLocale => {
    const fallbackLocale = locales[0];
    const languageCode = forLanguageCode || getPrismicLanguageCode();

    return locales.find((locale) => locale.languageCode === languageCode) || fallbackLocale;
};

export const getLocaleForCountry = (countryCode: Maybe<CountryCode>): Maybe<WebsiteLocale> => {
    return locales.find((locale) => locale.countryCode === countryCode) || null;
};

export const getAllCountryCodes = () => locales.map((locale) => locale.countryCode);

export const getAllLanguageCodes = () => locales.map((locale) => locale.languageCode);
