import { InstallationSelection, InstallationType } from "../../types/installation";
import { getInstallationQuestionResults } from "./getInstallationQuestionResults";

export const getInstallationType = (installationSelection: InstallationSelection): InstallationType => {
    const questionResults = getInstallationQuestionResults(installationSelection);

    if (Object.values(questionResults).every((result) => result === InstallationType.STANDARD)) {
        return InstallationType.STANDARD;
    } else {
        return InstallationType.NON_STANDARD;
    }
};
