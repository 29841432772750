import { Maybe, Optional } from "../../types/generics";
import { Cart } from "../../types/cart";
import { CustomFieldName, Product } from "../../types/products";
import { getAccessories } from "./getAccessories";
import { getCharger } from "./getCharger";

const filterAccessoriesByChargerRelation = (charger: Product) => (accessory: Product) => {
    return accessory.related_products.includes(charger.id);
};

export const filterAccessoriesByConnector =
    (connector: string, strict = false) =>
    (accessory: Product): boolean => {
        const connectors = accessory.custom_fields.filter(
            (customField) => customField.name === CustomFieldName.CONNECTOR
        );

        return connectors.length < 1 ? !strict : connectors.map((connector) => connector.value).includes(connector);
    };

export const filterOnlyAccessoriesByConnector = (connector: string) => filterAccessoriesByConnector(connector, true);

export const getChargerAccessories = (
    customerGroupId: number,
    cartData: Optional<Maybe<Cart>>,
    chargers: Product[],
    connector?: string
): Product[] => {
    let accessories = getAccessories(customerGroupId);
    const charger = getCharger(cartData, chargers);

    if (!charger) {
        return [];
    }

    accessories = accessories.filter(filterAccessoriesByChargerRelation(charger));

    if (connector) {
        accessories = accessories.filter(filterAccessoriesByConnector(connector));
    }

    return accessories.sort((a, b) => a.sort_order - b.sort_order);
};
