import { useStaticQuery, graphql } from "gatsby";
import { getLocale } from "../utils/locale";
import { SiteConfigData, SiteErrorBoundaryData } from "../graphqlFragments/siteConfig";

const useErrorBoundaryConfig = () => {
    const { languageCode } = getLocale();
    const { siteErrorBoundaryConfig } = useStaticQuery<SiteConfigData>(
        graphql`
            query {
                ...SiteConfigDataFragment
            }
        `
    );

    return siteErrorBoundaryConfig.nodes.find(({ lang }) => languageCode === lang)?.data as SiteErrorBoundaryData;
};

export default useErrorBoundaryConfig;
