import { IGatsbyImageData } from "gatsby-plugin-image";
import { OrderedProductType } from "./orders";
import { Maybe, Optional } from "./generics";

export interface CustomField {
    name: CustomFieldName | AdditionalServiceCustomFieldName;
    value: string;
}

export interface Image {
    id: number;
    product_id: number;
    description: string;
    is_thumbnail: boolean;
    sort_order: number;
    url_standard: string;
    url_thumbnail: string;
    url_tiny: string;
    url_zoom: string;
    image_thumbnail: {
        gatsbyImageData: IGatsbyImageData;
    };
    image_cart: {
        gatsbyImageData: IGatsbyImageData;
    };
}

export interface ProductCategory {
    category_id: number;
    name: string;
    parent_id: number;
    sort_order: number;
    description: string;
    image_url: string;
}

export interface Product {
    calculated_price: number;
    categories: number[];
    custom_fields: CustomField[];
    description: string;
    id: number;
    images: Image[];
    name: string;
    options: Option[];
    price: number;
    related_products: number[];
    sale_price: number;
    sku: Maybe<string>;
    sort_order: number;
    type: OrderedProductType;
    variants: Variant[];
    order_quantity_maximum: number;
    order_quantity_minimum: number;
}

export interface Option {
    display_name: string;
    id: number;
    option_values: OptionValue[];
}

export interface OptionValue {
    id: number;
    option_id: number;
    is_default: boolean;
    label: string;
    sort_order: number;
}

export interface Variant {
    calculated_price: number;
    id: number;
    image_url: string;
    option_values: OptionValue[];
    price: number;
    purchasing_disabled: boolean;
    sale_price: number;
    sku: string;
}

export interface Icon {
    image: { alt: string; url: string };
    rating: string;
}

export enum SurgeProtectionSelectionEnum {
    PRODUCT_IDENTIFICATION_VAL = "SPD",
}

export enum OptionDisplayName {
    POWER_RATING = "Power rating",
}

export enum OptionName {
    POWER_RATING = "power_rating",
}

export enum AdditionalServiceCustomFieldName {
    FIND_OUT_MORE_LINK = "find_out_more_link",
    FIND_OUT_MORE_TEXT = "find_out_more_text",
    PRODUCT_INDENTIFIER = "product_identifier",
}

export enum CustomFieldName {
    CONNECTOR = "connector",
    POWER_RATING = "power_rating",
    DISPLAY_NAME = "display_name",
    CABLE_TYPE = "cable_type",
    PLUG_TYPE = "plug_type",
    POWER_TYPE = "power_type",
    CONNECTOR_TYPE_DESCRIPTION = "connector_type_description",
}

export enum CustomFieldConnectorValue {
    UNIVERSAL = "universal",
}

export interface GetProductPricesRequest {
    channel_id: number;
    customer_group_id?: number;
    currency_code: string;
    items: {
        product_id: number;
        variant_id?: number;
    }[];
}

export interface Price {
    as_entered: number;
    entered_inclusive: true;
    tax_exclusive: number;
    tax_inclusive: number;
}

export interface ProductPrice {
    product_id: number;
    variant_id?: number;
    options?: {
        option_id: number;
        value_id: number;
    }[];
    retail_price?: Price;
    sale_price?: Price;
    minimum_advertised_price?: Price;
    price: Price;
    calculated_price: Price;
    price_range?: {
        minimum: Price;
        maximum: Price;
    };
    retail_price_range?: {
        minimum: Price;
        maximum: Price;
    };
}

export interface ProductPriceGroup {
    channel_id: Maybe<number>;
    currency_code: Maybe<string>;
    customer_group_id?: Maybe<number>;
    data: ProductPrice[];
}

export interface ProductPresentmentPrice {
    calculated_price: number;
    retail_price: number;
    price_range?: {
        maximum: number;
        minimum: number;
    };
    is_discounted: boolean;
    from: Optional<number>;
}

export enum ProductCategoryName {
    Chargers = "Chargers",
    Accessories = "Accessories",
    AdditionalServices = "AdditionalServices",
    Solo3 = "Solo 3",
    Solo3S = "Solo 3S",
}

export enum PowerRating {
    Three = "3.6kW",
    Seven = "7kW",
    TwentyTwo = "22kW",
}
