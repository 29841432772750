import { Maybe } from "./generics";

export enum PropertyStorage {
    SELECTION = "property.selection",
}

export enum PropertyType {
    HOUSE = "house",
    FLAT = "flat",
}

export enum PropertyOwnershipStatus {
    OWNED = "owned",
    RENTED = "rented",
}

export interface PropertySelection {
    type: Maybe<PropertyType>;
    status: Maybe<PropertyOwnershipStatus>;
}
