import { useCallback, useEffect, useRef, useState } from "react";
import { useCreateCustomer } from "../../queries/useCreateCustomer";
import { useUpdateCustomer } from "../../queries/useUpdateCustomer";
import { Maybe } from "../../types/generics";
import { CreateCustomerAttributes, UpdateCustomerRequest } from "../../types/customer";
import { captureException } from "../../utils/sentry";
import { useFetchCustomer } from "../../queries/useFetchCustomer";
import { MarketingConsent } from "../../types/consent";
import { useMarketingConsentStorage } from "../useMarketingConsentStorage";
import { useMarketingConsent } from "../useMarketingConsent";
import { useUpdateSelections } from "./useUpdateSelections";
import { useAfterCustomerCreate } from "./useAfterCustomerCreate";

export const useCustomer = (consentCopy?: Maybe<string>) => {
    const { mutateAsync: createAsync, ...mutateCreate } = useCreateCustomer();
    const { mutateAsync: updateAsync, ...mutateUpdate } = useUpdateCustomer();
    const {
        data: customer,
        isError,
        isSuccess,
        isEnabled,
        isAuthReady,
        user,
        isLoading: isCustomerLoading,
    } = useFetchCustomer();
    const updateSelections = useUpdateSelections();
    const { onAfterCustomerCreate, isPending: afterCustomerCreatePending } = useAfterCustomerCreate(consentCopy);
    const { storeMarketingConsent } = useMarketingConsentStorage();
    const { saveMarketingConsent, mutateMarketingConsent } = useMarketingConsent();
    const isCustomerLoaded = useRef(false);
    const [areCustomerAttributesLoaded, setAreCustomerAttributesLoaded] = useState(false);

    const createCustomerAsync = useCallback(
        async (request: CreateCustomerAttributes, marketingConsent?: MarketingConsent) => {
            try {
                isCustomerLoaded.current = true;
                marketingConsent && storeMarketingConsent(marketingConsent);
                setAreCustomerAttributesLoaded(true);
                const result = await createAsync(request);
                await onAfterCustomerCreate(result);

                return result;
            } catch (error) {
                captureException(error);
            }
        },
        [createAsync, onAfterCustomerCreate, storeMarketingConsent]
    );

    const createCustomer = (request: CreateCustomerAttributes, marketingConsent?: MarketingConsent) => {
        (async () => createCustomerAsync(request, marketingConsent))();
    };

    const updateCustomerAsync = useCallback(
        async (request: UpdateCustomerRequest, marketingConsent?: MarketingConsent) => {
            const result = await updateAsync(request);
            marketingConsent && (await saveMarketingConsent(marketingConsent));

            return result;
        },
        [saveMarketingConsent, updateAsync]
    );

    const updateCustomer = (request: UpdateCustomerRequest, marketingConsent?: MarketingConsent) => {
        (async () => updateCustomerAsync(request, marketingConsent))();
    };

    useEffect(() => {
        // After load customer
        if (!isCustomerLoaded.current && customer && !areCustomerAttributesLoaded) {
            isCustomerLoaded.current = true;

            (async () => {
                await updateSelections(customer);
                setAreCustomerAttributesLoaded(true);
            })();
        }
    }, [areCustomerAttributesLoaded, customer, updateSelections]);

    useEffect(() => {
        // Create customer when it doesn't exist
        if (user && !user.big_commerce_id && !customer && !isCustomerLoaded.current) {
            (async () =>
                createCustomerAsync({
                    email: user.email,
                    first_name: user.first_name,
                    last_name: user.last_name,
                }))();
        }
    }, [createCustomerAsync, customer, user]);

    const isCustomerReady = !isEnabled || areCustomerAttributesLoaded;

    return {
        createCustomer,
        createCustomerAsync,
        createError: mutateCreate.error,
        customer,
        isAuthReady,
        isCreateError: mutateCreate.isError,
        isCreatePending: mutateCreate.isPending || afterCustomerCreatePending,
        isCreateSuccess: mutateCreate.isSuccess,
        isCustomerReady,
        isCustomerLoading,
        isEnabled,
        isError,
        isSuccess,
        isUpdateError: mutateUpdate.isError,
        isUpdatePending: mutateUpdate.isPending || mutateMarketingConsent.isPending,
        isUpdateSuccess: mutateUpdate.isSuccess,
        updateCustomer,
        updateCustomerAsync,
        updateError: mutateUpdate.error,
        user,
    };
};
