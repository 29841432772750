module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pod Point Shop","start_url":"/","background_color":"#f2f2f2","theme_color":"#8ec042","display":"standalone","icon":"src/assets/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"01d4a8f67c01ad2545062400e348c2a3"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://a3353d49dd61468a92cae44cfb09d73a@o29760.ingest.sentry.io/6619307","environment":"prod","release":"1.11.0","ignoreErrors":["top.GLOBALS","originalCreateNotification","canvas.contentDocument","MyApp_RemoveAllHighlights","http://tt.epicplay.com","Can't find variable: ZiteReader","jigsaw is not defined","ComboSearch is not defined","http://loading.retry.widdit.com/","atomicFindClose","cobrowseIOgetRedactedElementsBounds","fb_xd_fragment","bmi_SafeAddOnload","EBCallBackMessageReceived","conduitPage","Connection to Indexed Database server lost","hotjar","gtag","postMessage","Invalid call to runtime.sendMessage","reading 'shadowRoot'","evaluating 'We.get'","change value of a readonly property",{},"auth/invalid-email","auth/multi-factor-auth-required","auth/expired-action-code","auth/invalid-action-code","auth/network-request-failed"],"denyUrls":[{},{},{},{},{},{},{},{},{},{},{},"cookieControl",{},{},{},{},{},{},{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TC653C3","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
