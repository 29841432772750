/**
 * Cart empty message component
 */
import React from "react";
import { SiteCartData } from "../../graphqlFragments/siteConfig";

const EmptyCart = ({ data }: { data: SiteCartData }) => {
    return (
        <div className="flex flex-row items-center justify-start">
            <img
                className="w-20 h-20"
                src={data.empty_mini_cart_image.url}
                alt={data.empty_mini_cart_image.alt || undefined}
            />
            <p className="text-gray-300 text-base text-center mb-4 font-extrabold">{data.empty_mini_cart_text.text}</p>
        </div>
    );
};

export default EmptyCart;
