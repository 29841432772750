import { Origin, OriginChannelCategory, OriginSourceEnvironment, OriginStorage, OriginType } from "../types/origin";
import { useTransactionalLocalStorage } from "./useTransactionalLocalStorage";

export const defaultOrigin: Origin = {
    systemId: null,
    partner: null,
    type: OriginType.ORGANIC,
    sourceChannelCategory: OriginChannelCategory.WEB,
    sourceEnvironment: OriginSourceEnvironment.STOREFRONT,
};

export const useOriginStorage = () => {
    const [getOrigin, setOrigin] = useTransactionalLocalStorage<Origin>(OriginStorage.SELECTION);

    return {
        getOrigin: () => getOrigin() ?? defaultOrigin,
        setOrigin,
    };
};
