import classNames from "classnames";
import React, { PropsWithChildren, useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import DOMPurify from "isomorphic-dompurify";
import Icon, { Icons } from "../Icons/Icon";
import { Maybe } from "../../types/generics";

export type AlertType = "error" | "warning" | "success" | "info" | "neutral";
export interface Props {
    type: AlertType;
    className?: string;
    html?: string;
    richText?: any;
    title?: Maybe<string>;
    strong?: boolean;
    align?: "left" | "right" | "center";
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    icon?: Icons | true;
    isDismissible?: true;
    isOpen?: boolean;
    testId?: string;
    onClose?: () => void;
}

const alertColourPalette = (type: AlertType = "neutral") => ({
    "bg-red-50 text-red-700 border border-red-200": type === "error",
    "bg-yellow-50 text-yellow-700 border border-yellow-200": type === "warning",
    "bg-green-50 text-green-700 border border-green-200": type === "success",
    "bg-blue-50 text-blue-700 border border-blue-200": type === "info",
    "bg-gray-50 text-gray-700 border border-gray-200": !["error", "warning", "success", "info"].includes(type),
});

const isIconType = (icon: unknown): icon is Icons => Object.values(Icons).includes(icon as Icons);

const alertIcon = (type: AlertType | Icons) => {
    if (isIconType(type)) {
        return type;
    }

    return (
        (type === "error" && Icons.CLOSE_CIRCLE_LIGHT) ||
        (type === "warning" && Icons.WARNING_CIRCLE_LIGHT) ||
        (type === "success" && Icons.CHECKMARK_CIRCLE_LIGHT) ||
        Icons.INFO_LIGHT
    );
};

export default function Alert({
    children,
    className,
    html,
    richText,
    type,
    title,
    icon,
    isDismissible,
    onClose,
    testId,
    isOpen = true,
    strong = true,
    align = "center",
    size = "sm",
}: PropsWithChildren<Props>) {
    const [isOpenState, setIsOpenState] = useState<boolean>(!isDismissible || !!isOpen);
    const classes = classNames(
        "p-4 rounded-lg text-sm prose-a:underline hover:prose-a:no-underline",
        {
            "font-bold": strong,
            "text-center": align === "center",
            "text-right": align === "right",
            "text-left": align === "left",
            "text-xs": size === "xs",
            "text-sm": size === "sm",
            "text-md": size === "md",
            "text-lg": size === "lg",
            "text-xl": size === "xl",
        },
        alertColourPalette(type),
        className
    );

    return isOpenState ? (
        <div
            data-testid={testId}
            className={classNames(classes, {
                "flex flex-row items-start justify-between gap-3": !!icon || isDismissible,
            })}
        >
            {icon && <Icon icon={alertIcon(icon === true ? type : icon)} className="text-xl" />}
            <div className="grow">
                {title && <h5 className="font-bold text-black">{title}</h5>}
                {html && (
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }) }} />
                )}
                {richText && (
                    <div>
                        <PrismicRichText field={richText} />
                    </div>
                )}
                {!html && children && <div>{children}</div>}
            </div>
            {isDismissible && (
                <button
                    aria-label="Close"
                    className="cursor-pointer text-2xl leading-[0.5]"
                    onClick={onClose ?? (() => setIsOpenState(false))}
                >
                    x
                </button>
            )}
        </div>
    ) : null;
}
