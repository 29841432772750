import { useStaticQuery, graphql } from "gatsby";
import { CustomerGroup } from "../../types/customerGroups";

/**
 * Both `is_default` and `is_group_for_guests` are overridden at build time from
 * gatsby-node.ts using the customerChannelSettings for the current channel as
 * the `/v2/customer_groups` endpoint doesn't take channels into consideration.
 */
const useCustomerGroups = (): CustomerGroup[] => {
    const { customerGroups } = useStaticQuery(
        graphql`
            query {
                customerGroups {
                    data {
                        id
                        name
                        is_default
                        is_group_for_guests
                    }
                }
            }
        `
    );

    return customerGroups.data;
};

export default useCustomerGroups;
