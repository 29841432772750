import { formatISO } from "date-fns";
import { ConsentEnvironment, ConsentInput, EmailConsent, PhoneConsent } from "../../types/consent";
import { CustomerAttributeName, MappedCustomerAttributeValue } from "../../types/customerAttributes";
import { getCustomerAttributeIdByName } from "../../store/ephemeral/customerAttributes";

export const getEmailConsentCustomerAttributes = (consent: ConsentInput): MappedCustomerAttributeValue[] => {
    const isConsentGivenAttributeId = getCustomerAttributeIdByName(
        CustomerAttributeName.CONSENT_EMAIL_IS_CONSENT_GIVEN
    );
    const dateAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_EMAIL_DATE);
    const copyAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_EMAIL_COPY);
    const typeAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_EMAIL_TYPE);
    const environmentAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_EMAIL_ENVIRONMENT);

    return [
        {
            attribute_id: isConsentGivenAttributeId,
            value: EmailConsent.IS_CONSENT_GIVEN,
        },
        {
            attribute_id: typeAttributeId,
            value: EmailConsent.TYPE,
        },
        {
            attribute_id: dateAttributeId,
            value: formatISO(consent.date),
        },
        {
            attribute_id: copyAttributeId,
            value: consent.copy,
        },
        {
            attribute_id: environmentAttributeId,
            value: ConsentEnvironment.STOREFRONT,
        },
    ];
};

export const getPhoneConsentCustomerAttributes = (consent: ConsentInput): MappedCustomerAttributeValue[] => {
    const isConsentGivenAttributeId = getCustomerAttributeIdByName(
        CustomerAttributeName.CONSENT_PHONE_IS_CONSENT_GIVEN
    );
    const dateAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_PHONE_DATE);
    const copyAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_PHONE_COPY);
    const typeAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_PHONE_TYPE);
    const environmentAttributeId = getCustomerAttributeIdByName(CustomerAttributeName.CONSENT_PHONE_ENVIRONMENT);

    return [
        {
            attribute_id: isConsentGivenAttributeId,
            value: PhoneConsent.IS_CONSENT_GIVEN,
        },
        {
            attribute_id: typeAttributeId,
            value: PhoneConsent.TYPE,
        },
        {
            attribute_id: dateAttributeId,
            value: formatISO(consent.date),
        },
        {
            attribute_id: copyAttributeId,
            value: consent.copy,
        },
        {
            attribute_id: environmentAttributeId,
            value: ConsentEnvironment.STOREFRONT,
        },
    ];
};
