import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Working from "../Icons/Working";
import { join } from "../../utils/str/join";

const getDisabledClassObject = (isDisabled: boolean) => ({
    "opacity-30 text-gray-300": isDisabled,
    "hover:bg-gray-200": !isDisabled,
});

export type StyleVariant = "default" | "light";

const getStyleVariant = (styleVariant: StyleVariant) => (styleVariant === "light" ? "bg-white" : "bg-gray-100");

interface Props {
    value: number;
    onChange: (value: number) => void;
    className?: string;
    isLoading?: boolean;
    min?: number;
    max?: number;
    styleVariant?: StyleVariant;
    testId?: string;
}

const QuantityPicker = ({
    value,
    onChange,
    className,
    isLoading,
    min = 0,
    max = Infinity,
    styleVariant = "default",
    testId = "",
}: Props) => {
    const [canIncrement, setCanIncrement] = useState(true);
    const [canDecrement, setCanDecrement] = useState(true);
    const [isMandatoryItem, setIsMandatoryItem] = useState(false);

    const buttonClass = (side: "left" | "right") => {
        return `h-full font-medium p-2 no-underline text-gray-700 flex items-center ${getStyleVariant(styleVariant)} ${
            side === "left" ? "rounded-l" : "rounded-r"
        }`;
    };

    const handleChange = (value: number) => {
        if (value < min) return;
        if (value > max) return;

        onChange(value);
    };

    useEffect(() => {
        setCanIncrement(value < max);
        setCanDecrement(value > min);
    }, [value, min, max]);

    useEffect(() => {
        setIsMandatoryItem(min === max);
    }, [min, max]);

    return (
        <div className={`relative number-input flex items-end ${className}`}>
            {isLoading && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Working className="font-bold text-xl text-green-600" />
                </div>
            )}
            <button
                data-testid={join(["quantity-minus-button", testId])}
                disabled={isLoading || !canDecrement}
                onClick={() => onChange(value - 1)}
                className={classNames(buttonClass("left"), getDisabledClassObject(isLoading || !canDecrement))}
            >
                -
            </button>
            <input
                min={min}
                max={max}
                data-testid={join(["quantity-input", testId])}
                disabled={isMandatoryItem || isLoading}
                type="number"
                className={classNames(
                    `flex-1 p-2 w-12 font-medium text-center ${getStyleVariant(styleVariant)}`,
                    getDisabledClassObject(!!isLoading)
                )}
                onChange={(e) => handleChange(parseInt(e.target.value, 10))}
                value={value}
            />
            <button
                data-testid={join(["quantity-plus-button", testId])}
                disabled={isLoading || !canIncrement}
                onClick={() => onChange(value + 1)}
                className={classNames(buttonClass("right"), getDisabledClassObject(isLoading || !canIncrement))}
            >
                +
            </button>
        </div>
    );
};

export default QuantityPicker;
