import { useState } from "react";
import { PropertySelection } from "../types/property";
import { useSavePropertySelection } from "../queries/useSavePropertySelection";
import { useFetchCustomer } from "../queries/useFetchCustomer";
import { trackSelectProperty } from "../services/tracking";
import { Maybe } from "../types/generics";
import { usePropertySelectionStorage } from "./usePropertySelectionStorage";

export const usePropertySelection = () => {
    const { data: customer } = useFetchCustomer();
    const { getPropertySelection, storePropertySelection } = usePropertySelectionStorage();

    const [selection, setPropertySelection] = useState<Maybe<PropertySelection>>(getPropertySelection());

    const { mutateAsync } = useSavePropertySelection();

    return {
        selection,
        customer,
        storePropertySelection: (selection: PropertySelection) => {
            setPropertySelection(selection);
            storePropertySelection(selection);
        },
        savePropertySelection: async (selection: PropertySelection) => {
            setPropertySelection(selection);
            storePropertySelection(selection);
            trackSelectProperty(selection.type, selection.status);
            await mutateAsync({ selection, customerId: customer?.id });
        },
    };
};
