import { InstallationSelection } from "../types/installation";
import { useSaveInstallationSelection } from "../queries/useSaveInstallationSelection";
import { useInstallationSelectionStorage } from "./useInstallationSelectionStorage";

export const useInstallationSelection = () => {
    const { mutateAsync } = useSaveInstallationSelection();
    const { selection, storeInstallationSelection } = useInstallationSelectionStorage();

    const saveInstallationSelection = async (selection: InstallationSelection) => {
        await mutateAsync(selection);
        storeInstallationSelection(selection);
    };

    return {
        selection: selection ?? { truckingLength: null, cableLength: null, digRequired: null },
        storeInstallationSelection,
        saveInstallationSelection,
    };
};
