import { Optional } from "../../types/generics";

const store = new Map<string, any>();

const persist = () => {
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window["__POD_POINT_DATA__"] = store;
    }
};

export function getItem<T = any>(key: string): Optional<T>;
export function getItem<T = any>(key: string, defaultValue: T): T;
export function getItem<T = any>(key: string, defaultValue?: T): Optional<T> {
    return store.get(key) ?? defaultValue;
}

export const storeItem = <T = any>(key: string, value: T) => {
    store.set(key, value);
    persist();
};

export const removeItem = (key: string) => {
    store.delete(key);
    persist();
};
