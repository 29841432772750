import { Axios, AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { StatusCodes } from "http-status-codes";
import { AccessToken } from "../types/auth";
import User from "../types/user";
import { CheckoutRedirectUrlResponse } from "../types/checkout";
import {
    breadcrumbForAxiosError,
    breadcrumbForAxiosRequest,
    breadcrumbForAxiosResponse,
    getSessionId as getSentrySessionId,
    SENTRY_SESSION_ID_HEADER,
} from "../utils/sentry";
import { parseParams } from "../utils/str/parseParams";
import { getChannelId } from "../utils/getChannelId";

const USER_ENDPOINT = "/user";
const CHECKOUT_REDIRECT_ENDPOINT = "/big-commerce/checkout-redirect";
const RESET_PASSWORD_ENDPOINT = "/reset-password";
const RECOVER_FACTOR_ENDPOINT = "/recover-factor";
const SIGN_IN_WITH_MAGIC_LINK_ENDPOINT = "/sign-in-with-email";
export const VERIFY_EMAIL_EXISTS_ENDPOINT = "/big-commerce/{channel}/users/{hash}";

const getClient = () => {
    const client = new Axios({
        baseURL: process.env.GATSBY_BIG_COMMERCE_PROXY_API_URL,
        headers: {
            "Content-Type": "application/json",
            [SENTRY_SESSION_ID_HEADER]: getSentrySessionId(),
        },
        validateStatus: (status) => status >= 200 && status < 300,
        withCredentials: true,
    });

    client.interceptors.request.use((request: InternalAxiosRequestConfig) => {
        return breadcrumbForAxiosRequest(request);
    });

    client.interceptors.response.use(
        (response: AxiosResponse) => {
            breadcrumbForAxiosResponse(response);

            return {
                ...response,
                data: response.data ? JSON.parse(response.data) : null,
            };
        },
        (error: AxiosError) => {
            throw breadcrumbForAxiosError(error);
        }
    );

    return client;
};

export const getUser = async (token: AccessToken): Promise<AxiosResponse<User>> => {
    return getClient().get(USER_ENDPOINT, {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    });
};

export const createCheckoutRedirectUrl = async (
    customerId: number,
    cartId: string
): Promise<AxiosResponse<CheckoutRedirectUrlResponse>> => {
    return getClient().post(CHECKOUT_REDIRECT_ENDPOINT, JSON.stringify({ customerId, cartId }));
};

export const resetPassword = async (email: string, locale: string) => {
    return getClient().post(RESET_PASSWORD_ENDPOINT, JSON.stringify({ email, locale }));
};

export const recoverFactor = async (email: string, locale: string) => {
    return getClient().post(RECOVER_FACTOR_ENDPOINT, JSON.stringify({ email, locale }));
};

export const checkEmailExists = async (email: string) => {
    const url = parseParams(VERIFY_EMAIL_EXISTS_ENDPOINT, {
        channel: getChannelId().toString(),
        hash: btoa(encodeURIComponent(email)),
    });

    const response = await getClient().head(url, {
        validateStatus: (status) =>
            [StatusCodes.NO_CONTENT, StatusCodes.NOT_FOUND, StatusCodes.UNPROCESSABLE_ENTITY].includes(status),
    });

    return response.status == StatusCodes.NO_CONTENT;
};

export const signInWithMagicLink = async (email: string, locale: string) => {
    return getClient().post(SIGN_IN_WITH_MAGIC_LINK_ENDPOINT, JSON.stringify({ email, locale }));
};
