import React from "react";
import { Image } from "../../types/products";
import CardSection from "../Card/Section";
import { Optional } from "../../types/generics";

interface Props {
    image: Optional<Image>;
    content: React.ReactNode;
    testId?: string;
}

export default function CartRow({ image, content, testId }: Props) {
    return (
        <div className="flex items-center" data-testid={testId}>
            <CardSection className="w-24 sm:w-36">
                {image ? <img src={image.url_thumbnail} alt={image.description} /> : null}
            </CardSection>
            <div className="flex-1 sm:flex justify-between">{content}</div>
        </div>
    );
}
