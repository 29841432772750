import { Cart, LineItem } from "../../types/cart";
import { Product } from "../../types/products";
import { getAccessories, getAdditionalServices } from "../../store/ephemeral/products";
import { Maybe, Optional } from "../../types/generics";
import { filterOnlyAccessoriesByConnector } from "../products/getChargerAccessories";
import { getCharger } from "../products/getCharger";

export const getCartLineItems = (cartData: Optional<Maybe<Cart>>) => {
    return [...(cartData?.line_items.physical_items ?? []), ...(cartData?.line_items.digital_items ?? [])];
};

export const getChargerVariantFromCart = (cartData: Optional<Maybe<Cart>>, chargers: Product[]) => {
    const chargerLineItem = getChargerLineItem(cartData, chargers);
    const charger = getCharger(cartData, chargers);

    if (!chargerLineItem || !charger) {
        return undefined;
    }

    return charger.variants.find((variant) => variant.id === chargerLineItem.variant_id);
};

export const getCartLineItemsTotalQuantity = (lineItems: LineItem[]) =>
    lineItems.reduce((total, item) => (total += item.quantity), 0);

export const getProductLineItem = (product: Product, cartData: Optional<Maybe<Cart>>) => {
    const lineItems = getCartLineItems(cartData);

    return lineItems.find((lineItem) => lineItem.product_id === product.id);
};

export const getChargerLineItem = (cartData: Optional<Maybe<Cart>>, chargers: Product[]) => {
    const lineItems = getCartLineItems(cartData);
    const chargerIDs = chargers.map((charger) => charger.id);

    return lineItems.find((lineItem) => chargerIDs?.includes(lineItem.product_id));
};

export const getAllChargerLineItems = (lineItems: LineItem[], chargers: Product[]) => {
    const chargerIDs = chargers.map((charger) => charger.id);

    return lineItems.filter((lineItem) => chargerIDs.includes(lineItem.product_id));
};

export const getAdditionalServiceLineItems = (lineItems: LineItem[]) => {
    const additionalServices = getAdditionalServices();
    const serviceIDs = additionalServices.map((service) => service.id);

    return lineItems.filter((lineItem) => serviceIDs.includes(lineItem.product_id));
};

export const getAccessoryLineItems = (lineItems: LineItem[]) => {
    const accessories = getAccessories();
    const accessoryIDs = accessories.map((accessory) => accessory.id);

    return lineItems.filter((lineItem) => accessoryIDs.includes(lineItem.product_id));
};

export const hasAnyAccessoryLineItemForConnector = (lineItems: LineItem[], connector: string): boolean => {
    const accessories = getAccessories();
    const lineItemsIDs = lineItems.map((lineItem) => lineItem.product_id);

    return !!accessories
        .filter((accessory) => lineItemsIDs.includes(accessory.id))
        .find(filterOnlyAccessoriesByConnector(connector));
};

export const getCartChargerLineItemCount = (lineItems: LineItem[], chargers: Product[]) => {
    const chargerLineItems = getAllChargerLineItems(lineItems, chargers);

    return chargerLineItems.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0);
};
