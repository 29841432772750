import { useMemo } from "react";
import { ProductPresentmentPrice } from "../types/products";
import { Maybe } from "../types/generics";
import useStoreDefaultProductsPricing from "./useStoreDefaultProductsPricing";
import useStoreCustomerGroupProductsPricing from "./useStoreCustomerGroupProductsPricing";

export const useProductPrice = (customerGroupId: number, productId?: Maybe<number>, variantId?: Maybe<number>) => {
    const storeDefaultPrices = useStoreDefaultProductsPricing();
    const customerGroupProductsPricing = useStoreCustomerGroupProductsPricing(customerGroupId);

    return useMemo(() => {
        if (!productId || storeDefaultPrices.length === 0) {
            return undefined;
        }

        const customerGroupPrices = customerGroupProductsPricing ?? storeDefaultPrices;

        const defaultPrice = variantId
            ? storeDefaultPrices.find((price) => price.product_id === productId && price.variant_id === variantId)
            : storeDefaultPrices.find((price) => price.product_id === productId);

        const customerPrice = variantId
            ? customerGroupPrices.find((price) => price.product_id === productId && price.variant_id === variantId)
            : customerGroupPrices.find((price) => price.product_id === productId);

        if (!defaultPrice) {
            throw new Error(`Price not found for product #${productId}${variantId ? ` - variant #${variantId}` : ""}`);
        }

        const calculatedPrice = customerPrice?.calculated_price ?? defaultPrice.calculated_price;
        const retailPrice = customerPrice?.retail_price ?? defaultPrice?.retail_price ?? defaultPrice.price;
        const priceRange = customerPrice?.price_range ?? defaultPrice?.price_range;

        const productPrice: ProductPresentmentPrice = {
            calculated_price: calculatedPrice.tax_inclusive,
            retail_price: retailPrice.tax_inclusive,
            price_range: priceRange
                ? {
                      minimum: priceRange.minimum.tax_inclusive,
                      maximum: priceRange.maximum.tax_inclusive,
                  }
                : undefined,
            is_discounted: calculatedPrice.tax_inclusive < retailPrice.tax_inclusive,
            from:
                priceRange && priceRange.minimum.tax_inclusive !== priceRange.maximum.tax_inclusive
                    ? priceRange.minimum.tax_inclusive
                    : undefined,
        };

        return productPrice;
    }, [customerGroupProductsPricing, productId, storeDefaultPrices, variantId]);
};
