import { sha256 } from "js-sha256";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { getLocale } from "../utils/locale";
import { MarketingBannerCommon } from "../graphqlFragments/marketingBanners";

const useMarketingBanners = () => {
    const { languageCode } = getLocale();
    const published = useStaticQuery<Queries.MarketingBannersQuery>(
        graphql`
            query MarketingBanners {
                ...MarketingBannersData
            }
        `
    );

    const { data: dataWithPreviews } = useMergePrismicPreviewData(published);

    return dataWithPreviews.marketingBanners.nodes
        .filter(({ lang }) => lang === languageCode)
        .map(
            ({ data, uid }) =>
                ({
                    uid,
                    hash: sha256(JSON.stringify(data)),
                    ...data,
                } as MarketingBannerCommon)
        );
};

export default useMarketingBanners;
