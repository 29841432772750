import { VehicleSelection } from "../types/vehicle";
import { useFetchCustomer } from "../queries/useFetchCustomer";
import { useSaveVehicleSelection } from "../queries/useSaveVehicleSelection";
import { useUpdateProductSelections } from "../queries/cart/lineItem/useUpdateProductSelections";
import { useVehicleSelectionStorage } from "./useVehicleSelectionStorage";

export const useVehicleSelection = () => {
    const { getVehicleSelection, setVehicleSelection } = useVehicleSelectionStorage();
    const { data: customer } = useFetchCustomer();
    const { mutateAsync: saveVehicleSelectionMutation } = useSaveVehicleSelection();
    const { updateProductSelections } = useUpdateProductSelections();

    const saveVehicleSelection = async (selection: VehicleSelection) => {
        setVehicleSelection(selection);
        await saveVehicleSelectionMutation({ selection, customerId: customer?.id });
        await updateProductSelections();
    };

    return {
        getVehicleSelection,
        customer,
        setVehicleSelection,
        saveVehicleSelection,
    };
};
