import { useQuery } from "@tanstack/react-query";
import { StatusCodes } from "http-status-codes";
import { Cart, CartQueryKey } from "../../types/cart";
import * as bigCommerce from "../../services/bigCommerce/proxy";
import { Maybe, Optional } from "../../types/generics";
import { useCartSelectionStorage } from "../../hooks/useCartSelectionStorage";
import { logout } from "../../services/auth";
import { Pages } from "../../types/navigation";
import useNavigationPages from "../../hooks/useNavigationPages";

export const useFetchCart = () => {
    const { getCartId, setCartId } = useCartSelectionStorage();
    const { navigateTo } = useNavigationPages();

    return useQuery<Optional<Maybe<Cart>>>({
        queryKey: [CartQueryKey.FETCH, getCartId()],
        queryFn: async () => {
            const cartId = getCartId();

            if (!cartId) {
                return null;
            }

            try {
                const response = await bigCommerce.fetchCart(cartId);

                return response.data.data;
            } catch (error: any) {
                const status = error?.response?.status ?? error?.status ?? error.code;

                if ([StatusCodes.UNAUTHORIZED, StatusCodes.FORBIDDEN].includes(status)) {
                    await logout();
                    await navigateTo(Pages.LOGIN);

                    return null;
                }

                if ([StatusCodes.NOT_FOUND].includes(status)) {
                    setCartId(null);

                    return null;
                }

                throw error;
            }
        },
    });
};
